import { WidgetType } from "../../models";

// Grid Widget Body
export const getMockGridWidgetBodyData = async (
  type: WidgetType
) => {
  switch (type) {
    case "serp-listGrid-keywords":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keywordName", sequence: 1, type: "string", value: "Best Electric Cars" },
          { id: "searchVolume", sequence: 2, type: "string", value: "10000" },
          { id: "competition", sequence: 3, type: "string", value: "Medium" },
          { id: "competition_index", sequence: 4, type: "string", value: "0.45" },
          { id: "low_top_of_page_bid_micros", sequence: 5, type: "string", value: "1000000" },
          { id: "high_top_of_page_bid_micros", sequence: 6, type: "string", value: "5000000" },
          { id: "locationDisplayName", sequence: 7, type: "string", value: "United States" },
          { id: "deviceTypeDescription", sequence: 8, type: "icon", value: "desktop" },
          { id: "searchEngineTypeDescription", sequence: 9, type: "icon", value: "google" },
          { id: "url", sequence: 10, type: "url", value: "https://www.tesla.com" },
          { id: "auditTime", sequence: 11, type: "string", value: "2024-01-31" },
          { id: "position", sequence: 12, type: "string", value: "11" },
          { id: "auditVariationTime", sequence: 13, type: "string", value: "2024-01-01" },
          { id: "previousPosition", sequence: 14, type: "string", value: "15" },
          { id: "variation", sequence: 15, type: "string", value: "4" },
          { id: "variationPreviousPeriod", sequence: 16, type: "string", value: "4" },
          { id: "variationPreviousPeriodDate", sequence: 17, type: "string", value: "2023-12-31" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keywordName", sequence: 1, type: "string", value: "Electric Cars" },
          { id: "searchVolume", sequence: 2, type: "string", value: "15000" },
          { id: "competition", sequence: 3, type: "string", value: "High" },
          { id: "competition_index", sequence: 4, type: "string", value: "0.75" },
          { id: "low_top_of_page_bid_micros", sequence: 5, type: "string", value: "2000000" },
          { id: "high_top_of_page_bid_micros", sequence: 6, type: "string", value: "6000000" },
          { id: "locationDisplayName", sequence: 7, type: "string", value: "United Kingdom" },
          { id: "deviceTypeDescription", sequence: 8, type: "icon", value: "mobile" },
          { id: "searchEngineTypeDescription", sequence: 9, type: "icon", value: "bing" },
          { id: "url", sequence: 10, type: "url", value: "https://www.nissan.com" },
          { id: "auditTime", sequence: 11, type: "string", value: "2024-02-15" },
          { id: "position", sequence: 12, type: "string", value: "7" },
          { id: "auditVariationTime", sequence: 13, type: "string", value: "2024-02-01" },
          { id: "previousPosition", sequence: 14, type: "string", value: "10" },
          { id: "variation", sequence: 15, type: "string", value: "3" },
          { id: "variationPreviousPeriod", sequence: 16, type: "string", value: "3" },
          { id: "variationPreviousPeriodDate", sequence: 17, type: "string", value: "2024-01-31" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keywordName", sequence: 1, type: "string", value: "Hybrid Cars" },
          { id: "searchVolume", sequence: 2, type: "string", value: "20000" },
          { id: "competition", sequence: 3, type: "string", value: "Low" },
          { id: "competition_index", sequence: 4, type: "string", value: "0.25" },
          { id: "low_top_of_page_bid_micros", sequence: 5, type: "string", value: "500000" },
          { id: "high_top_of_page_bid_micros", sequence: 6, type: "string", value: "3000000" },
          { id: "locationDisplayName", sequence: 7, type: "string", value: "Canada" },
          { id: "deviceTypeDescription", sequence: 8, type: "icon", value: "tablet" },
          { id: "searchEngineTypeDescription", sequence: 9, type: "icon", value: "yahoo" },
          { id: "url", sequence: 10, type: "url", value: "https://www.toyota.com" },
          { id: "auditTime", sequence: 11, type: "string", value: "2024-03-05" },
          { id: "position", sequence: 12, type: "string", value: "5" },
          { id: "auditVariationTime", sequence: 13, type: "string", value: "2024-02-25" },
          { id: "previousPosition", sequence: 14, type: "string", value: "8" },
          { id: "variation", sequence: 15, type: "string", value: "3" },
          { id: "variationPreviousPeriod", sequence: 16, type: "string", value: "3" },
          { id: "variationPreviousPeriodDate", sequence: 17, type: "string", value: "2024-02-20" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keywordName", sequence: 1, type: "string", value: "Electric Trucks" },
          { id: "searchVolume", sequence: 2, type: "string", value: "12000" },
          { id: "competition", sequence: 3, type: "string", value: "Medium" },
          { id: "competition_index", sequence: 4, type: "string", value: "0.55" },
          { id: "low_top_of_page_bid_micros", sequence: 5, type: "string", value: "3000000" },
          { id: "high_top_of_page_bid_micros", sequence: 6, type: "string", value: "7000000" },
          { id: "locationDisplayName", sequence: 7, type: "string", value: "Germany" },
          { id: "deviceTypeDescription", sequence: 8, type: "icon", value: "mobile" },
          { id: "searchEngineTypeDescription", sequence: 9, type: "icon", value: "bing" },
          { id: "url", sequence: 10, type: "url", value: "https://www.volkswagen.com" },
          { id: "auditTime", sequence: 11, type: "string", value: "2024-03-15" },
          { id: "position", sequence: 12, type: "string", value: "9" },
          { id: "auditVariationTime", sequence: 13, type: "string", value: "2024-03-01" },
          { id: "previousPosition", sequence: 14, type: "string", value: "14" },
          { id: "variation", sequence: 15, type: "string", value: "5" },
          { id: "variationPreviousPeriod", sequence: 16, type: "string", value: "5" },
          { id: "variationPreviousPeriodDate", sequence: 17, type: "string", value: "2024-02-28" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keywordName", sequence: 1, type: "string", value: "Electric Vans" },
          { id: "searchVolume", sequence: 2, type: "string", value: "8000" },
          { id: "competition", sequence: 3, type: "string", value: "Low" },
          { id: "competition_index", sequence: 4, type: "string", value: "0.35" },
          { id: "low_top_of_page_bid_micros", sequence: 5, type: "string", value: "1500000" },
          { id: "high_top_of_page_bid_micros", sequence: 6, type: "string", value: "4500000" },
          { id: "locationDisplayName", sequence: 7, type: "string", value: "Australia" },
          { id: "deviceTypeDescription", sequence: 8, type: "icon", value: "tablet" },
          { id: "searchEngineTypeDescription", sequence: 9, type: "icon", value: "yahoo" },
          { id: "url", sequence: 10, type: "url", value: "https://www.ford.com" },
          { id: "auditTime", sequence: 11, type: "string", value: "2024-04-01" },
          { id: "position", sequence: 12, type: "string", value: "6" },
          { id: "auditVariationTime", sequence: 13, type: "string", value: "2024-03-15" },
          { id: "previousPosition", sequence: 14, type: "string", value: "8" },
          { id: "variation", sequence: 15, type: "string", value: "2" },
          { id: "variationPreviousPeriod", sequence: 16, type: "string", value: "2" },
          { id: "variationPreviousPeriodDate", sequence: 17, type: "string", value: "2024-03-31" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keywordName", sequence: 1, type: "string", value: "Luxury Electric Cars" },
          { id: "searchVolume", sequence: 2, type: "string", value: "5000" },
          { id: "competition", sequence: 3, type: "string", value: "Very High" },
          { id: "competition_index", sequence: 4, type: "string", value: "0.85" },
          { id: "low_top_of_page_bid_micros", sequence: 5, type: "string", value: "6000000" },
          { id: "high_top_of_page_bid_micros", sequence: 6, type: "string", value: "10000000" },
          { id: "locationDisplayName", sequence: 7, type: "string", value: "France" },
          { id: "deviceTypeDescription", sequence: 8, type: "icon", value: "desktop" },
          { id: "searchEngineTypeDescription", sequence: 9, type: "icon", value: "google" },
          { id: "url", sequence: 10, type: "url", value: "https://www.bmw.com" },
          { id: "auditTime", sequence: 11, type: "string", value: "2024-05-10" },
          { id: "position", sequence: 12, type: "string", value: "3" },
          { id: "auditVariationTime", sequence: 13, type: "string", value: "2024-04-25" },
          { id: "previousPosition", sequence: 14, type: "string", value: "5" },
          { id: "variation", sequence: 15, type: "string", value: "2" },
          { id: "variationPreviousPeriod", sequence: 16, type: "string", value: "2" },
          { id: "variationPreviousPeriodDate", sequence: 17, type: "string", value: "2024-04-20" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keywordName", sequence: 1, type: "string", value: "Electric Motorcycles" },
          { id: "searchVolume", sequence: 2, type: "string", value: "3000" },
          { id: "competition", sequence: 3, type: "string", value: "Medium" },
          { id: "competition_index", sequence: 4, type: "string", value: "0.60" },
          { id: "low_top_of_page_bid_micros", sequence: 5, type: "string", value: "2500000" },
          { id: "high_top_of_page_bid_micros", sequence: 6, type: "string", value: "5500000" },
          { id: "locationDisplayName", sequence: 7, type: "string", value: "India" },
          { id: "deviceTypeDescription", sequence: 8, type: "icon", value: "desktop" },
          { id: "searchEngineTypeDescription", sequence: 9, type: "icon", value: "yahoo" },
          { id: "url", sequence: 10, type: "url", value: "https://www.harley-davidson.com" },
          { id: "auditTime", sequence: 11, type: "string", value: "2024-06-01" },
          { id: "position", sequence: 12, type: "string", value: "4" },
          { id: "auditVariationTime", sequence: 13, type: "string", value: "2024-05-20" },
          { id: "previousPosition", sequence: 14, type: "string", value: "6" },
          { id: "variation", sequence: 15, type: "string", value: "2" },
          { id: "variationPreviousPeriod", sequence: 16, type: "string", value: "2" },
          { id: "variationPreviousPeriodDate", sequence: 17, type: "string", value: "2024-05-15" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keywordName", sequence: 1, type: "string", value: "Self-Driving Cars" },
          { id: "searchVolume", sequence: 2, type: "string", value: "10000" },
          { id: "competition", sequence: 3, type: "string", value: "Very High" },
          { id: "competition_index", sequence: 4, type: "string", value: "0.90" },
          { id: "low_top_of_page_bid_micros", sequence: 5, type: "string", value: "8000000" },
          { id: "high_top_of_page_bid_micros", sequence: 6, type: "string", value: "15000000" },
          { id: "locationDisplayName", sequence: 7, type: "string", value: "Japan" },
          { id: "deviceTypeDescription", sequence: 8, type: "icon", value: "mobile" },
          { id: "searchEngineTypeDescription", sequence: 9, type: "icon", value: "google" },
          { id: "url", sequence: 10, type: "url", value: "https://www.mazda.com" },
          { id: "auditTime", sequence: 11, type: "string", value: "2024-07-10" },
          { id: "position", sequence: 12, type: "string", value: "2" },
          { id: "auditVariationTime", sequence: 13, type: "string", value: "2024-06-30" },
          { id: "previousPosition", sequence: 14, type: "string", value: "5" },
          { id: "variation", sequence: 15, type: "string", value: "3" },
          { id: "variationPreviousPeriod", sequence: 16, type: "string", value: "3" },
          { id: "variationPreviousPeriodDate", sequence: 17, type: "string", value: "2024-06-25" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keywordName", sequence: 1, type: "string", value: "Autonomous Vehicles" },
          { id: "searchVolume", sequence: 2, type: "string", value: "2000" },
          { id: "competition", sequence: 3, type: "string", value: "High" },
          { id: "competition_index", sequence: 4, type: "string", value: "0.70" },
          { id: "low_top_of_page_bid_micros", sequence: 5, type: "string", value: "5000000" },
          { id: "high_top_of_page_bid_micros", sequence: 6, type: "string", value: "12000000" },
          { id: "locationDisplayName", sequence: 7, type: "string", value: "China" },
          { id: "deviceTypeDescription", sequence: 8, type: "icon", value: "tablet" },
          { id: "searchEngineTypeDescription", sequence: 9, type: "icon", value: "yahoo" },
          { id: "url", sequence: 10, type: "url", value: "https://www.geely.com" },
          { id: "auditTime", sequence: 11, type: "string", value: "2024-08-20" },
          { id: "position", sequence: 12, type: "string", value: "10" },
          { id: "auditVariationTime", sequence: 13, type: "string", value: "2024-08-05" },
          { id: "previousPosition", sequence: 14, type: "string", value: "12" },
          { id: "variation", sequence: 15, type: "string", value: "2" },
          { id: "variationPreviousPeriod", sequence: 16, type: "string", value: "2" },
          { id: "variationPreviousPeriodDate", sequence: 17, type: "string", value: "2024-07-30" }
        ],
      ]
    case "serp-listGrid-competition":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "auditTime", sequence: 1, type: "string", value: "2024-05-31" },
          { id: "keywordName", sequence: 2, type: "string", value: "internet speed test" },
          { id: "locationDisplayName", sequence: 3, type: "string", value: "United States" },
          { id: "deviceTypeDescription", sequence: 4, type: "icon", value: "desktop" },
          { id: "searchEngineTypeDescription", sequence: 5, type: "icon", value: "google" },
          { id: "position", sequence: 6, type: "string", value: "1" },
          { id: "variation", sequence: 7, type: "string", value: "0" },
          { id: "1-pos", sequence: 11, type: "string", value: "3" },
          { id: "1-var", sequence: 12, type: "string", value: "0" },
          { id: "2-pos", sequence: 21, type: "string", value: "3" },
          { id: "2-var", sequence: 22, type: "string", value: "0" },
          { id: "3-pos", sequence: 31, type: "string", value: "5" },
          { id: "3-var", sequence: 32, type: "string", value: "-2" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "auditTime", sequence: 1, type: "string", value: "2024-05-31" },
          { id: "keywordName", sequence: 2, type: "string", value: "Internet Speed Test" },
          { id: "locationDisplayName", sequence: 3, type: "string", value: "United States" },
          { id: "deviceTypeDescription", sequence: 4, type: "icon", value: "desktop" },
          { id: "searchEngineTypeDescription", sequence: 5, type: "icon", value: "google" },
          { id: "position", sequence: 6, type: "string", value: "1" },
          { id: "variation", sequence: 7, type: "string", value: "0" },
          { id: "1-pos", sequence: 11, type: "string", value: "2" },
          { id: "1-var", sequence: 12, type: "string", value: "1" },
          { id: "2-pos", sequence: 21, type: "string", value: "2" },
          { id: "2-var", sequence: 22, type: "string", value: "1" },
          { id: "3-pos", sequence: 31, type: "string", value: "2" },
          { id: "3-var", sequence: 32, type: "string", value: "0" }
        ]
      ]
    case "ga4-listGrid-acquisitionAll":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "Organic Search" },
          { id: "totalUsers", sequence: 2, type: "string", value: "532" },
          { id: "activeUsers", sequence: 3, type: "string", value: "552" },
          { id: "newUsers", sequence: 4, type: "string", value: "52" },
          { id: "sessions", sequence: 5, type: "string", value: "77" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "7" },
          { id: "engagementRate", sequence: 7, type: "string", value: "767" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "707" },
          { id: "eventCount", sequence: 9, type: "string", value: "308" },
          { id: "keyEvents", sequence: 10, type: "string", value: "3048" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$348" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "Social Media" },
          { id: "totalUsers", sequence: 2, type: "string", value: "250" },
          { id: "activeUsers", sequence: 3, type: "string", value: "230" },
          { id: "newUsers", sequence: 4, type: "string", value: "25" },
          { id: "sessions", sequence: 5, type: "string", value: "50" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "20" },
          { id: "engagementRate", sequence: 7, type: "string", value: "400" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "87" },
          { id: "eventCount", sequence: 9, type: "string", value: "150" },
          { id: "keyEvents", sequence: 10, type: "string", value: "1450" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$120" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 1 },
          { id: "channel", sequence: 1, type: "string", value: "Email Campaign" },
          { id: "totalUsers", sequence: 2, type: "string", value: "300" },
          { id: "activeUsers", sequence: 3, type: "string", value: "280" },
          { id: "newUsers", sequence: 4, type: "string", value: "30" },
          { id: "sessions", sequence: 5, type: "string", value: "45" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "15" },
          { id: "engagementRate", sequence: 7, type: "string", value: "333" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "54" },
          { id: "eventCount", sequence: 9, type: "string", value: "120" },
          { id: "keyEvents", sequence: 10, type: "string", value: "1230" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$200" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 2 },
          { id: "channel", sequence: 1, type: "string", value: "Paid Ads" },
          { id: "totalUsers", sequence: 2, type: "string", value: "400" },
          { id: "activeUsers", sequence: 3, type: "string", value: "390" },
          { id: "newUsers", sequence: 4, type: "string", value: "40" },
          { id: "sessions", sequence: 5, type: "string", value: "100" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "35" },
          { id: "engagementRate", sequence: 7, type: "string", value: "500" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "90" },
          { id: "eventCount", sequence: 9, type: "string", value: "250" },
          { id: "keyEvents", sequence: 10, type: "string", value: "2100" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$350" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 3 },
          { id: "channel", sequence: 1, type: "string", value: "Referral" },
          { id: "totalUsers", sequence: 2, type: "string", value: "150" },
          { id: "activeUsers", sequence: 3, type: "string", value: "145" },
          { id: "newUsers", sequence: 4, type: "string", value: "15" },
          { id: "sessions", sequence: 5, type: "string", value: "30" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "10" },
          { id: "engagementRate", sequence: 7, type: "string", value: "333" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "69" },
          { id: "eventCount", sequence: 9, type: "string", value: "75" },
          { id: "keyEvents", sequence: 10, type: "string", value: "750" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$60" }
        ]
      ]
    case "ga4-listGrid-organicSearch":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "Google / Organic" },
          { id: "totalUsers", sequence: 2, type: "string", value: "29" },
          { id: "activeUsers", sequence: 3, type: "string", value: "293" },
          { id: "newUsers", sequence: 4, type: "string", value: "25" },
          { id: "sessions", sequence: 5, type: "string", value: "370" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "20" },
          { id: "engagementRate", sequence: 7, type: "string", value: "56.22%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.41" },
          { id: "eventCount", sequence: 9, type: "string", value: "163" },
          { id: "keyEvents", sequence: 10, type: "string", value: "8" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 1 },
          { id: "channel", sequence: 1, type: "string", value: "Yahoo / Organic" },
          { id: "totalUsers", sequence: 2, type: "string", value: "5" },
          { id: "activeUsers", sequence: 3, type: "string", value: "4" },
          { id: "newUsers", sequence: 4, type: "string", value: "44" },
          { id: "sessions", sequence: 5, type: "string", value: "52" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "45" },
          { id: "engagementRate", sequence: 7, type: "string", value: "89.54%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "1" },
          { id: "eventCount", sequence: 9, type: "string", value: "28" },
          { id: "keyEvents", sequence: 10, type: "string", value: "4" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 2 },
          { id: "channel", sequence: 1, type: "string", value: "Bing / Organic" },
          { id: "totalUsers", sequence: 2, type: "string", value: "1" },
          { id: "activeUsers", sequence: 3, type: "string", value: "10" },
          { id: "newUsers", sequence: 4, type: "string", value: "1" },
          { id: "sessions", sequence: 5, type: "string", value: "3" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "1" },
          { id: "engagementRate", sequence: 7, type: "string", value: "30.33%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "10" },
          { id: "eventCount", sequence: 9, type: "string", value: "8" },
          { id: "keyEvents", sequence: 10, type: "string", value: "5" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ]
      ]
    case "ga4-listGrid-paidSearch":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "Google / CPC" },
          { id: "adsOption", sequence: 2, type: "string", value: "Branding" },
          { id: "totalUsers", sequence: 3, type: "string", value: "10" },
          { id: "activeUsers", sequence: 4, type: "string", value: "10" },
          { id: "newUsers", sequence: 5, type: "string", value: "9" },
          { id: "sessions", sequence: 6, type: "string", value: "13" },
          { id: "engagedSessions", sequence: 7, type: "string", value: "6" },
          { id: "engagementRate", sequence: 8, type: "string", value: "4.15%" },
          { id: "engagedSessionsPerActiveUser", sequence: 9, type: "string", value: "1.60" },
          { id: "eventCount", sequence: 10, type: "string", value: "5" },
          { id: "keyEvents", sequence: 11, type: "string", value: "1" },
          { id: "totalRevenue", sequence: 12, type: "string", value: "$10" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 1 },
          { id: "channel", sequence: 1, type: "string", value: "Google / CPC" },
          { id: "adsOption", sequence: 2, type: "string", value: "(not set)" },
          { id: "totalUsers", sequence: 3, type: "string", value: "81" },
          { id: "activeUsers", sequence: 4, type: "string", value: "8" },
          { id: "newUsers", sequence: 5, type: "string", value: "80" },
          { id: "sessions", sequence: 6, type: "string", value: "8" },
          { id: "engagedSessions", sequence: 7, type: "string", value: "20" },
          { id: "engagementRate", sequence: 8, type: "string", value: "2.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 9, type: "string", value: "0.25" },
          { id: "eventCount", sequence: 10, type: "string", value: "8" },
          { id: "keyEvents", sequence: 11, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 12, type: "string", value: "$40" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 2 },
          { id: "channel", sequence: 1, type: "string", value: "Google / CPC" },
          { id: "adsOption", sequence: 2, type: "string", value: "VIC" },
          { id: "totalUsers", sequence: 3, type: "string", value: "8" },
          { id: "activeUsers", sequence: 4, type: "string", value: "18" },
          { id: "newUsers", sequence: 5, type: "string", value: "8" },
          { id: "sessions", sequence: 6, type: "string", value: "10" },
          { id: "engagedSessions", sequence: 7, type: "string", value: "5" },
          { id: "engagementRate", sequence: 8, type: "string", value: "4.45%" },
          { id: "engagedSessionsPerActiveUser", sequence: 9, type: "string", value: "0.63" },
          { id: "eventCount", sequence: 10, type: "string", value: "4" },
          { id: "keyEvents", sequence: 11, type: "string", value: "10" },
          { id: "totalRevenue", sequence: 12, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 3 },
          { id: "channel", sequence: 1, type: "string", value: "Yahoo / CPC" },
          { id: "adsOption", sequence: 2, type: "string", value: "SN | Pmax" },
          { id: "totalUsers", sequence: 3, type: "string", value: "6" },
          { id: "activeUsers", sequence: 4, type: "string", value: "5" },
          { id: "newUsers", sequence: 5, type: "string", value: "5" },
          { id: "sessions", sequence: 6, type: "string", value: "7" },
          { id: "engagedSessions", sequence: 7, type: "string", value: "1" },
          { id: "engagementRate", sequence: 8, type: "string", value: "14.29%" },
          { id: "engagedSessionsPerActiveUser", sequence: 9, type: "string", value: "1.20" },
          { id: "eventCount", sequence: 10, type: "string", value: "200" },
          { id: "keyEvents", sequence: 11, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 12, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 4 },
          { id: "channel", sequence: 1, type: "string", value: "Google / CPC" },
          { id: "adsOption", sequence: 2, type: "string", value: "(referral)" },
          { id: "totalUsers", sequence: 3, type: "string", value: "20" },
          { id: "activeUsers", sequence: 4, type: "string", value: "2" },
          { id: "newUsers", sequence: 5, type: "string", value: "2" },
          { id: "sessions", sequence: 6, type: "string", value: "12" },
          { id: "engagedSessions", sequence: 7, type: "string", value: "0" },
          { id: "engagementRate", sequence: 8, type: "string", value: "2.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 9, type: "string", value: "10" },
          { id: "eventCount", sequence: 10, type: "string", value: "6" },
          { id: "keyEvents", sequence: 11, type: "string", value: "140" },
          { id: "totalRevenue", sequence: 12, type: "string", value: "$0" }
        ]
      ]
    case "ga4-listGrid-direct":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "(direct) / (none)" },
          { id: "pageOption", sequence: 2, type: "string", value: "Digital Solutions Agency" },
          { id: "totalUsers", sequence: 3, type: "string", value: "112" },
          { id: "activeUsers", sequence: 4, type: "string", value: "17" },
          { id: "newUsers", sequence: 5, type: "string", value: "94" },
          { id: "sessions", sequence: 6, type: "string", value: "141" },
          { id: "engagedSessions", sequence: 7, type: "string", value: "64" },
          { id: "engagementRate", sequence: 8, type: "string", value: "4.39%" },
          { id: "engagedSessionsPerActiveUser", sequence: 9, type: "string", value: "0.60" },
          { id: "eventCount", sequence: 10, type: "string", value: "99" },
          { id: "keyEvents", sequence: 11, type: "string", value: "1" },
          { id: "totalRevenue", sequence: 12, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 1 },
          { id: "channel", sequence: 1, type: "string", value: "(direct) / (none)" },
          { id: "pageOption", sequence: 2, type: "string", value: "SEO Audit Pros" },
          { id: "totalUsers", sequence: 3, type: "string", value: "490" },
          { id: "activeUsers", sequence: 4, type: "string", value: "49" },
          { id: "newUsers", sequence: 5, type: "string", value: "480" },
          { id: "sessions", sequence: 6, type: "string", value: "52" },
          { id: "engagedSessions", sequence: 7, type: "string", value: "14" },
          { id: "engagementRate", sequence: 8, type: "string", value: "2.92%" },
          { id: "engagedSessionsPerActiveUser", sequence: 9, type: "string", value: "0.29" },
          { id: "eventCount", sequence: 10, type: "string", value: "18" },
          { id: "keyEvents", sequence: 11, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 12, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 2 },
          { id: "channel", sequence: 1, type: "string", value: "(direct) / (none)" },
          { id: "pageOption", sequence: 2, type: "string", value: "Top SEO Agency" },
          { id: "totalUsers", sequence: 3, type: "string", value: "2" },
          { id: "activeUsers", sequence: 4, type: "string", value: "28" },
          { id: "newUsers", sequence: 5, type: "string", value: "24" },
          { id: "sessions", sequence: 6, type: "string", value: "3" },
          { id: "engagedSessions", sequence: 7, type: "string", value: "14" },
          { id: "engagementRate", sequence: 8, type: "string", value: "43.0%" },
          { id: "engagedSessionsPerActiveUser", sequence: 9, type: "string", value: "0.50" },
          { id: "eventCount", sequence: 10, type: "string", value: "10" },
          { id: "keyEvents", sequence: 11, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 12, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 3 },
          { id: "channel", sequence: 1, type: "string", value: "(direct) / (none)" },
          { id: "pageOption", sequence: 2, type: "string", value: "Contact Our Team" },
          { id: "totalUsers", sequence: 3, type: "string", value: "2" },
          { id: "activeUsers", sequence: 4, type: "string", value: "22" },
          { id: "newUsers", sequence: 5, type: "string", value: "7" },
          { id: "sessions", sequence: 6, type: "string", value: "2" },
          { id: "engagedSessions", sequence: 7, type: "string", value: "19" },
          { id: "engagementRate", sequence: 8, type: "string", value: "6.86%" },
          { id: "engagedSessionsPerActiveUser", sequence: 9, type: "string", value: "0.86" },
          { id: "eventCount", sequence: 10, type: "string", value: "7" },
          { id: "keyEvents", sequence: 11, type: "string", value: "5" },
          { id: "totalRevenue", sequence: 12, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 4 },
          { id: "channel", sequence: 1, type: "string", value: "(direct) / (none)" },
          { id: "pageOption", sequence: 2, type: "string", value: "Agency Reviews and More" },
          { id: "totalUsers", sequence: 3, type: "string", value: "107" },
          { id: "activeUsers", sequence: 4, type: "string", value: "17" },
          { id: "newUsers", sequence: 5, type: "string", value: "11" },
          { id: "sessions", sequence: 6, type: "string", value: "6" },
          { id: "engagedSessions", sequence: 7, type: "string", value: "33" },
          { id: "engagementRate", sequence: 8, type: "string", value: "57.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 9, type: "string", value: "1.94" },
          { id: "eventCount", sequence: 10, type: "string", value: "37" },
          { id: "keyEvents", sequence: 11, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 12, type: "string", value: "$0" }
        ]
      ]
    case "ga4-listGrid-social":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "instagram.com" },
          { id: "totalUsers", sequence: 2, type: "string", value: "1" },
          { id: "activeUsers", sequence: 3, type: "string", value: "1" },
          { id: "newUsers", sequence: 4, type: "string", value: "1" },
          { id: "sessions", sequence: 5, type: "string", value: "1" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "1" },
          { id: "engagementRate", sequence: 7, type: "string", value: "100.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "1" },
          { id: "eventCount", sequence: 9, type: "string", value: "5" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$50" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "m.facebook.com" },
          { id: "totalUsers", sequence: 2, type: "string", value: "1" },
          { id: "activeUsers", sequence: 3, type: "string", value: "1" },
          { id: "newUsers", sequence: 4, type: "string", value: "1" },
          { id: "sessions", sequence: 5, type: "string", value: "2" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "0" },
          { id: "engagementRate", sequence: 7, type: "string", value: "0.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0" },
          { id: "eventCount", sequence: 9, type: "string", value: "7" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "trustpilot" },
          { id: "totalUsers", sequence: 2, type: "string", value: "1" },
          { id: "activeUsers", sequence: 3, type: "string", value: "1" },
          { id: "newUsers", sequence: 4, type: "string", value: "1" },
          { id: "sessions", sequence: 5, type: "string", value: "1" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "1" },
          { id: "engagementRate", sequence: 7, type: "string", value: "100.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "1" },
          { id: "eventCount", sequence: 9, type: "string", value: "5" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "web.skype.com" },
          { id: "totalUsers", sequence: 2, type: "string", value: "1" },
          { id: "activeUsers", sequence: 3, type: "string", value: "1" },
          { id: "newUsers", sequence: 4, type: "string", value: "1" },
          { id: "sessions", sequence: 5, type: "string", value: "1" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "0" },
          { id: "engagementRate", sequence: 7, type: "string", value: "0.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0" },
          { id: "eventCount", sequence: 9, type: "string", value: "3" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$40" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "site5.com" },
          { id: "totalUsers", sequence: 2, type: "string", value: "1" },
          { id: "activeUsers", sequence: 3, type: "string", value: "1" },
          { id: "newUsers", sequence: 4, type: "string", value: "1" },
          { id: "sessions", sequence: 5, type: "string", value: "1" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "1" },
          { id: "engagementRate", sequence: 7, type: "string", value: "100.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "1" },
          { id: "eventCount", sequence: 9, type: "string", value: "9" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$20" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "glassdoor.com" },
          { id: "totalUsers", sequence: 2, type: "string", value: "1" },
          { id: "activeUsers", sequence: 3, type: "string", value: "1" },
          { id: "newUsers", sequence: 4, type: "string", value: "1" },
          { id: "sessions", sequence: 5, type: "string", value: "2" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "2" },
          { id: "engagementRate", sequence: 7, type: "string", value: "100.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "2" },
          { id: "eventCount", sequence: 9, type: "string", value: "11" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ]
      ]
    case "ga4-listGrid-referral":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "docs.google.com" },
          { id: "totalUsers", sequence: 2, type: "string", value: "4" },
          { id: "activeUsers", sequence: 3, type: "string", value: "4" },
          { id: "newUsers", sequence: 4, type: "string", value: "4" },
          { id: "sessions", sequence: 5, type: "string", value: "4" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "3" },
          { id: "engagementRate", sequence: 7, type: "string", value: "75.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.75" },
          { id: "eventCount", sequence: 9, type: "string", value: "25" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "upwork.com" },
          { id: "totalUsers", sequence: 2, type: "string", value: "3" },
          { id: "activeUsers", sequence: 3, type: "string", value: "3" },
          { id: "newUsers", sequence: 4, type: "string", value: "3" },
          { id: "sessions", sequence: 5, type: "string", value: "3" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "3" },
          { id: "engagementRate", sequence: 7, type: "string", value: "100.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "1" },
          { id: "eventCount", sequence: 9, type: "string", value: "16" },
          { id: "keyEvents", sequence: 10, type: "string", value: "1" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "adclick.g.doubleclick.net" },
          { id: "totalUsers", sequence: 2, type: "string", value: "1" },
          { id: "activeUsers", sequence: 3, type: "string", value: "1" },
          { id: "newUsers", sequence: 4, type: "string", value: "1" },
          { id: "sessions", sequence: 5, type: "string", value: "1" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "0" },
          { id: "engagementRate", sequence: 7, type: "string", value: "0.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0" },
          { id: "eventCount", sequence: 9, type: "string", value: "3" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "designrush.com" },
          { id: "totalUsers", sequence: 2, type: "string", value: "1" },
          { id: "activeUsers", sequence: 3, type: "string", value: "1" },
          { id: "newUsers", sequence: 4, type: "string", value: "1" },
          { id: "sessions", sequence: 5, type: "string", value: "1" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "1" },
          { id: "engagementRate", sequence: 7, type: "string", value: "100.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "1" },
          { id: "eventCount", sequence: 9, type: "string", value: "8" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "siteE.com" },
          { id: "totalUsers", sequence: 2, type: "string", value: "1" },
          { id: "activeUsers", sequence: 3, type: "string", value: "1" },
          { id: "newUsers", sequence: 4, type: "string", value: "1" },
          { id: "sessions", sequence: 5, type: "string", value: "1" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "1" },
          { id: "engagementRate", sequence: 7, type: "string", value: "100.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "1" },
          { id: "eventCount", sequence: 9, type: "string", value: "10" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "themanifest.com" },
          { id: "totalUsers", sequence: 2, type: "string", value: "1" },
          { id: "activeUsers", sequence: 3, type: "string", value: "1" },
          { id: "newUsers", sequence: 4, type: "string", value: "1" },
          { id: "sessions", sequence: 5, type: "string", value: "1" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "0" },
          { id: "engagementRate", sequence: 7, type: "string", value: "0.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0" },
          { id: "eventCount", sequence: 9, type: "string", value: "3" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ]
      ]
    case "ga4-listGrid-display":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "google" },
          { id: "totalUsers", sequence: 2, type: "string", value: "14" },
          { id: "activeUsers", sequence: 3, type: "string", value: "40" },
          { id: "newUsers", sequence: 4, type: "string", value: "2" },
          { id: "sessions", sequence: 5, type: "string", value: "85" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "30" },
          { id: "engagementRate", sequence: 7, type: "string", value: "7.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.75" },
          { id: "eventCount", sequence: 9, type: "string", value: "250" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$60" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "bing.com" },
          { id: "totalUsers", sequence: 2, type: "string", value: "8" },
          { id: "activeUsers", sequence: 3, type: "string", value: "3" },
          { id: "newUsers", sequence: 4, type: "string", value: "30" },
          { id: "sessions", sequence: 5, type: "string", value: "3" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "3" },
          { id: "engagementRate", sequence: 7, type: "string", value: "10.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "1" },
          { id: "eventCount", sequence: 9, type: "string", value: "16" },
          { id: "keyEvents", sequence: 10, type: "string", value: "1" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$80" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "channel", sequence: 1, type: "string", value: "duckduckgo" },
          { id: "totalUsers", sequence: 2, type: "string", value: "15" },
          { id: "activeUsers", sequence: 3, type: "string", value: "1" },
          { id: "newUsers", sequence: 4, type: "string", value: "10" },
          { id: "sessions", sequence: 5, type: "string", value: "1" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "0" },
          { id: "engagementRate", sequence: 7, type: "string", value: "0.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0" },
          { id: "eventCount", sequence: 9, type: "string", value: "38" },
          { id: "keyEvents", sequence: 10, type: "string", value: "20" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$20" }
        ],
      ]
    case "ga4-listGrid-location":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "Zarland" },
          { id: "totalUsers", sequence: 2, type: "string", value: "499" },
          { id: "activeUsers", sequence: 3, type: "string", value: "485" },
          { id: "newUsers", sequence: 4, type: "string", value: "480" },
          { id: "sessions", sequence: 5, type: "string", value: "583" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "260" },
          { id: "engagementRate", sequence: 7, type: "string", value: "44.60%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.54" },
          { id: "eventCount", sequence: 9, type: "string", value: "2468" },
          { id: "keyEvents", sequence: 10, type: "string", value: "7" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "Bermuda" },
          { id: "totalUsers", sequence: 2, type: "string", value: "145" },
          { id: "activeUsers", sequence: 3, type: "string", value: "136" },
          { id: "newUsers", sequence: 4, type: "string", value: "129" },
          { id: "sessions", sequence: 5, type: "string", value: "207" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "127" },
          { id: "engagementRate", sequence: 7, type: "string", value: "61.35%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.93" },
          { id: "eventCount", sequence: 9, type: "string", value: "952" },
          { id: "keyEvents", sequence: 10, type: "string", value: "8" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "Fictionland" },
          { id: "totalUsers", sequence: 2, type: "string", value: "94" },
          { id: "activeUsers", sequence: 3, type: "string", value: "90" },
          { id: "newUsers", sequence: 4, type: "string", value: "81" },
          { id: "sessions", sequence: 5, type: "string", value: "110" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "83" },
          { id: "engagementRate", sequence: 7, type: "string", value: "75.45%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.92" },
          { id: "eventCount", sequence: 9, type: "string", value: "523" },
          { id: "keyEvents", sequence: 10, type: "string", value: "4" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "New Zealand" },
          { id: "totalUsers", sequence: 2, type: "string", value: "93" },
          { id: "activeUsers", sequence: 3, type: "string", value: "91" },
          { id: "newUsers", sequence: 4, type: "string", value: "88" },
          { id: "sessions", sequence: 5, type: "string", value: "100" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "31" },
          { id: "engagementRate", sequence: 7, type: "string", value: "31.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.34" },
          { id: "eventCount", sequence: 9, type: "string", value: "357" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "Pakistan" },
          { id: "totalUsers", sequence: 2, type: "string", value: "40" },
          { id: "activeUsers", sequence: 3, type: "string", value: "40" },
          { id: "newUsers", sequence: 4, type: "string", value: "40" },
          { id: "sessions", sequence: 5, type: "string", value: "40" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "9" },
          { id: "engagementRate", sequence: 7, type: "string", value: "22.50%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.23" },
          { id: "eventCount", sequence: 9, type: "string", value: "129" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "Nigerland" },
          { id: "totalUsers", sequence: 2, type: "string", value: "29" },
          { id: "activeUsers", sequence: 3, type: "string", value: "28" },
          { id: "newUsers", sequence: 4, type: "string", value: "21" },
          { id: "sessions", sequence: 5, type: "string", value: "137" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "59" },
          { id: "engagementRate", sequence: 7, type: "string", value: "43.07%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "2.11" },
          { id: "eventCount", sequence: 9, type: "string", value: "467" },
          { id: "keyEvents", sequence: 10, type: "string", value: "1" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ]
      ]
    case "ga4-listGrid-language":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "language", sequence: 1, type: "string", value: "English" },
          { id: "totalUsers", sequence: 2, type: "string", value: "90" },
          { id: "activeUsers", sequence: 3, type: "string", value: "961" },
          { id: "newUsers", sequence: 4, type: "string", value: "93" },
          { id: "sessions", sequence: 5, type: "string", value: "182" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "64" },
          { id: "engagementRate", sequence: 7, type: "string", value: "48.67%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "2.65" },
          { id: "eventCount", sequence: 9, type: "string", value: "536" },
          { id: "keyEvents", sequence: 10, type: "string", value: "23" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "language", sequence: 1, type: "string", value: "Chinese" },
          { id: "totalUsers", sequence: 2, type: "string", value: "146" },
          { id: "activeUsers", sequence: 3, type: "string", value: "16" },
          { id: "newUsers", sequence: 4, type: "string", value: "15" },
          { id: "sessions", sequence: 5, type: "string", value: "160" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "5" },
          { id: "engagementRate", sequence: 7, type: "string", value: "41.25%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.31" },
          { id: "eventCount", sequence: 9, type: "string", value: "680" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "language", sequence: 1, type: "string", value: "Spanish" },
          { id: "totalUsers", sequence: 2, type: "string", value: "9" },
          { id: "activeUsers", sequence: 3, type: "string", value: "7" },
          { id: "newUsers", sequence: 4, type: "string", value: "96" },
          { id: "sessions", sequence: 5, type: "string", value: "11" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "2" },
          { id: "engagementRate", sequence: 7, type: "string", value: "19.18%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.29" },
          { id: "eventCount", sequence: 9, type: "string", value: "380" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "language", sequence: 1, type: "string", value: "German" },
          { id: "totalUsers", sequence: 2, type: "string", value: "17" },
          { id: "activeUsers", sequence: 3, type: "string", value: "7" },
          { id: "newUsers", sequence: 4, type: "string", value: "6" },
          { id: "sessions", sequence: 5, type: "string", value: "70" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "3" },
          { id: "engagementRate", sequence: 7, type: "string", value: "42.86%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.43" },
          { id: "eventCount", sequence: 9, type: "string", value: "26" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$10" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "language", sequence: 1, type: "string", value: "French" },
          { id: "totalUsers", sequence: 2, type: "string", value: "60" },
          { id: "activeUsers", sequence: 3, type: "string", value: "60" },
          { id: "newUsers", sequence: 4, type: "string", value: "56" },
          { id: "sessions", sequence: 5, type: "string", value: "78" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "20" },
          { id: "engagementRate", sequence: 7, type: "string", value: "33.33%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.33" },
          { id: "eventCount", sequence: 9, type: "string", value: "26" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "language", sequence: 1, type: "string", value: "Russian" },
          { id: "totalUsers", sequence: 2, type: "string", value: "6000" },
          { id: "activeUsers", sequence: 3, type: "string", value: "4" },
          { id: "newUsers", sequence: 4, type: "string", value: "50" },
          { id: "sessions", sequence: 5, type: "string", value: "90" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "29" },
          { id: "engagementRate", sequence: 7, type: "string", value: "29.22%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.50" },
          { id: "eventCount", sequence: 9, type: "string", value: "290" },
          { id: "keyEvents", sequence: 10, type: "string", value: "50" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$600" }
        ]
      ]
    case "ga4-listGrid-interests":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "interest", sequence: 1, type: "string", value: "Tech Lovers" },
          { id: "totalUsers", sequence: 2, type: "string", value: "306" },
          { id: "activeUsers", sequence: 3, type: "string", value: "35" },
          { id: "newUsers", sequence: 4, type: "string", value: "34" },
          { id: "sessions", sequence: 5, type: "string", value: "5" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "27" },
          { id: "engagementRate", sequence: 7, type: "string", value: "67.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.77" },
          { id: "eventCount", sequence: 9, type: "string", value: "195" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "interest", sequence: 1, type: "string", value: "Home Decor Lovers" },
          { id: "totalUsers", sequence: 2, type: "string", value: "28" },
          { id: "activeUsers", sequence: 3, type: "string", value: "208" },
          { id: "newUsers", sequence: 4, type: "string", value: "24" },
          { id: "sessions", sequence: 5, type: "string", value: "308" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "204" },
          { id: "engagementRate", sequence: 7, type: "string", value: "63.16%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.86" },
          { id: "eventCount", sequence: 9, type: "string", value: "1905" },
          { id: "keyEvents", sequence: 10, type: "string", value: "2" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "interest", sequence: 1, type: "string", value: "Music Fans" },
          { id: "totalUsers", sequence: 2, type: "string", value: "280" },
          { id: "activeUsers", sequence: 3, type: "string", value: "207" },
          { id: "newUsers", sequence: 4, type: "string", value: "27" },
          { id: "sessions", sequence: 5, type: "string", value: "34" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "17" },
          { id: "engagementRate", sequence: 7, type: "string", value: "7.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.63" },
          { id: "eventCount", sequence: 9, type: "string", value: "145" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "interest", sequence: 1, type: "string", value: "Film Buffs" },
          { id: "totalUsers", sequence: 2, type: "string", value: "27" },
          { id: "activeUsers", sequence: 3, type: "string", value: "260" },
          { id: "newUsers", sequence: 4, type: "string", value: "26" },
          { id: "sessions", sequence: 5, type: "string", value: "35" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "21" },
          { id: "engagementRate", sequence: 7, type: "string", value: "6.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.81" },
          { id: "eventCount", sequence: 9, type: "string", value: "157" },
          { id: "keyEvents", sequence: 10, type: "string", value: "3" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "interest", sequence: 1, type: "string", value: "Gamers" },
          { id: "totalUsers", sequence: 2, type: "string", value: "206" },
          { id: "activeUsers", sequence: 3, type: "string", value: "25" },
          { id: "newUsers", sequence: 4, type: "string", value: "24" },
          { id: "sessions", sequence: 5, type: "string", value: "300" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "17" },
          { id: "engagementRate", sequence: 7, type: "string", value: "6.67%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.68" },
          { id: "eventCount", sequence: 9, type: "string", value: "12" },
          { id: "keyEvents", sequence: 10, type: "string", value: "3" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "interest", sequence: 1, type: "string", value: "Culinary Enthusiasts" },
          { id: "totalUsers", sequence: 2, type: "string", value: "25" },
          { id: "activeUsers", sequence: 3, type: "string", value: "24" },
          { id: "newUsers", sequence: 4, type: "string", value: "24" },
          { id: "sessions", sequence: 5, type: "string", value: "29" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "18" },
          { id: "engagementRate", sequence: 7, type: "string", value: "6.67%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.75" },
          { id: "eventCount", sequence: 9, type: "string", value: "13" },
          { id: "keyEvents", sequence: 10, type: "string", value: "1" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ]
      ]
    case "ga4-listGrid-gender":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "gender", sequence: 1, type: "string", value: "Unknown" },
          { id: "totalUsers", sequence: 2, type: "string", value: "71" },
          { id: "activeUsers", sequence: 3, type: "string", value: "797" },
          { id: "newUsers", sequence: 4, type: "string", value: "76" },
          { id: "sessions", sequence: 5, type: "string", value: "802" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "303" },
          { id: "engagementRate", sequence: 7, type: "string", value: "43.8%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "4.52" },
          { id: "eventCount", sequence: 9, type: "string", value: "358" },
          { id: "keyEvents", sequence: 10, type: "string", value: "130" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$20" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "gender", sequence: 1, type: "string", value: "Male" },
          { id: "totalUsers", sequence: 2, type: "string", value: "11" },
          { id: "activeUsers", sequence: 3, type: "string", value: "193" },
          { id: "newUsers", sequence: 4, type: "string", value: "165" },
          { id: "sessions", sequence: 5, type: "string", value: "304" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "109" },
          { id: "engagementRate", sequence: 7, type: "string", value: "35.82%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.92" },
          { id: "eventCount", sequence: 9, type: "string", value: "128" },
          { id: "keyEvents", sequence: 10, type: "string", value: "7" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$50" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "gender", sequence: 1, type: "string", value: "Female" },
          { id: "totalUsers", sequence: 2, type: "string", value: "135" },
          { id: "activeUsers", sequence: 3, type: "string", value: "19" },
          { id: "newUsers", sequence: 4, type: "string", value: "170" },
          { id: "sessions", sequence: 5, type: "string", value: "164" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "112" },
          { id: "engagementRate", sequence: 7, type: "string", value: "87.73%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.87" },
          { id: "eventCount", sequence: 9, type: "string", value: "81" },
          { id: "keyEvents", sequence: 10, type: "string", value: "30" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$70" }
        ]
      ]
    case "ga4-listGrid-age":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "age", sequence: 1, type: "string", value: "18-26" },
          { id: "totalUsers", sequence: 2, type: "string", value: "10" },
          { id: "activeUsers", sequence: 3, type: "string", value: "14" },
          { id: "newUsers", sequence: 4, type: "string", value: "12" },
          { id: "sessions", sequence: 5, type: "string", value: "25" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "142" },
          { id: "engagementRate", sequence: 7, type: "string", value: "55.47%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.99" },
          { id: "eventCount", sequence: 9, type: "string", value: "100" },
          { id: "keyEvents", sequence: 10, type: "string", value: "6" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "age", sequence: 1, type: "string", value: "28-34" },
          { id: "totalUsers", sequence: 2, type: "string", value: "704" },
          { id: "activeUsers", sequence: 3, type: "string", value: "700" },
          { id: "newUsers", sequence: 4, type: "string", value: "605" },
          { id: "sessions", sequence: 5, type: "string", value: "114" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "607" },
          { id: "engagementRate", sequence: 7, type: "string", value: "58.77%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.96" },
          { id: "eventCount", sequence: 9, type: "string", value: "59" },
          { id: "keyEvents", sequence: 10, type: "string", value: "2" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "age", sequence: 1, type: "string", value: "45-54" },
          { id: "totalUsers", sequence: 2, type: "string", value: "31" },
          { id: "activeUsers", sequence: 3, type: "string", value: "31" },
          { id: "newUsers", sequence: 4, type: "string", value: "310" },
          { id: "sessions", sequence: 5, type: "string", value: "37" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "20" },
          { id: "engagementRate", sequence: 7, type: "string", value: "5.05%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.65" },
          { id: "eventCount", sequence: 9, type: "string", value: "15" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$40" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "age", sequence: 1, type: "string", value: "35-44" },
          { id: "totalUsers", sequence: 2, type: "string", value: "202" },
          { id: "activeUsers", sequence: 3, type: "string", value: "20" },
          { id: "newUsers", sequence: 4, type: "string", value: "170" },
          { id: "sessions", sequence: 5, type: "string", value: "34" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "18" },
          { id: "engagementRate", sequence: 7, type: "string", value: "52.94%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.90" },
          { id: "eventCount", sequence: 9, type: "string", value: "136" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "age", sequence: 1, type: "string", value: "55-64" },
          { id: "totalUsers", sequence: 2, type: "string", value: "105" },
          { id: "activeUsers", sequence: 3, type: "string", value: "15" },
          { id: "newUsers", sequence: 4, type: "string", value: "14" },
          { id: "sessions", sequence: 5, type: "string", value: "18" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "50" },
          { id: "engagementRate", sequence: 7, type: "string", value: "27.78%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.33" },
          { id: "eventCount", sequence: 9, type: "string", value: "603" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ]
      ]
    case "ga4-listGrid-audience":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "audience", sequence: 1, type: "string", value: "Global Users" },
          { id: "totalUsers", sequence: 2, type: "string", value: "1200" },
          { id: "activeUsers", sequence: 3, type: "string", value: "1150" },
          { id: "newUsers", sequence: 4, type: "string", value: "1100" },
          { id: "sessions", sequence: 5, type: "string", value: "1500" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "700" },
          { id: "engagementRate", sequence: 7, type: "string", value: "50.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.65" },
          { id: "eventCount", sequence: 9, type: "string", value: "6000" },
          { id: "keyEvents", sequence: 10, type: "string", value: "25" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "audience", sequence: 1, type: "string", value: "Regional Insights" },
          { id: "totalUsers", sequence: 2, type: "string", value: "450" },
          { id: "activeUsers", sequence: 3, type: "string", value: "440" },
          { id: "newUsers", sequence: 4, type: "string", value: "430" },
          { id: "sessions", sequence: 5, type: "string", value: "550" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "240" },
          { id: "engagementRate", sequence: 7, type: "string", value: "43.64%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.55" },
          { id: "eventCount", sequence: 9, type: "string", value: "2700" },
          { id: "keyEvents", sequence: 10, type: "string", value: "8" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "audience", sequence: 1, type: "string", value: "Mobile Users" },
          { id: "totalUsers", sequence: 2, type: "string", value: "600" },
          { id: "activeUsers", sequence: 3, type: "string", value: "580" },
          { id: "newUsers", sequence: 4, type: "string", value: "550" },
          { id: "sessions", sequence: 5, type: "string", value: "800" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "400" },
          { id: "engagementRate", sequence: 7, type: "string", value: "50.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.69" },
          { id: "eventCount", sequence: 9, type: "string", value: "3500" },
          { id: "keyEvents", sequence: 10, type: "string", value: "15" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "audience", sequence: 1, type: "string", value: "Desktop Users" },
          { id: "totalUsers", sequence: 2, type: "string", value: "500" },
          { id: "activeUsers", sequence: 3, type: "string", value: "480" },
          { id: "newUsers", sequence: 4, type: "string", value: "460" },
          { id: "sessions", sequence: 5, type: "string", value: "700" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "300" },
          { id: "engagementRate", sequence: 7, type: "string", value: "42.86%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.63" },
          { id: "eventCount", sequence: 9, type: "string", value: "2000" },
          { id: "keyEvents", sequence: 10, type: "string", value: "10" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "audience", sequence: 1, type: "string", value: "International Users" },
          { id: "totalUsers", sequence: 2, type: "string", value: "350" },
          { id: "activeUsers", sequence: 3, type: "string", value: "340" },
          { id: "newUsers", sequence: 4, type: "string", value: "330" },
          { id: "sessions", sequence: 5, type: "string", value: "450" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "220" },
          { id: "engagementRate", sequence: 7, type: "string", value: "40.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.65" },
          { id: "eventCount", sequence: 9, type: "string", value: "1800" },
          { id: "keyEvents", sequence: 10, type: "string", value: "5" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "audience", sequence: 1, type: "string", value: "Returning Users" },
          { id: "totalUsers", sequence: 2, type: "string", value: "800" },
          { id: "activeUsers", sequence: 3, type: "string", value: "780" },
          { id: "newUsers", sequence: 4, type: "string", value: "750" },
          { id: "sessions", sequence: 5, type: "string", value: "1000" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "500" },
          { id: "engagementRate", sequence: 7, type: "string", value: "62.50%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.64" },
          { id: "eventCount", sequence: 9, type: "string", value: "4200" },
          { id: "keyEvents", sequence: 10, type: "string", value: "20" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ]
      ]
    case "ga4-listGrid-event":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "eventName", sequence: 1, type: "string", value: "page_interaction" },
          { id: "totalUsers", sequence: 2, type: "string", value: "1100" },
          { id: "activeUsers", sequence: 3, type: "string", value: "1050" },
          { id: "newUsers", sequence: 4, type: "string", value: "5" },
          { id: "sessions", sequence: 5, type: "string", value: "1350" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "700" },
          { id: "engagementRate", sequence: 7, type: "string", value: "51.50%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.67" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "eventName", sequence: 1, type: "string", value: "page_exit" },
          { id: "totalUsers", sequence: 2, type: "string", value: "1080" },
          { id: "activeUsers", sequence: 3, type: "string", value: "1030" },
          { id: "newUsers", sequence: 4, type: "string", value: "2" },
          { id: "sessions", sequence: 5, type: "string", value: "1400" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "660" },
          { id: "engagementRate", sequence: 7, type: "string", value: "48.50%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.64" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "eventName", sequence: 1, type: "string", value: "conversion" },
          { id: "totalUsers", sequence: 2, type: "string", value: "800" },
          { id: "activeUsers", sequence: 3, type: "string", value: "790" },
          { id: "newUsers", sequence: 4, type: "string", value: "10" },
          { id: "sessions", sequence: 5, type: "string", value: "900" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "500" },
          { id: "engagementRate", sequence: 7, type: "string", value: "62.50%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.75" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "eventName", sequence: 1, type: "string", value: "form_submit" },
          { id: "totalUsers", sequence: 2, type: "string", value: "600" },
          { id: "activeUsers", sequence: 3, type: "string", value: "590" },
          { id: "newUsers", sequence: 4, type: "string", value: "1" },
          { id: "sessions", sequence: 5, type: "string", value: "700" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "450" },
          { id: "engagementRate", sequence: 7, type: "string", value: "64.29%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.76" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "eventName", sequence: 1, type: "string", value: "video_play" },
          { id: "totalUsers", sequence: 2, type: "string", value: "30" },
          { id: "activeUsers", sequence: 3, type: "string", value: "30" },
          { id: "newUsers", sequence: 4, type: "string", value: "0" },
          { id: "sessions", sequence: 5, type: "string", value: "35" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "25" },
          { id: "engagementRate", sequence: 7, type: "string", value: "83.33%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.83" }
        ]
      ]
    case "ga4-listGrid-pagesAndScreens":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pagePath", sequence: 1, type: "string", value: "Premier Digital Agency" },
          { id: "totalUsers", sequence: 2, type: "string", value: "120" },
          { id: "activeUsers", sequence: 3, type: "string", value: "115" },
          { id: "newUsers", sequence: 4, type: "string", value: "100" },
          { id: "sessions", sequence: 5, type: "string", value: "150" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "70" },
          { id: "engagementRate", sequence: 7, type: "string", value: "46.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.61" },
          { id: "eventCount", sequence: 9, type: "string", value: "520" },
          { id: "keyEvents", sequence: 10, type: "string", value: "2" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pagePath", sequence: 1, type: "string", value: "Comprehensive SEO Audit Services" },
          { id: "totalUsers", sequence: 2, type: "string", value: "60" },
          { id: "activeUsers", sequence: 3, type: "string", value: "55" },
          { id: "newUsers", sequence: 4, type: "string", value: "58" },
          { id: "sessions", sequence: 5, type: "string", value: "62" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "15" },
          { id: "engagementRate", sequence: 7, type: "string", value: "24.19%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.27" },
          { id: "eventCount", sequence: 9, type: "string", value: "220" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pagePath", sequence: 1, type: "string", value: "Award-Winning SEO Solutions" },
          { id: "totalUsers", sequence: 2, type: "string", value: "30" },
          { id: "activeUsers", sequence: 3, type: "string", value: "29" },
          { id: "newUsers", sequence: 4, type: "string", value: "24" },
          { id: "sessions", sequence: 5, type: "string", value: "33" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "15" },
          { id: "engagementRate", sequence: 7, type: "string", value: "45.45%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.51" },
          { id: "eventCount", sequence: 9, type: "string", value: "100" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pagePath", sequence: 1, type: "string", value: "Get in Touch" },
          { id: "totalUsers", sequence: 2, type: "string", value: "25" },
          { id: "activeUsers", sequence: 3, type: "string", value: "22" },
          { id: "newUsers", sequence: 4, type: "string", value: "8" },
          { id: "sessions", sequence: 5, type: "string", value: "30" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "20" },
          { id: "engagementRate", sequence: 7, type: "string", value: "66.67%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.90" },
          { id: "eventCount", sequence: 9, type: "string", value: "75" },
          { id: "keyEvents", sequence: 10, type: "string", value: "5" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pagePath", sequence: 1, type: "string", value: "Reviews and Info" },
          { id: "totalUsers", sequence: 2, type: "string", value: "20" },
          { id: "activeUsers", sequence: 3, type: "string", value: "18" },
          { id: "newUsers", sequence: 4, type: "string", value: "12" },
          { id: "sessions", sequence: 5, type: "string", value: "65" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "30" },
          { id: "engagementRate", sequence: 7, type: "string", value: "46.15%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "1.67" },
          { id: "eventCount", sequence: 9, type: "string", value: "250" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pagePath", sequence: 1, type: "string", value: "Join Our Marketing Team" },
          { id: "totalUsers", sequence: 2, type: "string", value: "15" },
          { id: "activeUsers", sequence: 3, type: "string", value: "14" },
          { id: "newUsers", sequence: 4, type: "string", value: "12" },
          { id: "sessions", sequence: 5, type: "string", value: "18" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "8" },
          { id: "engagementRate", sequence: 7, type: "string", value: "44.44%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.57" },
          { id: "eventCount", sequence: 9, type: "string", value: "55" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ]
      ]
    case "ga4-listGrid-platformDevice":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "platformDeviceCategory", sequence: 1, type: "string", value: "Web / Desktop" },
          { id: "totalUsers", sequence: 2, type: "string", value: "753" },
          { id: "activeUsers", sequence: 3, type: "string", value: "710" },
          { id: "newUsers", sequence: 4, type: "string", value: "680" },
          { id: "sessions", sequence: 5, type: "string", value: "1024" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "498" },
          { id: "engagementRate", sequence: 7, type: "string", value: "47.25%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.70" },
          { id: "eventCount", sequence: 9, type: "string", value: "3200" },
          { id: "keyEvents", sequence: 10, type: "string", value: "15" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "platformDeviceCategory", sequence: 1, type: "string", value: "Web / Mobile" },
          { id: "totalUsers", sequence: 2, type: "string", value: "212" },
          { id: "activeUsers", sequence: 3, type: "string", value: "200" },
          { id: "newUsers", sequence: 4, type: "string", value: "180" },
          { id: "sessions", sequence: 5, type: "string", value: "230" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "72" },
          { id: "engagementRate", sequence: 7, type: "string", value: "31.91%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.36" },
          { id: "eventCount", sequence: 9, type: "string", value: "950" },
          { id: "keyEvents", sequence: 10, type: "string", value: "3" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "platformDeviceCategory", sequence: 1, type: "string", value: "Web / Tablet" },
          { id: "totalUsers", sequence: 2, type: "string", value: "35" },
          { id: "activeUsers", sequence: 3, type: "string", value: "30" },
          { id: "newUsers", sequence: 4, type: "string", value: "28" },
          { id: "sessions", sequence: 5, type: "string", value: "40" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "15" },
          { id: "engagementRate", sequence: 7, type: "string", value: "37.50%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.50" },
          { id: "eventCount", sequence: 9, type: "string", value: "110" },
          { id: "keyEvents", sequence: 10, type: "string", value: "1" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "platformDeviceCategory", sequence: 1, type: "string", value: "Web / Smart TV" },
          { id: "totalUsers", sequence: 2, type: "string", value: "5" },
          { id: "activeUsers", sequence: 3, type: "string", value: "4" },
          { id: "newUsers", sequence: 4, type: "string", value: "3" },
          { id: "sessions", sequence: 5, type: "string", value: "5" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "3" },
          { id: "engagementRate", sequence: 7, type: "string", value: "60.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.75" },
          { id: "eventCount", sequence: 9, type: "string", value: "7" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ]
      ]
    case "ga4-listGrid-browser":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "browser", sequence: 1, type: "string", value: "Chrome" },
          { id: "totalUsers", sequence: 2, type: "string", value: "700" },
          { id: "activeUsers", sequence: 3, type: "string", value: "680" },
          { id: "newUsers", sequence: 4, type: "string", value: "650" },
          { id: "sessions", sequence: 5, type: "string", value: "900" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "500" },
          { id: "engagementRate", sequence: 7, type: "string", value: "55.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.74" },
          { id: "eventCount", sequence: 9, type: "string", value: "4500" },
          { id: "keyEvents", sequence: 10, type: "string", value: "30" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$100" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 1 },
          { id: "browser", sequence: 1, type: "string", value: "Safari" },
          { id: "totalUsers", sequence: 2, type: "string", value: "150" },
          { id: "activeUsers", sequence: 3, type: "string", value: "140" },
          { id: "newUsers", sequence: 4, type: "string", value: "130" },
          { id: "sessions", sequence: 5, type: "string", value: "200" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "60" },
          { id: "engagementRate", sequence: 7, type: "string", value: "40.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.43" },
          { id: "eventCount", sequence: 9, type: "string", value: "800" },
          { id: "keyEvents", sequence: 10, type: "string", value: "15" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$50" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 2 },
          { id: "browser", sequence: 1, type: "string", value: "Edge" },
          { id: "totalUsers", sequence: 2, type: "string", value: "90" },
          { id: "activeUsers", sequence: 3, type: "string", value: "85" },
          { id: "newUsers", sequence: 4, type: "string", value: "80" },
          { id: "sessions", sequence: 5, type: "string", value: "100" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "50" },
          { id: "engagementRate", sequence: 7, type: "string", value: "55.56%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.59" },
          { id: "eventCount", sequence: 9, type: "string", value: "400" },
          { id: "keyEvents", sequence: 10, type: "string", value: "10" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$25" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 3 },
          { id: "browser", sequence: 1, type: "string", value: "Samsung Internet" },
          { id: "totalUsers", sequence: 2, type: "string", value: "30" },
          { id: "activeUsers", sequence: 3, type: "string", value: "28" },
          { id: "newUsers", sequence: 4, type: "string", value: "25" },
          { id: "sessions", sequence: 5, type: "string", value: "35" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "9" },
          { id: "engagementRate", sequence: 7, type: "string", value: "25.71%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.32" },
          { id: "eventCount", sequence: 9, type: "string", value: "50" },
          { id: "keyEvents", sequence: 10, type: "string", value: "3" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$5" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 4 },
          { id: "browser", sequence: 1, type: "string", value: "Firefox" },
          { id: "totalUsers", sequence: 2, type: "string", value: "20" },
          { id: "activeUsers", sequence: 3, type: "string", value: "18" },
          { id: "newUsers", sequence: 4, type: "string", value: "16" },
          { id: "sessions", sequence: 5, type: "string", value: "22" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "12" },
          { id: "engagementRate", sequence: 7, type: "string", value: "54.55%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.67" },
          { id: "eventCount", sequence: 9, type: "string", value: "30" },
          { id: "keyEvents", sequence: 10, type: "string", value: "2" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$2" }
        ]
      ]
    case "ga4-listGrid-operatingSystem":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "operatingSystem", sequence: 1, type: "string", value: "Windows" },
          { id: "totalUsers", sequence: 2, type: "string", value: "520" },
          { id: "activeUsers", sequence: 3, type: "string", value: "510" },
          { id: "newUsers", sequence: 4, type: "string", value: "490" },
          { id: "sessions", sequence: 5, type: "string", value: "700" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "400" },
          { id: "engagementRate", sequence: 7, type: "string", value: "58.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.80" },
          { id: "eventCount", sequence: 9, type: "string", value: "3100" },
          { id: "keyEvents", sequence: 10, type: "string", value: "16" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 1 },
          { id: "operatingSystem", sequence: 1, type: "string", value: "Macintosh" },
          { id: "totalUsers", sequence: 2, type: "string", value: "240" },
          { id: "activeUsers", sequence: 3, type: "string", value: "230" },
          { id: "newUsers", sequence: 4, type: "string", value: "220" },
          { id: "sessions", sequence: 5, type: "string", value: "260" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "120" },
          { id: "engagementRate", sequence: 7, type: "string", value: "45.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.52" },
          { id: "eventCount", sequence: 9, type: "string", value: "1100" },
          { id: "keyEvents", sequence: 10, type: "string", value: "6" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 2 },
          { id: "operatingSystem", sequence: 1, type: "string", value: "Android" },
          { id: "totalUsers", sequence: 2, type: "string", value: "120" },
          { id: "activeUsers", sequence: 3, type: "string", value: "115" },
          { id: "newUsers", sequence: 4, type: "string", value: "110" },
          { id: "sessions", sequence: 5, type: "string", value: "170" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "60" },
          { id: "engagementRate", sequence: 7, type: "string", value: "35.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.55" },
          { id: "eventCount", sequence: 9, type: "string", value: "700" },
          { id: "keyEvents", sequence: 10, type: "string", value: "3" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 3 },
          { id: "operatingSystem", sequence: 1, type: "string", value: "iOS" },
          { id: "totalUsers", sequence: 2, type: "string", value: "110" },
          { id: "activeUsers", sequence: 3, type: "string", value: "108" },
          { id: "newUsers", sequence: 4, type: "string", value: "104" },
          { id: "sessions", sequence: 5, type: "string", value: "120" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "40" },
          { id: "engagementRate", sequence: 7, type: "string", value: "34.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.37" },
          { id: "eventCount", sequence: 9, type: "string", value: "450" },
          { id: "keyEvents", sequence: 10, type: "string", value: "2" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 4 },
          { id: "operatingSystem", sequence: 1, type: "string", value: "Linux" },
          { id: "totalUsers", sequence: 2, type: "string", value: "80" },
          { id: "activeUsers", sequence: 3, type: "string", value: "75" },
          { id: "newUsers", sequence: 4, type: "string", value: "70" },
          { id: "sessions", sequence: 5, type: "string", value: "100" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "30" },
          { id: "engagementRate", sequence: 7, type: "string", value: "36.00%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.40" },
          { id: "eventCount", sequence: 9, type: "string", value: "400" },
          { id: "keyEvents", sequence: 10, type: "string", value: "1" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 5 },
          { id: "operatingSystem", sequence: 1, type: "string", value: "Chrome OS" },
          { id: "totalUsers", sequence: 2, type: "string", value: "15" },
          { id: "activeUsers", sequence: 3, type: "string", value: "14" },
          { id: "newUsers", sequence: 4, type: "string", value: "12" },
          { id: "sessions", sequence: 5, type: "string", value: "20" },
          { id: "engagedSessions", sequence: 6, type: "string", value: "5" },
          { id: "engagementRate", sequence: 7, type: "string", value: "33.33%" },
          { id: "engagedSessionsPerActiveUser", sequence: 8, type: "string", value: "0.36" },
          { id: "eventCount", sequence: 9, type: "string", value: "25" },
          { id: "keyEvents", sequence: 10, type: "string", value: "0" },
          { id: "totalRevenue", sequence: 11, type: "string", value: "$0" }
        ]
      ]
    case "ga4-listGrid-acquisitionOrganicSearch":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "(not set)" },
          { id: "sessions", sequence: 2, type: "string", value: "48" },
          { id: "totalUsers", sequence: 3, type: "string", value: "42" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "16m, 2s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "59" },
          { id: "conversions", sequence: 6, type: "string", value: "3" },
          { id: "eventCount", sequence: 7, type: "string", value: "203" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "SEO Services" },
          { id: "sessions", sequence: 2, type: "string", value: "10" },
          { id: "totalUsers", sequence: 3, type: "string", value: "10" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "3m, 11s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "11" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "40" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "e marketing experts" },
          { id: "sessions", sequence: 2, type: "string", value: "6" },
          { id: "totalUsers", sequence: 3, type: "string", value: "5" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "4m, 47s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "10" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "31" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "SEO Company" },
          { id: "sessions", sequence: 2, type: "string", value: "5" },
          { id: "totalUsers", sequence: 3, type: "string", value: "5" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "5m, 15s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "8" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "23" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "SEO Agency" },
          { id: "sessions", sequence: 2, type: "string", value: "3" },
          { id: "totalUsers", sequence: 3, type: "string", value: "3" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "2m, 1s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "9" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "24" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "SEO Experts" },
          { id: "sessions", sequence: 2, type: "string", value: "3" },
          { id: "totalUsers", sequence: 3, type: "string", value: "3" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "1m, 30s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "5" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "17" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "SEO Company in Turkey" },
          { id: "sessions", sequence: 2, type: "string", value: "1" },
          { id: "totalUsers", sequence: 3, type: "string", value: "1" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "13s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "1" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "5" }
        ]
      ]
    case "ga4-listGrid-acquisitionPaidSearch":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "(not set)" },
          { id: "sessions", sequence: 2, type: "string", value: "48" },
          { id: "totalUsers", sequence: 3, type: "string", value: "42" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "16m, 2s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "59" },
          { id: "conversions", sequence: 6, type: "string", value: "3" },
          { id: "eventCount", sequence: 7, type: "string", value: "203" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "SEO Services" },
          { id: "sessions", sequence: 2, type: "string", value: "10" },
          { id: "totalUsers", sequence: 3, type: "string", value: "10" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "3m, 11s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "11" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "40" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "e marketing experts" },
          { id: "sessions", sequence: 2, type: "string", value: "6" },
          { id: "totalUsers", sequence: 3, type: "string", value: "5" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "4m, 47s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "10" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "31" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "SEO Company" },
          { id: "sessions", sequence: 2, type: "string", value: "5" },
          { id: "totalUsers", sequence: 3, type: "string", value: "5" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "5m, 15s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "8" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "23" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "SEO Agency" },
          { id: "sessions", sequence: 2, type: "string", value: "3" },
          { id: "totalUsers", sequence: 3, type: "string", value: "3" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "2m, 1s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "9" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "24" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "SEO Experts" },
          { id: "sessions", sequence: 2, type: "string", value: "3" },
          { id: "totalUsers", sequence: 3, type: "string", value: "3" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "1m, 30s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "5" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "17" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "SEO Company in Turkey" },
          { id: "sessions", sequence: 2, type: "string", value: "1" },
          { id: "totalUsers", sequence: 3, type: "string", value: "1" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "13s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "1" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "5" }
        ],
      ]
    case "ga4-listGrid-acquisitionDirect":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/" },
          { id: "sessions", sequence: 2, type: "string", value: "187" },
          { id: "totalUsers", sequence: 3, type: "string", value: "147" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "36m, 43s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "205" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "631" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/contact-us/" },
          { id: "sessions", sequence: 2, type: "string", value: "25" },
          { id: "totalUsers", sequence: 3, type: "string", value: "21" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "6m, 16s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "29" },
          { id: "conversions", sequence: 6, type: "string", value: "9" },
          { id: "eventCount", sequence: 7, type: "string", value: "76" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/competitor-analysis-checklist/" },
          { id: "sessions", sequence: 2, type: "string", value: "20" },
          { id: "totalUsers", sequence: 3, type: "string", value: "9" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "8m, 13s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "24" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "59" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/careers/" },
          { id: "sessions", sequence: 2, type: "string", value: "19" },
          { id: "totalUsers", sequence: 3, type: "string", value: "19" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "2m, 3s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "20" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "62" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/about-us/" },
          { id: "sessions", sequence: 2, type: "string", value: "18" },
          { id: "totalUsers", sequence: 3, type: "string", value: "16" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "2m, 2s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "17" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "50" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/digital-marketing-budget-for-business/" },
          { id: "sessions", sequence: 2, type: "string", value: "17" },
          { id: "totalUsers", sequence: 3, type: "string", value: "13" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "6m, 25s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "19" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "60" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/case-studies/" },
          { id: "sessions", sequence: 2, type: "string", value: "16" },
          { id: "totalUsers", sequence: 3, type: "string", value: "13" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "1m, 15s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "14" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "37" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/how-much-does-a-website-cost-in-global/" },
          { id: "sessions", sequence: 2, type: "string", value: "15" },
          { id: "totalUsers", sequence: 3, type: "string", value: "13" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "3m, 25s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "13" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "47" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/seo-audit/" },
          { id: "sessions", sequence: 2, type: "string", value: "14" },
          { id: "totalUsers", sequence: 3, type: "string", value: "14" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "7m, 13s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "15" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "52" }
        ]
      ]
    case "ga4-listGrid-acquisitionSocial":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "web.skype.com" },
          { id: "sessions", sequence: 2, type: "string", value: "6" },
          { id: "totalUsers", sequence: 3, type: "string", value: "1" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "7s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "5" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "18" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "glassdoor.com.au" },
          { id: "sessions", sequence: 2, type: "string", value: "5" },
          { id: "totalUsers", sequence: 3, type: "string", value: "1" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "10s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "6" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "16" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "facebook.com" },
          { id: "sessions", sequence: 2, type: "string", value: "4" },
          { id: "totalUsers", sequence: 3, type: "string", value: "4" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "4" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "16" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "l.facebook.com" },
          { id: "sessions", sequence: 2, type: "string", value: "3" },
          { id: "totalUsers", sequence: 3, type: "string", value: "3" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "1m, 10s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "3" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "12" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "trustpilot" },
          { id: "sessions", sequence: 2, type: "string", value: "3" },
          { id: "totalUsers", sequence: 3, type: "string", value: "3" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "1m" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "9" },
          { id: "conversions", sequence: 6, type: "string", value: "2" },
          { id: "eventCount", sequence: 7, type: "string", value: "24" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "linkedin.com" },
          { id: "sessions", sequence: 2, type: "string", value: "1" },
          { id: "totalUsers", sequence: 3, type: "string", value: "1" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "56s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "4" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "14" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "pinterest.de" },
          { id: "sessions", sequence: 2, type: "string", value: "1" },
          { id: "totalUsers", sequence: 3, type: "string", value: "1" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "1m, 41s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "2" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "7" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "yelp.com" },
          { id: "sessions", sequence: 2, type: "string", value: "1" },
          { id: "totalUsers", sequence: 3, type: "string", value: "1" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "10s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "1" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "4" }
        ]
      ]
    case "ga4-listGrid-acquisitionReferral":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "clutch.co" },
          { id: "sessions", sequence: 2, type: "string", value: "83" },
          { id: "totalUsers", sequence: 3, type: "string", value: "71" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "22m, 17s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "114" },
          { id: "conversions", sequence: 6, type: "string", value: "7" },
          { id: "eventCount", sequence: 7, type: "string", value: "404" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "designrush.com" },
          { id: "sessions", sequence: 2, type: "string", value: "10" },
          { id: "totalUsers", sequence: 3, type: "string", value: "9" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "1m, 44s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "11" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "36" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "au.indeed.com" },
          { id: "sessions", sequence: 2, type: "string", value: "9" },
          { id: "totalUsers", sequence: 3, type: "string", value: "5" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "7m, 57s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "9" },
          { id: "conversions", sequence: 6, type: "string", value: "1" },
          { id: "eventCount", sequence: 7, type: "string", value: "36" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "themanifest.com" },
          { id: "sessions", sequence: 2, type: "string", value: "7" },
          { id: "totalUsers", sequence: 3, type: "string", value: "7" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "2m, 5s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "10" },
          { id: "conversions", sequence: 6, type: "string", value: "1" },
          { id: "eventCount", sequence: 7, type: "string", value: "32" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "pinlebi.com" },
          { id: "sessions", sequence: 2, type: "string", value: "6" },
          { id: "totalUsers", sequence: 3, type: "string", value: "6" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "7" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "19" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "partnersdirectory.withgoogle.com" },
          { id: "sessions", sequence: 2, type: "string", value: "4" },
          { id: "totalUsers", sequence: 3, type: "string", value: "4" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "1m, 7s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "7" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "20" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "trello.com" },
          { id: "sessions", sequence: 2, type: "string", value: "4" },
          { id: "totalUsers", sequence: 3, type: "string", value: "1" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "1s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "2" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "10" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "marketbusinessnews.com" },
          { id: "sessions", sequence: 2, type: "string", value: "3" },
          { id: "totalUsers", sequence: 3, type: "string", value: "3" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "26s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "3" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "13" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "lanewaymagazine.com.au" },
          { id: "sessions", sequence: 2, type: "string", value: "2" },
          { id: "totalUsers", sequence: 3, type: "string", value: "2" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "4s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "2" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "7" }
        ]
      ]
    case "ga4-listGrid-acquisitionDisplay":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessionSource", sequence: 1, type: "string", value: "google" },
          { id: "sessions", sequence: 2, type: "string", value: "83" },
          { id: "totalUsers", sequence: 3, type: "string", value: "80" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "51m, 41s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "159" },
          { id: "conversions", sequence: 6, type: "string", value: "12" },
          { id: "eventCount", sequence: 7, type: "string", value: "479" }
        ]
      ]
    case "ga4-listGrid-audienceLocation":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "global" },
          { id: "sessions", sequence: 2, type: "string", value: "615" },
          { id: "totalUsers", sequence: 3, type: "string", value: "488" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "6h, 51m, 1s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "855" },
          { id: "conversions", sequence: 6, type: "string", value: "23" },
          { id: "eventCount", sequence: 7, type: "string", value: "2815" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "India" },
          { id: "sessions", sequence: 2, type: "string", value: "333" },
          { id: "totalUsers", sequence: 3, type: "string", value: "235" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "5h, 8m, 19s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "437" },
          { id: "conversions", sequence: 6, type: "string", value: "20" },
          { id: "eventCount", sequence: 7, type: "string", value: "1443" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "Pakistan" },
          { id: "sessions", sequence: 2, type: "string", value: "112" },
          { id: "totalUsers", sequence: 3, type: "string", value: "87" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "52m, 2s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "163" },
          { id: "conversions", sequence: 6, type: "string", value: "10" },
          { id: "eventCount", sequence: 7, type: "string", value: "542" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "Netherlands" },
          { id: "sessions", sequence: 2, type: "string", value: "94" },
          { id: "totalUsers", sequence: 3, type: "string", value: "94" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "1m, 7s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "94" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "284" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "United States" },
          { id: "sessions", sequence: 2, type: "string", value: "93" },
          { id: "totalUsers", sequence: 3, type: "string", value: "89" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "32m, 1s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "210" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "662" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "Philippines" },
          { id: "sessions", sequence: 2, type: "string", value: "32" },
          { id: "totalUsers", sequence: 3, type: "string", value: "24" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "8m, 59s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "37" },
          { id: "conversions", sequence: 6, type: "string", value: "1" },
          { id: "eventCount", sequence: 7, type: "string", value: "131" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "Bangladesh" },
          { id: "sessions", sequence: 2, type: "string", value: "22" },
          { id: "totalUsers", sequence: 3, type: "string", value: "14" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "12m, 3s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "28" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "104" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "Vietnam" },
          { id: "sessions", sequence: 2, type: "string", value: "16" },
          { id: "totalUsers", sequence: 3, type: "string", value: "11" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "8m, 28s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "18" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "69" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "Indonesia" },
          { id: "sessions", sequence: 2, type: "string", value: "15" },
          { id: "totalUsers", sequence: 3, type: "string", value: "9" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "8m, 36s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "14" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "54" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "China" },
          { id: "sessions", sequence: 2, type: "string", value: "14" },
          { id: "totalUsers", sequence: 3, type: "string", value: "14" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "22s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "13" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "41" }
        ]
      ]
    case "ga4-listGrid-audienceLanguage":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "language", sequence: 1, type: "string", value: "en-us" },
          { id: "sessions", sequence: 2, type: "string", value: "889" },
          { id: "totalUsers", sequence: 3, type: "string", value: "709" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "8h, 34m, 14s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "1274" },
          { id: "conversions", sequence: 6, type: "string", value: "34" },
          { id: "eventCount", sequence: 7, type: "string", value: "4139" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "language", sequence: 1, type: "string", value: "en-gb" },
          { id: "sessions", sequence: 2, type: "string", value: "263" },
          { id: "totalUsers", sequence: 3, type: "string", value: "208" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "3h, 33m, 1s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "330" },
          { id: "conversions", sequence: 6, type: "string", value: "7" },
          { id: "eventCount", sequence: 7, type: "string", value: "1137" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "language", sequence: 1, type: "string", value: "en-au" },
          { id: "sessions", sequence: 2, type: "string", value: "240" },
          { id: "totalUsers", sequence: 3, type: "string", value: "193" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "2h, 23m, 34s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "330" },
          { id: "conversions", sequence: 6, type: "string", value: "12" },
          { id: "eventCount", sequence: 7, type: "string", value: "1066" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "language", sequence: 1, type: "string", value: "en" },
          { id: "sessions", sequence: 2, type: "string", value: "25" },
          { id: "totalUsers", sequence: 3, type: "string", value: "17" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "16m, 2s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "29" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "102" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "language", sequence: 1, type: "string", value: "en-in" },
          { id: "sessions", sequence: 2, type: "string", value: "24" },
          { id: "totalUsers", sequence: 3, type: "string", value: "22" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "8m, 52s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "27" },
          { id: "conversions", sequence: 6, type: "string", value: "1" },
          { id: "eventCount", sequence: 7, type: "string", value: "92" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "language", sequence: 1, type: "string", value: "zh-cn" },
          { id: "sessions", sequence: 2, type: "string", value: "11" },
          { id: "totalUsers", sequence: 3, type: "string", value: "10" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "41s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "12" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "36" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "language", sequence: 1, type: "string", value: "vi-vn" },
          { id: "sessions", sequence: 2, type: "string", value: "9" },
          { id: "totalUsers", sequence: 3, type: "string", value: "6" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "3m, 34s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "11" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "42" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "language", sequence: 1, type: "string", value: "es-es" },
          { id: "sessions", sequence: 2, type: "string", value: "5" },
          { id: "totalUsers", sequence: 3, type: "string", value: "3" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "53s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "3" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "15" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "language", sequence: 1, type: "string", value: "tr-tr" },
          { id: "sessions", sequence: 2, type: "string", value: "5" },
          { id: "totalUsers", sequence: 3, type: "string", value: "3" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "3m, 8s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "3" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "15" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "language", sequence: 1, type: "string", value: "it-it" },
          { id: "sessions", sequence: 2, type: "string", value: "4" },
          { id: "totalUsers", sequence: 3, type: "string", value: "1" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "3m, 16s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "4" },
          { id: "conversions", sequence: 6, type: "string", value: "1" },
          { id: "eventCount", sequence: 7, type: "string", value: "14" }
        ]
      ]
    case "ga4-listGrid-audienceDevices":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "mobileDeviceInfo", sequence: 1, type: "string", value: "(not set)" },
          { id: "sessions", sequence: 2, type: "string", value: "1345" },
          { id: "totalUsers", sequence: 3, type: "string", value: "1066" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "13h, 5m, 36s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "1862" },
          { id: "conversions", sequence: 6, type: "string", value: "52" },
          { id: "eventCount", sequence: 7, type: "string", value: "6138" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "mobileDeviceInfo", sequence: 1, type: "string", value: "Redmi Note 10S" },
          { id: "sessions", sequence: 2, type: "string", value: "8" },
          { id: "totalUsers", sequence: 3, type: "string", value: "2" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "2m, 43s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "11" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "26" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "mobileDeviceInfo", sequence: 1, type: "string", value: "Galaxy A04e" },
          { id: "sessions", sequence: 2, type: "string", value: "6" },
          { id: "totalUsers", sequence: 3, type: "string", value: "5" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "6m, 5s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "7" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "25" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "mobileDeviceInfo", sequence: 1, type: "string", value: "iQOO 7" },
          { id: "sessions", sequence: 2, type: "string", value: "6" },
          { id: "totalUsers", sequence: 3, type: "string", value: "1" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "4m, 55s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "7" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "16" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "mobileDeviceInfo", sequence: 1, type: "string", value: "G6" },
          { id: "sessions", sequence: 2, type: "string", value: "5" },
          { id: "totalUsers", sequence: 3, type: "string", value: "5" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "5" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "15" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "mobileDeviceInfo", sequence: 1, type: "string", value: "Moto G04" },
          { id: "sessions", sequence: 2, type: "string", value: "5" },
          { id: "totalUsers", sequence: 3, type: "string", value: "1" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "1m, 7s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "9" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "21" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "mobileDeviceInfo", sequence: 1, type: "string", value: "Galaxy S23 Ultra" },
          { id: "sessions", sequence: 2, type: "string", value: "4" },
          { id: "totalUsers", sequence: 3, type: "string", value: "4" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "3m, 21s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "7" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "21" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "mobileDeviceInfo", sequence: 1, type: "string", value: "Galaxy S22 Ultra" },
          { id: "sessions", sequence: 2, type: "string", value: "3" },
          { id: "totalUsers", sequence: 3, type: "string", value: "3" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "1m, 4s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "3" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "12" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "mobileDeviceInfo", sequence: 1, type: "string", value: "Galaxy S24 Ultra" },
          { id: "sessions", sequence: 2, type: "string", value: "3" },
          { id: "totalUsers", sequence: 3, type: "string", value: "1" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "13s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "3" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "11" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "mobileDeviceInfo", sequence: 1, type: "string", value: "XP10" },
          { id: "sessions", sequence: 2, type: "string", value: "3" },
          { id: "totalUsers", sequence: 3, type: "string", value: "1" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "5s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "4" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "11" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "mobileDeviceInfo", sequence: 1, type: "string", value: "305" },
          { id: "sessions", sequence: 2, type: "string", value: "2" },
          { id: "totalUsers", sequence: 3, type: "string", value: "2" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "33s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "2" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "8" }
        ]
      ]
    case "ga4-listGrid-audienceBrowser":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "browser", sequence: 1, type: "string", value: "Chrome" },
          { id: "sessions", sequence: 2, type: "string", value: "1201" },
          { id: "totalUsers", sequence: 3, type: "string", value: "953" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "12h, 31m, 54s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "1520" },
          { id: "conversions", sequence: 6, type: "string", value: "50" },
          { id: "eventCount", sequence: 7, type: "string", value: "5107" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "browser", sequence: 1, type: "string", value: "Safari" },
          { id: "sessions", sequence: 2, type: "string", value: "154" },
          { id: "totalUsers", sequence: 3, type: "string", value: "125" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "1h, 24m, 24s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "209" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "659" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "browser", sequence: 1, type: "string", value: "Edge" },
          { id: "sessions", sequence: 2, type: "string", value: "68" },
          { id: "totalUsers", sequence: 3, type: "string", value: "56" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "31m, 15s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "84" },
          { id: "conversions", sequence: 6, type: "string", value: "2" },
          { id: "eventCount", sequence: 7, type: "string", value: "293" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "browser", sequence: 1, type: "string", value: "Firefox" },
          { id: "sessions", sequence: 2, type: "string", value: "44" },
          { id: "totalUsers", sequence: 3, type: "string", value: "41" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "39m, 37s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "206" },
          { id: "conversions", sequence: 6, type: "string", value: "3" },
          { id: "eventCount", sequence: 7, type: "string", value: "623" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "browser", sequence: 1, type: "string", value: "Opera" },
          { id: "sessions", sequence: 2, type: "string", value: "20" },
          { id: "totalUsers", sequence: 3, type: "string", value: "7" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "10m, 22s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "18" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "50" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "browser", sequence: 1, type: "string", value: "Samsung Internet" },
          { id: "sessions", sequence: 2, type: "string", value: "17" },
          { id: "totalUsers", sequence: 3, type: "string", value: "14" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "2m, 17s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "20" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "57" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "browser", sequence: 1, type: "string", value: "Android Webview" },
          { id: "sessions", sequence: 2, type: "string", value: "6" },
          { id: "totalUsers", sequence: 3, type: "string", value: "6" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "1m, 23s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "6" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "22" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "browser", sequence: 1, type: "string", value: "Safari (in-app)" },
          { id: "sessions", sequence: 2, type: "string", value: "1" },
          { id: "totalUsers", sequence: 3, type: "string", value: "1" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "5m, 53s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "1" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "3" }
        ]
      ]
    case "ga4-listGrid-audienceOperatingSystem":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "operatingSystem", sequence: 1, type: "string", value: "Windows" },
          { id: "sessions", sequence: 2, type: "string", value: "909" },
          { id: "totalUsers", sequence: 3, type: "string", value: "704" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "8h, 29m, 40s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "1160" },
          { id: "conversions", sequence: 6, type: "string", value: "44" },
          { id: "eventCount", sequence: 7, type: "string", value: "3881" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "operatingSystem", sequence: 1, type: "string", value: "Macintosh" },
          { id: "sessions", sequence: 2, type: "string", value: "216" },
          { id: "totalUsers", sequence: 3, type: "string", value: "185" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "2h, 11m, 35s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "276" },
          { id: "conversions", sequence: 6, type: "string", value: "7" },
          { id: "eventCount", sequence: 7, type: "string", value: "987" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "operatingSystem", sequence: 1, type: "string", value: "Android" },
          { id: "sessions", sequence: 2, type: "string", value: "190" },
          { id: "totalUsers", sequence: 3, type: "string", value: "153" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "3h, 4m, 10s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "238" },
          { id: "conversions", sequence: 6, type: "string", value: "2" },
          { id: "eventCount", sequence: 7, type: "string", value: "772" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "operatingSystem", sequence: 1, type: "string", value: "iOS" },
          { id: "sessions", sequence: 2, type: "string", value: "140" },
          { id: "totalUsers", sequence: 3, type: "string", value: "107" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "1h, 15m, 4s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "193" },
          { id: "conversions", sequence: 6, type: "string", value: "1" },
          { id: "eventCount", sequence: 7, type: "string", value: "591" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "operatingSystem", sequence: 1, type: "string", value: "Linux" },
          { id: "sessions", sequence: 2, type: "string", value: "50" },
          { id: "totalUsers", sequence: 3, type: "string", value: "48" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "16m, 34s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "189" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "553" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "operatingSystem", sequence: 1, type: "string", value: "Chrome OS" },
          { id: "sessions", sequence: 2, type: "string", value: "7" },
          { id: "totalUsers", sequence: 3, type: "string", value: "6" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "4m, 9s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "8" },
          { id: "conversions", sequence: 6, type: "string", value: "1" },
          { id: "eventCount", sequence: 7, type: "string", value: "30" }
        ]
      ]
    case "ga4-listGrid-conversions":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "(organic)" },
          { id: "conversions", sequence: 2, type: "string", value: "16" },
          { id: "newUsers", sequence: 3, type: "string", value: "500" },
          { id: "totalUsers", sequence: 4, type: "string", value: "532" },
          { id: "userEngagementDuration", sequence: 5, type: "string", value: "10h, 14m, 2s" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "(direct)" },
          { id: "conversions", sequence: 2, type: "string", value: "9" },
          { id: "newUsers", sequence: 3, type: "string", value: "356" },
          { id: "totalUsers", sequence: 4, type: "string", value: "370" },
          { id: "userEngagementDuration", sequence: 5, type: "string", value: "1h, 58m, 26s" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "au-seo-firms" },
          { id: "conversions", sequence: 2, type: "string", value: "4" },
          { id: "newUsers", sequence: 3, type: "string", value: "15" },
          { id: "totalUsers", sequence: 4, type: "string", value: "17" },
          { id: "userEngagementDuration", sequence: 5, type: "string", value: "5m," }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "directory" },
          { id: "conversions", sequence: 2, type: "string", value: "4" },
          { id: "newUsers", sequence: 3, type: "string", value: "60" },
          { id: "totalUsers", sequence: 4, type: "string", value: "68" },
          { id: "userEngagementDuration", sequence: 5, type: "string", value: "28m, 56s" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "(not set)" },
          { id: "conversions", sequence: 2, type: "string", value: "2" },
          { id: "newUsers", sequence: 3, type: "string", value: "1" },
          { id: "totalUsers", sequence: 4, type: "string", value: "19" },
          { id: "userEngagementDuration", sequence: 5, type: "string", value: "16m, 33s" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "(referral)" },
          { id: "conversions", sequence: 2, type: "string", value: "2" },
          { id: "newUsers", sequence: 3, type: "string", value: "45" },
          { id: "totalUsers", sequence: 4, type: "string", value: "53" },
          { id: "userEngagementDuration", sequence: 5, type: "string", value: "20m, 24s" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "domain_click" },
          { id: "conversions", sequence: 2, type: "string", value: "2" },
          { id: "newUsers", sequence: 3, type: "string", value: "3" },
          { id: "totalUsers", sequence: 4, type: "string", value: "3" },
          { id: "userEngagementDuration", sequence: 5, type: "string", value: "1m," }
        ]
      ]
    case "ga4-listGrid-pagesAll":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/" },
          { id: "sessions", sequence: 2, type: "string", value: "544" },
          { id: "totalUsers", sequence: 3, type: "string", value: "423" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "2h, 12m, 37s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "597" },
          { id: "conversions", sequence: 6, type: "string", value: "5" },
          { id: "eventCount", sequence: 7, type: "string", value: "1995" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/how-much-does-a-website-cost-in-global/" },
          { id: "sessions", sequence: 2, type: "string", value: "160" },
          { id: "totalUsers", sequence: 3, type: "string", value: "135" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "2h, 16m, 13s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "152" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "603" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/services/seo/" },
          { id: "sessions", sequence: 2, type: "string", value: "118" },
          { id: "totalUsers", sequence: 3, type: "string", value: "108" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "34m, 36s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "128" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "439" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/seo-audit/" },
          { id: "sessions", sequence: 2, type: "string", value: "115" },
          { id: "totalUsers", sequence: 3, type: "string", value: "110" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "59m, 29s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "127" },
          { id: "conversions", sequence: 6, type: "string", value: "12" },
          { id: "eventCount", sequence: 7, type: "string", value: "441" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/contact-us/" },
          { id: "sessions", sequence: 2, type: "string", value: "104" },
          { id: "totalUsers", sequence: 3, type: "string", value: "88" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "37m, 46s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "112" },
          { id: "conversions", sequence: 6, type: "string", value: "36" },
          { id: "eventCount", sequence: 7, type: "string", value: "300" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/digital-marketing-budget-for-business/" },
          { id: "sessions", sequence: 2, type: "string", value: "97" },
          { id: "totalUsers", sequence: 3, type: "string", value: "76" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "2h, 55m, 35s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "103" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "390" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/how-much-does-average-seo-cost-global/" },
          { id: "sessions", sequence: 2, type: "string", value: "67" },
          { id: "totalUsers", sequence: 3, type: "string", value: "48" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "58m, 2s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "61" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "224" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/about-us/" },
          { id: "sessions", sequence: 2, type: "string", value: "51" },
          { id: "totalUsers", sequence: 3, type: "string", value: "47" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "16m, 53s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "52" },
          { id: "conversions", sequence: 6, type: "string", value: "1" },
          { id: "eventCount", sequence: 7, type: "string", value: "141" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/careers/" },
          { id: "sessions", sequence: 2, type: "string", value: "49" },
          { id: "totalUsers", sequence: 3, type: "string", value: "40" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "17m, 10s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "55" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "167" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "string", value: "/blogs/" },
          { id: "sessions", sequence: 2, type: "string", value: "33" },
          { id: "totalUsers", sequence: 3, type: "string", value: "27" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "10m, 45s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "36" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "112" }
        ]
      ]
    case "ga4-listGrid-pagesPath":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pagePathAndScreenClass", sequence: 1, type: "string", value: "/" },
          { id: "sessions", sequence: 2, type: "string", value: "544" },
          { id: "totalUsers", sequence: 3, type: "string", value: "423" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "2h, 12m, 37s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "597" },
          { id: "conversions", sequence: 6, type: "string", value: "5" },
          { id: "eventCount", sequence: 7, type: "string", value: "1995" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pagePathAndScreenClass", sequence: 1, type: "string", value: "/how-much-does-a-website-cost-in-global/" },
          { id: "sessions", sequence: 2, type: "string", value: "160" },
          { id: "totalUsers", sequence: 3, type: "string", value: "135" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "2h, 16m, 13s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "152" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "603" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pagePathAndScreenClass", sequence: 1, type: "string", value: "/services/seo/" },
          { id: "sessions", sequence: 2, type: "string", value: "118" },
          { id: "totalUsers", sequence: 3, type: "string", value: "108" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "34m, 36s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "128" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "439" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pagePathAndScreenClass", sequence: 1, type: "string", value: "/seo-audit/" },
          { id: "sessions", sequence: 2, type: "string", value: "115" },
          { id: "totalUsers", sequence: 3, type: "string", value: "110" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "59m, 29s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "127" },
          { id: "conversions", sequence: 6, type: "string", value: "12" },
          { id: "eventCount", sequence: 7, type: "string", value: "441" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pagePathAndScreenClass", sequence: 1, type: "string", value: "/contact-us/" },
          { id: "sessions", sequence: 2, type: "string", value: "104" },
          { id: "totalUsers", sequence: 3, type: "string", value: "88" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "37m, 46s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "112" },
          { id: "conversions", sequence: 6, type: "string", value: "36" },
          { id: "eventCount", sequence: 7, type: "string", value: "300" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pagePathAndScreenClass", sequence: 1, type: "string", value: "/digital-marketing-budget-for-business/" },
          { id: "sessions", sequence: 2, type: "string", value: "97" },
          { id: "totalUsers", sequence: 3, type: "string", value: "76" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "2h, 55m, 35s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "103" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "390" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pagePathAndScreenClass", sequence: 1, type: "string", value: "/how-much-does-average-seo-cost-global/" },
          { id: "sessions", sequence: 2, type: "string", value: "67" },
          { id: "totalUsers", sequence: 3, type: "string", value: "48" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "58m, 2s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "61" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "224" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pagePathAndScreenClass", sequence: 1, type: "string", value: "/about-us/" },
          { id: "sessions", sequence: 2, type: "string", value: "51" },
          { id: "totalUsers", sequence: 3, type: "string", value: "47" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "16m, 53s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "52" },
          { id: "conversions", sequence: 6, type: "string", value: "1" },
          { id: "eventCount", sequence: 7, type: "string", value: "141" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pagePathAndScreenClass", sequence: 1, type: "string", value: "/careers/" },
          { id: "sessions", sequence: 2, type: "string", value: "49" },
          { id: "totalUsers", sequence: 3, type: "string", value: "40" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "17m, 10s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "55" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "167" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pagePathAndScreenClass", sequence: 1, type: "string", value: "/blogs/" },
          { id: "sessions", sequence: 2, type: "string", value: "33" },
          { id: "totalUsers", sequence: 3, type: "string", value: "27" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "10m, 45s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "36" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "112" }
        ]
      ]
    case "ga4-listGrid-pagesTitle":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pageTitleAndScreenClass", sequence: 1, type: "string", value: "How Much Does a Website Cost in global?" },
          { id: "sessions", sequence: 2, type: "string", value: "160" },
          { id: "totalUsers", sequence: 3, type: "string", value: "135" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "2h, 16m, 13s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "152" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "603" },
          { id: "newUsers", sequence: 8, type: "string", value: "64" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pageTitleAndScreenClass", sequence: 1, type: "string", value: "Award-Winning SEO Agency in Turkey | SEO Services" },
          { id: "sessions", sequence: 2, type: "string", value: "118" },
          { id: "totalUsers", sequence: 3, type: "string", value: "108" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "34m, 36s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "128" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "439" },
          { id: "newUsers", sequence: 8, type: "string", value: "404" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "pageTitleAndScreenClass", sequence: 1, type: "string", value: "Latest Blogs - SEO, PPC, Digital Marketing, and Lead Generation" },
          { id: "sessions", sequence: 2, type: "string", value: "33" },
          { id: "totalUsers", sequence: 3, type: "string", value: "27" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "10m, 45s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "36" },
          { id: "conversions", sequence: 6, type: "string", value: "0" },
          { id: "eventCount", sequence: 7, type: "string", value: "112" },
          { id: "newUsers", sequence: 8, type: "string", value: "95" }
        ]
      ]
    case "ga4-listGrid-pagesContentGroup":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "contentGroup", sequence: 1, type: "string", value: "(not set)" },
          { id: "sessions", sequence: 2, type: "string", value: "1473" },
          { id: "totalUsers", sequence: 3, type: "string", value: "1167" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "14h, 58m, 40s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "2014" },
          { id: "conversions", sequence: 6, type: "string", value: "52" },
          { id: "eventCount", sequence: 7, type: "string", value: "6649" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "contentGroup", sequence: 1, type: "string", value: "(not set)" },
          { id: "sessions", sequence: 2, type: "string", value: "38" },
          { id: "totalUsers", sequence: 3, type: "string", value: "37" },
          { id: "userEngagementDuration", sequence: 4, type: "string", value: "15h, 50m, 20s" },
          { id: "screenPageViews", sequence: 5, type: "string", value: "22m, 32s" },
          { id: "conversions", sequence: 6, type: "string", value: "50" },
          { id: "eventCount", sequence: 7, type: "string", value: "165" }
        ]
      ]
    case "ga4-listGrid-topWebsiteTrafficChannel":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessiondefaultchannelgroup", sequence: 1, type: "string", value: "Oganic Search" },
          { id: "newUsers", sequence: 2, type: "string", value: "1473" },
          { id: "engagedSessions", sequence: 3, type: "string", value: "1473" },
          { id: "engagementRate", sequence: 4, type: "string", value: "11%" },
          { id: "sessionsPerUser", sequence: 5, type: "string", value: "1167" },
          { id: "eventCount", sequence: 6, type: "string", value: "156" },
          { id: "eventCountPerUser", sequence: 7, type: "string", value: "2014" },
          { id: "conversions", sequence: 8, type: "string", value: "52" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessiondefaultchannelgroup", sequence: 1, type: "string", value: "Paid Search" },
          { id: "newUsers", sequence: 2, type: "string", value: "38" },
          { id: "engagedSessions", sequence: 3, type: "string", value: "38" },
          { id: "engagementRate", sequence: 4, type: "string", value: "37%" },
          { id: "sessionsPerUser", sequence: 5, type: "string", value: "34" },
          { id: "eventCount", sequence: 6, type: "string", value: "862" },
          { id: "eventCountPerUser", sequence: 7, type: "string", value: "56" },
          { id: "conversions", sequence: 8, type: "string", value: "50" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessiondefaultchannelgroup", sequence: 1, type: "string", value: "Referral" },
          { id: "newUsers", sequence: 2, type: "string", value: "43" },
          { id: "engagedSessions", sequence: 3, type: "string", value: "31" },
          { id: "engagementRate", sequence: 4, type: "string", value: "30%" },
          { id: "sessionsPerUser", sequence: 5, type: "string", value: "39" },
          { id: "eventCount", sequence: 6, type: "string", value: "862" },
          { id: "eventCountPerUser", sequence: 7, type: "string", value: "15" },
          { id: "conversions", sequence: 8, type: "string", value: "50" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "sessiondefaultchannelgroup", sequence: 1, type: "string", value: "Email" },
          { id: "newUsers", sequence: 2, type: "string", value: "53" },
          { id: "engagedSessions", sequence: 3, type: "string", value: "41" },
          { id: "engagementRate", sequence: 4, type: "string", value: "60%" },
          { id: "sessionsPerUser", sequence: 5, type: "string", value: "49" },
          { id: "eventCount", sequence: 6, type: "string", value: "82" },
          { id: "eventCountPerUser", sequence: 7, type: "string", value: "10" },
          { id: "conversions", sequence: 8, type: "string", value: "20" },
        ]
      ]
    case "ga4-listGrid-events":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "eventName", sequence: 1, type: "string", value: "session_start" },
          { id: "totalUsers", sequence: 2, type: "string", value: "1194" },
          { id: "userEngagementDuration", sequence: 3, type: "string", value: "0" },
          { id: "conversions", sequence: 4, type: "string", value: "0" },
          { id: "eventCount", sequence: 5, type: "string", value: "1502" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "eventName", sequence: 1, type: "string", value: "page_view" },
          { id: "totalUsers", sequence: 2, type: "string", value: "1193" },
          { id: "userEngagementDuration", sequence: 3, type: "string", value: "19s" },
          { id: "conversions", sequence: 4, type: "string", value: "0" },
          { id: "eventCount", sequence: 5, type: "string", value: "2064" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "eventName", sequence: 1, type: "string", value: "first_visit" },
          { id: "totalUsers", sequence: 2, type: "string", value: "1136" },
          { id: "userEngagementDuration", sequence: 3, type: "string", value: "5" },
          { id: "conversions", sequence: 4, type: "string", value: "0" },
          { id: "eventCount", sequence: 5, type: "string", value: "1136" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "eventName", sequence: 1, type: "string", value: "user_engagement" },
          { id: "totalUsers", sequence: 2, type: "string", value: "747" },
          { id: "userEngagementDuration", sequence: 3, type: "string", value: "9h, 4m, 34s" },
          { id: "conversions", sequence: 4, type: "string", value: "0" },
          { id: "eventCount", sequence: 5, type: "string", value: "1383" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "eventName", sequence: 1, type: "string", value: "scroll" },
          { id: "totalUsers", sequence: 2, type: "string", value: "433" },
          { id: "userEngagementDuration", sequence: 3, type: "string", value: "5h, 48m, 23s" },
          { id: "conversions", sequence: 4, type: "string", value: "0" },
          { id: "eventCount", sequence: 5, type: "string", value: "666" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "eventName", sequence: 1, type: "string", value: "Contact_Us" },
          { id: "totalUsers", sequence: 2, type: "string", value: "26" },
          { id: "userEngagementDuration", sequence: 3, type: "string", value: "19m, 17s" },
          { id: "conversions", sequence: 4, type: "string", value: "34" },
          { id: "eventCount", sequence: 5, type: "string", value: "34" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "eventName", sequence: 1, type: "string", value: "SEO_Report" },
          { id: "totalUsers", sequence: 2, type: "string", value: "7" },
          { id: "userEngagementDuration", sequence: 3, type: "string", value: "4m, 19s" },
          { id: "conversions", sequence: 4, type: "string", value: "7" },
          { id: "eventCount", sequence: 5, type: "string", value: "7" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "eventName", sequence: 1, type: "string", value: "Footer_Contact_Form" },
          { id: "totalUsers", sequence: 2, type: "string", value: "6" },
          { id: "userEngagementDuration", sequence: 3, type: "string", value: "2m, 45s" },
          { id: "conversions", sequence: 4, type: "string", value: "7" },
          { id: "eventCount", sequence: 5, type: "string", value: "7" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "eventName", sequence: 1, type: "string", value: "Phone" },
          { id: "totalUsers", sequence: 2, type: "string", value: "4" },
          { id: "userEngagementDuration", sequence: 3, type: "string", value: "31s" },
          { id: "conversions", sequence: 4, type: "string", value: "5" },
          { id: "eventCount", sequence: 5, type: "string", value: "5" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "eventName", sequence: 1, type: "string", value: "click" },
          { id: "totalUsers", sequence: 2, type: "string", value: "4" },
          { id: "userEngagementDuration", sequence: 3, type: "string", value: "31s" },
          { id: "conversions", sequence: 4, type: "string", value: "0" },
          { id: "eventCount", sequence: 5, type: "string", value: "8" }
        ]
      ]
    case "gads-listGrid-campaigns":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Auto Body" },
          { id: "budget", sequence: 2, type: "string", value: "95" },
          { id: "campaignType", sequence: 3, type: "string", value: "$137.00" },
          { id: "clicks", sequence: 4, type: "string", value: "19" },
          { id: "impressions", sequence: 5, type: "string", value: "20.01%" },
          { id: "avgCPC", sequence: 6, type: "string", value: "72.00" },
          { id: "ctr", sequence: 7, type: "string", value: "15.00%" },
          { id: "cost", sequence: 8, type: "string", value: "$11.00" },
          { id: "avgCost", sequence: 9, type: "string", value: "$25.00" },
          { id: "conversion", sequence: 10, type: "string", value: "4" },
          { id: "costConversion", sequence: 11, type: "string", value: "N/A" },
          { id: "conversionRate", sequence: 12, type: "string", value: "N/A" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 1 },
          { id: "campaign", sequence: 1, type: "string", value: "Dental Deno" },
          { id: "budget", sequence: 2, type: "string", value: "93" },
          { id: "campaignType", sequence: 3, type: "string", value: "$17.00" },
          { id: "clicks", sequence: 4, type: "string", value: "10" },
          { id: "impressions", sequence: 5, type: "string", value: "18.75%" },
          { id: "avgCPC", sequence: 6, type: "string", value: "74.00" },
          { id: "ctr", sequence: 7, type: "string", value: "70.00%" },
          { id: "cost", sequence: 8, type: "string", value: "$15.00" },
          { id: "avgCost", sequence: 9, type: "string", value: "$20.00" },
          { id: "conversion", sequence: 10, type: "string", value: "3" },
          { id: "costConversion", sequence: 11, type: "string", value: "N/A" },
          { id: "conversionRate", sequence: 12, type: "string", value: "N/A" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 2 },
          { id: "campaign", sequence: 1, type: "string", value: "Acme chakar" },
          { id: "budget", sequence: 2, type: "string", value: "104" },
          { id: "campaignType", sequence: 3, type: "string", value: "$12.00" },
          { id: "clicks", sequence: 4, type: "string", value: "20" },
          { id: "impressions", sequence: 5, type: "string", value: "17.93%" },
          { id: "avgCPC", sequence: 6, type: "string", value: "7.00" },
          { id: "ctr", sequence: 7, type: "string", value: "17.00%" },
          { id: "cost", sequence: 8, type: "string", value: "$25.00" },
          { id: "avgCost", sequence: 9, type: "string", value: "$00.00" },
          { id: "conversion", sequence: 10, type: "string", value: "3" },
          { id: "costConversion", sequence: 11, type: "string", value: "N/A" },
          { id: "conversionRate", sequence: 12, type: "string", value: "N/A" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 3 },
          { id: "campaign", sequence: 1, type: "string", value: "default" },
          { id: "budget", sequence: 2, type: "string", value: "84" },
          { id: "campaignType", sequence: 3, type: "string", value: "$100.00" },
          { id: "clicks", sequence: 4, type: "string", value: "49" },
          { id: "impressions", sequence: 5, type: "string", value: "18.20%" },
          { id: "avgCPC", sequence: 6, type: "string", value: "67.00" },
          { id: "ctr", sequence: 7, type: "string", value: "7.00%" },
          { id: "cost", sequence: 8, type: "string", value: "$22.00" },
          { id: "avgCost", sequence: 9, type: "string", value: "$24.00" },
          { id: "conversion", sequence: 10, type: "string", value: "3" },
          { id: "costConversion", sequence: 11, type: "string", value: "N/A" },
          { id: "conversionRate", sequence: 12, type: "string", value: "N/A" }
        ]
      ]
    case "gads-listGrid-adGroups":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "adGroup", sequence: 1, type: "string", value: "SEO Company in Turkey" },
          { id: "campaign", sequence: 2, type: "string", value: "Exact" },
          { id: "clicks", sequence: 3, type: "string", value: "16" },
          { id: "impressions", sequence: 4, type: "string", value: "1" },
          { id: "avgCPC", sequence: 5, type: "string", value: "$135.65" },
          { id: "cost", sequence: 6, type: "string", value: "$135.65" },
          { id: "avgCost", sequence: 7, type: "string", value: "$135.65" },
          { id: "ctr", sequence: 8, type: "string", value: "1.6%" },
          { id: "conversion", sequence: 9, type: "string", value: "0" },
          { id: "costConversion", sequence: 10, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 11, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "adGroup", sequence: 1, type: "string", value: "Website Design" },
          { id: "campaign", sequence: 2, type: "string", value: "Dental Deno" },
          { id: "clicks", sequence: 3, type: "string", value: "0" },
          { id: "impressions", sequence: 4, type: "string", value: "0" },
          { id: "avgCPC", sequence: 5, type: "string", value: "$0" },
          { id: "cost", sequence: 6, type: "string", value: "$0" },
          { id: "avgCost", sequence: 7, type: "string", value: "0" },
          { id: "ctr", sequence: 8, type: "string", value: "0.0%" },
          { id: "conversion", sequence: 9, type: "string", value: "0" },
          { id: "costConversion", sequence: 10, type: "string", value: "$0" },
          { id: "conversionRate", sequence: 11, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "adGroup", sequence: 1, type: "string", value: "eCommerce SEO" },
          { id: "campaign", sequence: 2, type: "string", value: "" },
          { id: "clicks", sequence: 3, type: "string", value: "0" },
          { id: "impressions", sequence: 4, type: "string", value: "0" },
          { id: "avgCPC", sequence: 5, type: "string", value: "$0" },
          { id: "cost", sequence: 6, type: "string", value: "$0" },
          { id: "avgCost", sequence: 7, type: "string", value: "0" },
          { id: "ctr", sequence: 8, type: "string", value: "0.0%" },
          { id: "conversion", sequence: 9, type: "string", value: "0" },
          { id: "costConversion", sequence: 10, type: "string", value: "$0" },
          { id: "conversionRate", sequence: 11, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "adGroup", sequence: 1, type: "string", value: "Digital Marketing Agency" },
          { id: "campaign", sequence: 2, type: "string", value: "Auto Body" },
          { id: "clicks", sequence: 3, type: "string", value: "0" },
          { id: "impressions", sequence: 4, type: "string", value: "0" },
          { id: "avgCPC", sequence: 5, type: "string", value: "$0" },
          { id: "cost", sequence: 6, type: "string", value: "$0" },
          { id: "avgCost", sequence: 7, type: "string", value: "0" },
          { id: "ctr", sequence: 8, type: "string", value: "0.0%" },
          { id: "conversion", sequence: 9, type: "string", value: "0" },
          { id: "costConversion", sequence: 10, type: "string", value: "$0" },
          { id: "conversionRate", sequence: 11, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "adGroup", sequence: 1, type: "string", value: "SEO Services" },
          { id: "campaign", sequence: 2, type: "string", value: "" },
          { id: "clicks", sequence: 3, type: "string", value: "1623" },
          { id: "impressions", sequence: 4, type: "string", value: "26" },
          { id: "avgCPC", sequence: 5, type: "string", value: "$40.83" },
          { id: "cost", sequence: 6, type: "string", value: "$1061.51" },
          { id: "avgCost", sequence: 7, type: "string", value: "$40.83" },
          { id: "ctr", sequence: 8, type: "string", value: "6.2%" },
          { id: "conversion", sequence: 9, type: "string", value: "1" },
          { id: "costConversion", sequence: 10, type: "string", value: "$1061.51" },
          { id: "conversionRate", sequence: 11, type: "string", value: "3.85%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "adGroup", sequence: 1, type: "string", value: "Band Name Ad" },
          { id: "campaign", sequence: 2, type: "string", value: "Acme chakar" },
          { id: "clicks", sequence: 3, type: "string", value: "234" },
          { id: "impressions", sequence: 4, type: "string", value: "25" },
          { id: "avgCPC", sequence: 5, type: "string", value: "$6.7" },
          { id: "cost", sequence: 6, type: "string", value: "$167.49" },
          { id: "avgCost", sequence: 7, type: "string", value: "$6.7" },
          { id: "ctr", sequence: 8, type: "string", value: "0.9%" },
          { id: "conversion", sequence: 9, type: "string", value: "0" },
          { id: "costConversion", sequence: 10, type: "string", value: "$0" },
          { id: "conversionRate", sequence: 11, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "adGroup", sequence: 1, type: "string", value: "Brand Awareness" },
          { id: "campaign", sequence: 2, type: "string", value: "" },
          { id: "clicks", sequence: 3, type: "string", value: "38822" },
          { id: "impressions", sequence: 4, type: "string", value: "55" },
          { id: "avgCPC", sequence: 5, type: "string", value: "$11.2" },
          { id: "cost", sequence: 6, type: "string", value: "$615.96" },
          { id: "avgCost", sequence: 7, type: "string", value: "$11.2" },
          { id: "ctr", sequence: 8, type: "string", value: "70.5%" },
          { id: "conversion", sequence: 9, type: "string", value: "0" },
          { id: "costConversion", sequence: 10, type: "string", value: "$0" },
          { id: "conversionRate", sequence: 11, type: "string", value: "0.00%" }
        ]
      ];

    case "gads-listGrid-keywords":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "SEO Services" },
          { id: "adGroupAndcampaign", sequence: 2, type: "string", value: "Auto Body > SEO Services" },
          { id: "matchType", sequence: 3, type: "string", value: "Phrase" },
          { id: "clicks", sequence: 4, type: "string", value: "860" },
          { id: "impressions", sequence: 5, type: "string", value: "15" },
          { id: "avgCPC", sequence: 6, type: "string", value: "$34.80" },
          { id: "cost", sequence: 7, type: "string", value: "$521.98" },
          { id: "avgCost", sequence: 8, type: "string", value: "$34.80" },
          { id: "ctr", sequence: 9, type: "string", value: "5.3%" },
          { id: "conversion", sequence: 10, type: "string", value: "0" },
          { id: "costConversion", sequence: 11, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 12, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "SEO Company" },
          { id: "adGroupAndcampaign", sequence: 2, type: "string", value: "default > SEO Services" },
          { id: "matchType", sequence: 3, type: "string", value: "Phrase" },
          { id: "clicks", sequence: 4, type: "string", value: "80" },
          { id: "impressions", sequence: 5, type: "string", value: "3" },
          { id: "avgCPC", sequence: 6, type: "string", value: "$40.74" },
          { id: "cost", sequence: 7, type: "string", value: "$122.21" },
          { id: "avgCost", sequence: 8, type: "string", value: "$40.74" },
          { id: "ctr", sequence: 9, type: "string", value: "2.7%" },
          { id: "conversion", sequence: 10, type: "string", value: "0" },
          { id: "costConversion", sequence: 11, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 12, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "SEO Agency" },
          { id: "adGroupAndcampaign", sequence: 2, type: "string", value: " > SEO Services" },
          { id: "matchType", sequence: 3, type: "string", value: "Phrase" },
          { id: "clicks", sequence: 4, type: "string", value: "151" },
          { id: "impressions", sequence: 5, type: "string", value: "3" },
          { id: "avgCPC", sequence: 6, type: "string", value: "$25.56" },
          { id: "cost", sequence: 7, type: "string", value: "$76.67" },
          { id: "avgCost", sequence: 8, type: "string", value: "$25.56" },
          { id: "ctr", sequence: 9, type: "string", value: "5.0%" },
          { id: "conversion", sequence: 10, type: "string", value: "0" },
          { id: "costConversion", sequence: 11, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 12, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "seo services in global" },
          { id: "adGroupAndcampaign", sequence: 2, type: "string", value: "Auto Body > SEO Services" },
          { id: "matchType", sequence: 3, type: "string", value: "Phrase" },
          { id: "clicks", sequence: 4, type: "string", value: "86" },
          { id: "impressions", sequence: 5, type: "string", value: "2" },
          { id: "avgCPC", sequence: 6, type: "string", value: "$43.38" },
          { id: "cost", sequence: 7, type: "string", value: "$86.77" },
          { id: "avgCost", sequence: 8, type: "string", value: "$43.38" },
          { id: "ctr", sequence: 9, type: "string", value: "4.3%" },
          { id: "conversion", sequence: 10, type: "string", value: "1" },
          { id: "costConversion", sequence: 11, type: "string", value: "$86.77" },
          { id: "conversionRate", sequence: 12, type: "string", value: "50.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "web design Turkey" },
          { id: "adGroupAndcampaign", sequence: 2, type: "string", value: "Dental Deno > Website Design" },
          { id: "matchType", sequence: 3, type: "string", value: "Exact" },
          { id: "clicks", sequence: 4, type: "string", value: "0" },
          { id: "impressions", sequence: 5, type: "string", value: "0" },
          { id: "avgCPC", sequence: 6, type: "string", value: "$0.00" },
          { id: "cost", sequence: 7, type: "string", value: "$0.00" },
          { id: "avgCost", sequence: 8, type: "string", value: "$0.00" },
          { id: "ctr", sequence: 9, type: "string", value: "0.0%" },
          { id: "conversion", sequence: 10, type: "string", value: "0" },
          { id: "costConversion", sequence: 11, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 12, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "website design Turkey" },
          { id: "adGroupAndcampaign", sequence: 2, type: "string", value: "Website Design" },
          { id: "matchType", sequence: 3, type: "string", value: "Exact" },
          { id: "clicks", sequence: 4, type: "string", value: "0" },
          { id: "impressions", sequence: 5, type: "string", value: "0" },
          { id: "avgCPC", sequence: 6, type: "string", value: "$0.00" },
          { id: "cost", sequence: 7, type: "string", value: "$0.00" },
          { id: "avgCost", sequence: 8, type: "string", value: "$0.00" },
          { id: "ctr", sequence: 9, type: "string", value: "0.0%" },
          { id: "conversion", sequence: 10, type: "string", value: "0" },
          { id: "costConversion", sequence: 11, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 12, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "SEO Company in Turkey" },
          { id: "adGroupAndcampaign", sequence: 2, type: "string", value: "Acme chakar > SEO Services" },
          { id: "matchType", sequence: 3, type: "string", value: "Exact" },
          { id: "clicks", sequence: 4, type: "string", value: "16" },
          { id: "impressions", sequence: 5, type: "string", value: "1" },
          { id: "avgCPC", sequence: 6, type: "string", value: "$135.65" },
          { id: "cost", sequence: 7, type: "string", value: "$135.65" },
          { id: "avgCost", sequence: 8, type: "string", value: "$135.65" },
          { id: "ctr", sequence: 9, type: "string", value: "1.6%" },
          { id: "conversion", sequence: 10, type: "string", value: "0" },
          { id: "costConversion", sequence: 11, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 12, type: "string", value: "0.00%" }
        ]
      ]
    case "gads-listGrid-keywordsSearchTerms":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "searchTerm", sequence: 1, type: "string", value: "marketing agency" },
          { id: "adGroupAndcampaign", sequence: 2, type: "string", value: "Auto Body | Dynamic > SEO" },
          { id: "matchType", sequence: 3, type: "string", value: "Exact" },
          { id: "clicks", sequence: 4, type: "string", value: "41" },
          { id: "impressions", sequence: 5, type: "string", value: "4" },
          { id: "avgCPC", sequence: 6, type: "string", value: "$12.07" },
          { id: "cost", sequence: 7, type: "string", value: "$48.29" },
          { id: "avgCost", sequence: 8, type: "string", value: "$12.07" },
          { id: "ctr", sequence: 9, type: "string", value: "1.0%" },
          { id: "conversion", sequence: 10, type: "string", value: "0" },
          { id: "costConversion", sequence: 11, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 12, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "searchTerm", sequence: 1, type: "string", value: "seo agency" },
          { id: "adGroupAndcampaign", sequence: 2, type: "string", value: "SEO Services" },
          { id: "matchType", sequence: 3, type: "string", value: "Exact" },
          { id: "clicks", sequence: 4, type: "string", value: "102" },
          { id: "impressions", sequence: 5, type: "string", value: "2" },
          { id: "avgCPC", sequence: 6, type: "string", value: "$19.77" },
          { id: "cost", sequence: 7, type: "string", value: "$39.53" },
          { id: "avgCost", sequence: 8, type: "string", value: "$19.77" },
          { id: "ctr", sequence: 9, type: "string", value: "5.0%" },
          { id: "conversion", sequence: 10, type: "string", value: "0" },
          { id: "costConversion", sequence: 11, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 12, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "searchTerm", sequence: 1, type: "string", value: "seo company" },
          { id: "adGroupAndcampaign", sequence: 2, type: "string", value: "Dental Deno > SEO Services" },
          { id: "matchType", sequence: 3, type: "string", value: "Exact" },
          { id: "clicks", sequence: 4, type: "string", value: "30" },
          { id: "impressions", sequence: 5, type: "string", value: "2" },
          { id: "avgCPC", sequence: 6, type: "string", value: "$42.91" },
          { id: "cost", sequence: 7, type: "string", value: "$85.82" },
          { id: "avgCost", sequence: 8, type: "string", value: "$42.91" },
          { id: "ctr", sequence: 9, type: "string", value: "1.5%" },
          { id: "conversion", sequence: 10, type: "string", value: "0" },
          { id: "costConversion", sequence: 11, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 12, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "searchTerm", sequence: 1, type: "string", value: "seo services" },
          { id: "adGroupAndcampaign", sequence: 2, type: "string", value: "SEO Services" },
          { id: "matchType", sequence: 3, type: "string", value: "Exact" },
          { id: "clicks", sequence: 4, type: "string", value: "39" },
          { id: "impressions", sequence: 5, type: "string", value: "2" },
          { id: "avgCPC", sequence: 6, type: "string", value: "$33.95" },
          { id: "cost", sequence: 7, type: "string", value: "$67.91" },
          { id: "avgCost", sequence: 8, type: "string", value: "$33.95" },
          { id: "ctr", sequence: 9, type: "string", value: "3.0%" },
          { id: "conversion", sequence: 10, type: "string", value: "0" },
          { id: "costConversion", sequence: 11, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 12, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "searchTerm", sequence: 1, type: "string", value: "website and seo services" },
          { id: "adGroupAndcampaign", sequence: 2, type: "string", value: "Acme chakar > SEO Services" },
          { id: "matchType", sequence: 3, type: "string", value: "Near Exact" },
          { id: "clicks", sequence: 4, type: "string", value: "4" },
          { id: "impressions", sequence: 5, type: "string", value: "2" },
          { id: "avgCPC", sequence: 6, type: "string", value: "$36.46" },
          { id: "cost", sequence: 7, type: "string", value: "$72.92" },
          { id: "avgCost", sequence: 8, type: "string", value: "$36.46" },
          { id: "ctr", sequence: 9, type: "string", value: "2.0%" },
          { id: "conversion", sequence: 10, type: "string", value: "0" },
          { id: "costConversion", sequence: 11, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 12, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "searchTerm", sequence: 1, type: "string", value: "website seo" },
          { id: "adGroupAndcampaign", sequence: 2, type: "string", value: "SEO Services" },
          { id: "matchType", sequence: 3, type: "string", value: "Near Phrase" },
          { id: "clicks", sequence: 4, type: "string", value: "5" },
          { id: "impressions", sequence: 5, type: "string", value: "2" },
          { id: "avgCPC", sequence: 6, type: "string", value: "$59.49" },
          { id: "cost", sequence: 7, type: "string", value: "$118.98" },
          { id: "avgCost", sequence: 8, type: "string", value: "$59.49" },
          { id: "ctr", sequence: 9, type: "string", value: "2.5%" },
          { id: "conversion", sequence: 10, type: "string", value: "0" },
          { id: "costConversion", sequence: 11, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 12, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "searchTerm", sequence: 1, type: "string", value: "digital marketing" },
          { id: "adGroupAndcampaign", sequence: 2, type: "string", value: "Auto Body | Dynamic > SEO" },
          { id: "matchType", sequence: 3, type: "string", value: "Exact" },
          { id: "clicks", sequence: 4, type: "string", value: "37" },
          { id: "impressions", sequence: 5, type: "string", value: "2" },
          { id: "avgCPC", sequence: 6, type: "string", value: "$8.94" },
          { id: "cost", sequence: 7, type: "string", value: "$17.88" },
          { id: "avgCost", sequence: 8, type: "string", value: "$8.94" },
          { id: "ctr", sequence: 9, type: "string", value: "4.0%" },
          { id: "conversion", sequence: 10, type: "string", value: "0" },
          { id: "costConversion", sequence: 11, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 12, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "searchTerm", sequence: 1, type: "string", value: "marketing agency Turkey" },
          { id: "adGroupAndcampaign", sequence: 2, type: "string", value: "Dynamic > SEO" },
          { id: "matchType", sequence: 3, type: "string", value: "Exact" },
          { id: "clicks", sequence: 4, type: "string", value: "22" },
          { id: "impressions", sequence: 5, type: "string", value: "2" },
          { id: "avgCPC", sequence: 6, type: "string", value: "$60.89" },
          { id: "cost", sequence: 7, type: "string", value: "$121.78" },
          { id: "avgCost", sequence: 8, type: "string", value: "$60.89" },
          { id: "ctr", sequence: 9, type: "string", value: "1.5%" },
          { id: "conversion", sequence: 10, type: "string", value: "0" },
          { id: "costConversion", sequence: 11, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 12, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "searchTerm", sequence: 1, type: "string", value: "marketing consultant Turkey" },
          { id: "adGroupAndcampaign", sequence: 2, type: "string", value: "Acme chakar Dynamic > SEO" },
          { id: "matchType", sequence: 3, type: "string", value: "Exact" },
          { id: "clicks", sequence: 4, type: "string", value: "5" },
          { id: "impressions", sequence: 5, type: "string", value: "2" },
          { id: "avgCPC", sequence: 6, type: "string", value: "$11.97" },
          { id: "cost", sequence: 7, type: "string", value: "$23.94" },
          { id: "avgCost", sequence: 8, type: "string", value: "$11.97" },
          { id: "ctr", sequence: 9, type: "string", value: "2.5%" },
          { id: "conversion", sequence: 10, type: "string", value: "0" },
          { id: "costConversion", sequence: 11, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 12, type: "string", value: "0.00%" }
        ]
      ];

    case "gads-listGrid-keywordsAds":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "ad", sequence: 1, type: "string", value: "https://www.picasaweb.google.com > Auto Body > Brand Awareness" },
          { id: "adLink", sequence: 2, type: "url", value: "https://www.yazi.com" },
          { id: "adDescription", sequence: 3, type: "string", value: "Auto Body Brand Awareness" },
          { id: "adGroupAndcampaign", sequence: 4, type: "string", value: "Brand Awareness" },
          { id: "clicks", sequence: 5, type: "string", value: "12686" },
          { id: "impressions", sequence: 6, type: "string", value: "32" },
          { id: "avgCPC", sequence: 7, type: "string", value: "$10.78" },
          { id: "cost", sequence: 8, type: "string", value: "$345.00" },
          { id: "avgCost", sequence: 9, type: "string", value: "$10.78" },
          { id: "ctr", sequence: 10, type: "string", value: "39.69%" },
          { id: "conversion", sequence: 11, type: "string", value: "1" },
          { id: "costConversion", sequence: 12, type: "string", value: "$345.00" },
          { id: "conversionRate", sequence: 13, type: "string", value: "3.13%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "ad", sequence: 1, type: "string", value: "https://www.aboutads.info > Dynamic > SEO" },
          { id: "adLink", sequence: 2, type: "url", value: "https://www.xyz.com" },
          { id: "adDescription", sequence: 3, type: "string", value: "Dynamic SEO" },
          { id: "adGroupAndcampaign", sequence: 4, type: "string", value: "SEO" },
          { id: "clicks", sequence: 5, type: "string", value: "1243" },
          { id: "impressions", sequence: 6, type: "string", value: "30" },
          { id: "avgCPC", sequence: 7, type: "string", value: "$19.10" },
          { id: "cost", sequence: 8, type: "string", value: "$572.89" },
          { id: "avgCost", sequence: 9, type: "string", value: "$19.10" },
          { id: "ctr", sequence: 10, type: "string", value: "4.13%" },
          { id: "conversion", sequence: 11, type: "string", value: "2" },
          { id: "costConversion", sequence: 12, type: "string", value: "$286.44" },
          { id: "conversionRate", sequence: 13, type: "string", value: "6.67%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "ad", sequence: 1, type: "string", value: "https://www.youronlinechoices.com/ > Dental Deno > Band Name Ad" },
          { id: "adLink", sequence: 2, type: "url", value: "https://www.acz.com" },
          { id: "adDescription", sequence: 3, type: "string", value: "Dental Deno Band Name Ad" },
          { id: "adGroupAndcampaign", sequence: 4, type: "string", value: "Band Name Ad" },
          { id: "clicks", sequence: 5, type: "string", value: "216" },
          { id: "impressions", sequence: 6, type: "string", value: "23" },
          { id: "avgCPC", sequence: 7, type: "string", value: "$7.00" },
          { id: "cost", sequence: 8, type: "string", value: "$160.97" },
          { id: "avgCost", sequence: 9, type: "string", value: "$7.00" },
          { id: "ctr", sequence: 10, type: "string", value: "0.94%" },
          { id: "conversion", sequence: 11, type: "string", value: "0" },
          { id: "costConversion", sequence: 12, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 13, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "ad", sequence: 1, type: "string", value: "https://www.rakuten.co.jp > SEO Services" },
          { id: "adLink", sequence: 2, type: "url", value: "https://www.rely.com" },
          { id: "adDescription", sequence: 3, type: "string", value: "SEO Services" },
          { id: "adGroupAndcampaign", sequence: 4, type: "string", value: "SEO Services" },
          { id: "clicks", sequence: 5, type: "string", value: "1453" },
          { id: "impressions", sequence: 6, type: "string", value: "20" },
          { id: "avgCPC", sequence: 7, type: "string", value: "$46.90" },
          { id: "cost", sequence: 8, type: "string", value: "$937.99" },
          { id: "avgCost", sequence: 9, type: "string", value: "$46.90" },
          { id: "ctr", sequence: 10, type: "string", value: "7.27%" },
          { id: "conversion", sequence: 11, type: "string", value: "0" },
          { id: "costConversion", sequence: 12, type: "string", value: "$0.00" },
          { id: "conversionRate", sequence: 13, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "ad", sequence: 1, type: "string", value: "https://www.buydomains.com > Acme chakar > Brand Awareness" },
          { id: "adLink", sequence: 2, type: "url", value: "https://www.selter.com" },
          { id: "adDescription", sequence: 3, type: "string", value: "Acme chakar Brand Awareness" },
          { id: "adGroupAndcampaign", sequence: 4, type: "string", value: "Brand Awareness" },
          { id: "clicks", sequence: 5, type: "string", value: "14669" },
          { id: "impressions", sequence: 6, type: "string", value: "19" },
          { id: "avgCPC", sequence: 7, type: "string", value: "$13.76" },
          { id: "cost", sequence: 8, type: "string", value: "$261.42" },
          { id: "avgCost", sequence: 9, type: "string", value: "$13.76" },
          { id: "ctr", sequence: 10, type: "string", value: "77.31%" },
          { id: "conversion", sequence: 11, type: "string", value: "2" },
          { id: "costConversion", sequence: 12, type: "string", value: "$130.71" },
          { id: "conversionRate", sequence: 13, type: "string", value: "10.53%" }
        ]
      ];

    case "gads-listGrid-conversions":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "conversionName", sequence: 1, type: "string", value: "SEO_Report" },
          { id: "conversion", sequence: 2, type: "string", value: "5" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "conversionName", sequence: 1, type: "string", value: "Contact Form" },
          { id: "conversion", sequence: 2, type: "string", value: "1" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "conversionName", sequence: 1, type: "string", value: "Footer Contact Form" },
          { id: "conversion", sequence: 2, type: "string", value: "1" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "conversionName", sequence: 1, type: "string", value: "Lets Work Together Footer (All Web Site Data)" },
          { id: "conversion", sequence: 2, type: "string", value: "0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "conversionName", sequence: 1, type: "string", value: "Free eBook Download (All Web Site Data)" },
          { id: "conversion", sequence: 2, type: "string", value: "0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "conversionName", sequence: 1, type: "string", value: "Phone (All Web Site Data)" },
          { id: "conversion", sequence: 2, type: "string", value: "0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "conversionName", sequence: 1, type: "string", value: "Contact Us Page (All Web Site Data)" },
          { id: "conversion", sequence: 2, type: "string", value: "0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "conversionName", sequence: 1, type: "string", value: "Lets Work Together Sidebar (All Web Site Data)" },
          { id: "conversion", sequence: 2, type: "string", value: "0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "conversionName", sequence: 1, type: "string", value: "Get SEO Report (All Web Site Data)" },
          { id: "conversion", sequence: 2, type: "string", value: "0" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "conversionName", sequence: 1, type: "string", value: "Local actions - Website visits" },
          { id: "conversion", sequence: 2, type: "string", value: "0" }
        ]
      ]
    case "gads-listGrid-demographicsAge":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "age", sequence: 1, type: "string", value: "18-24" },
          { id: "clicks", sequence: 2, type: "string", value: "13" },
          { id: "impressions", sequence: 3, type: "string", value: "4,815" },
          { id: "avgCPC", sequence: 4, type: "string", value: "$17.11" },
          { id: "cost", sequence: 5, type: "string", value: "$222.44" },
          { id: "avgCost", sequence: 6, type: "string", value: "$17.11" },
          { id: "ctr", sequence: 7, type: "string", value: "0.27%" },
          { id: "conversion", sequence: 8, type: "string", value: "0" },
          { id: "costConversion", sequence: 9, type: "string", value: "$0" },
          { id: "conversionRate", sequence: 10, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "age", sequence: 1, type: "string", value: "25-34" },
          { id: "clicks", sequence: 2, type: "string", value: "17" },
          { id: "impressions", sequence: 3, type: "string", value: "3,044" },
          { id: "avgCPC", sequence: 4, type: "string", value: "$22.52" },
          { id: "cost", sequence: 5, type: "string", value: "$382.81" },
          { id: "avgCost", sequence: 6, type: "string", value: "$22.52" },
          { id: "ctr", sequence: 7, type: "string", value: "0.56%" },
          { id: "conversion", sequence: 8, type: "string", value: "1" },
          { id: "costConversion", sequence: 9, type: "string", value: "$382.81" },
          { id: "conversionRate", sequence: 10, type: "string", value: "5.88%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "age", sequence: 1, type: "string", value: "35-44" },
          { id: "clicks", sequence: 2, type: "string", value: "34" },
          { id: "impressions", sequence: 3, type: "string", value: "7,521" },
          { id: "avgCPC", sequence: 4, type: "string", value: "$20.60" },
          { id: "cost", sequence: 5, type: "string", value: "$700.28" },
          { id: "avgCost", sequence: 6, type: "string", value: "$20.60" },
          { id: "ctr", sequence: 7, type: "string", value: "0.45%" },
          { id: "conversion", sequence: 8, type: "string", value: "1" },
          { id: "costConversion", sequence: 9, type: "string", value: "$700.28" },
          { id: "conversionRate", sequence: 10, type: "string", value: "2.94%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "age", sequence: 1, type: "string", value: "45-54" },
          { id: "clicks", sequence: 2, type: "string", value: "19" },
          { id: "impressions", sequence: 3, type: "string", value: "5,350" },
          { id: "avgCPC", sequence: 4, type: "string", value: "$14.08" },
          { id: "cost", sequence: 5, type: "string", value: "$267.48" },
          { id: "avgCost", sequence: 6, type: "string", value: "$14.08" },
          { id: "ctr", sequence: 7, type: "string", value: "0.36%" },
          { id: "conversion", sequence: 8, type: "string", value: "0" },
          { id: "costConversion", sequence: 9, type: "string", value: "$0" },
          { id: "conversionRate", sequence: 10, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "age", sequence: 1, type: "string", value: "55-64" },
          { id: "clicks", sequence: 2, type: "string", value: "10" },
          { id: "impressions", sequence: 3, type: "string", value: "2,085" },
          { id: "avgCPC", sequence: 4, type: "string", value: "$12.37" },
          { id: "cost", sequence: 5, type: "string", value: "$123.65" },
          { id: "avgCost", sequence: 6, type: "string", value: "$12.37" },
          { id: "ctr", sequence: 7, type: "string", value: "0.48%" },
          { id: "conversion", sequence: 8, type: "string", value: "2" },
          { id: "costConversion", sequence: 9, type: "string", value: "$61.83" },
          { id: "conversionRate", sequence: 10, type: "string", value: "20.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "age", sequence: 1, type: "string", value: "65-UP" },
          { id: "clicks", sequence: 2, type: "string", value: "3" },
          { id: "impressions", sequence: 3, type: "string", value: "8,054" },
          { id: "avgCPC", sequence: 4, type: "string", value: "$1.59" },
          { id: "cost", sequence: 5, type: "string", value: "$4.77" },
          { id: "avgCost", sequence: 6, type: "string", value: "$1.59" },
          { id: "ctr", sequence: 7, type: "string", value: "0.04%" },
          { id: "conversion", sequence: 8, type: "string", value: "0" },
          { id: "costConversion", sequence: 9, type: "string", value: "$0" },
          { id: "conversionRate", sequence: 10, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "age", sequence: 1, type: "string", value: "Undetermined" },
          { id: "clicks", sequence: 2, type: "string", value: "41" },
          { id: "impressions", sequence: 3, type: "string", value: "11,122" },
          { id: "avgCPC", sequence: 4, type: "string", value: "$18.80" },
          { id: "cost", sequence: 5, type: "string", value: "$770.85" },
          { id: "avgCost", sequence: 6, type: "string", value: "$18.80" },
          { id: "ctr", sequence: 7, type: "string", value: "0.37%" },
          { id: "conversion", sequence: 8, type: "string", value: "3" },
          { id: "costConversion", sequence: 9, type: "string", value: "$256.95" },
          { id: "conversionRate", sequence: 10, type: "string", value: "7.32%" }
        ]
      ]
    case "gads-listGrid-demographicsGender":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "gender", sequence: 1, type: "string", value: "Male" },
          { id: "clicks", sequence: 2, type: "string", value: "64" },
          { id: "impressions", sequence: 3, type: "string", value: "22,391" },
          { id: "avgCPC", sequence: 4, type: "string", value: "$15.95" },
          { id: "cost", sequence: 5, type: "string", value: "$1,020.60" },
          { id: "avgCost", sequence: 6, type: "string", value: "$15.95" },
          { id: "ctr", sequence: 7, type: "string", value: "0.29%" },
          { id: "conversion", sequence: 8, type: "string", value: "2" },
          { id: "costConversion", sequence: 9, type: "string", value: "$510.30" },
          { id: "conversionRate", sequence: 10, type: "string", value: "3.13%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "gender", sequence: 1, type: "string", value: "Female" },
          { id: "clicks", sequence: 2, type: "string", value: "31" },
          { id: "impressions", sequence: 3, type: "string", value: "8,139" },
          { id: "avgCPC", sequence: 4, type: "string", value: "$20.66" },
          { id: "cost", sequence: 5, type: "string", value: "$640.40" },
          { id: "avgCost", sequence: 6, type: "string", value: "$20.66" },
          { id: "ctr", sequence: 7, type: "string", value: "0.38%" },
          { id: "conversion", sequence: 8, type: "string", value: "2" },
          { id: "costConversion", sequence: 9, type: "string", value: "$320.20" },
          { id: "conversionRate", sequence: 10, type: "string", value: "6.45%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "gender", sequence: 1, type: "string", value: "Undetermined" },
          { id: "clicks", sequence: 2, type: "string", value: "42" },
          { id: "impressions", sequence: 3, type: "string", value: "11,461" },
          { id: "avgCPC", sequence: 4, type: "string", value: "$19.32" },
          { id: "cost", sequence: 5, type: "string", value: "$811.28" },
          { id: "avgCost", sequence: 6, type: "string", value: "$19.32" },
          { id: "ctr", sequence: 7, type: "string", value: "0.37%" },
          { id: "conversion", sequence: 8, type: "string", value: "3" },
          { id: "costConversion", sequence: 9, type: "string", value: "$270.43" },
          { id: "conversionRate", sequence: 10, type: "string", value: "7.14%" }
        ]
      ]

    case "gads-listGrid-calls":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "date", sequence: 1, type: "string", value: "July 16, 2024" },
          { id: "status", sequence: 2, type: "string", value: "RECEIVED" },
          { id: "callDuration", sequence: 3, type: "string", value: "00:00:13" },
          { id: "startTime", sequence: 4, type: "string", value: "July 16, 2024 11:28 AM" },
          { id: "endTime", sequence: 5, type: "string", value: "July 16, 2024 11:29 AM" },
          { id: "callType", sequence: 6, type: "string", value: "HIGH_END_MOBILE_SEARCH" },
          { id: "callSource", sequence: 7, type: "string", value: "AD" }
        ]
      ]
    case "gads-listGrid-campaignPerformanceByPhoneCalls":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Cello | Nike" },
          { id: "status", sequence: 2, type: "string", value: "ENABLED" },
          { id: "conversion", sequence: 3, type: "string", value: "12" },
          { id: "phoneCalls", sequence: 4, type: "string", value: "21" },
          { id: "conversionRate", sequence: 5, type: "string", value: "0.54%" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Puma" },
          { id: "status", sequence: 2, type: "string", value: "PAUSED" },
          { id: "conversion", sequence: 3, type: "string", value: "10" },
          { id: "phoneCalls", sequence: 4, type: "string", value: "201" },
          { id: "conversionRate", sequence: 5, type: "string", value: "0.4%" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Mesho" },
          { id: "status", sequence: 2, type: "string", value: "ENABLED" },
          { id: "conversion", sequence: 3, type: "string", value: "100" },
          { id: "phoneCalls", sequence: 4, type: "string", value: "2" },
          { id: "conversionRate", sequence: 5, type: "string", value: "4%" },
        ]
      ]
    case "gads-listGrid-weeklyPerformanceByClickType":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "week", sequence: 1, type: "string", value: "Jul 1, 2024 to Jul 7, 2024 (Week 20)" },
          { id: "clickType", sequence: 2, type: "string", value: "Headline" },
          { id: "clicks", sequence: 3, type: "string", value: "12" },
          { id: "impressions", sequence: 4, type: "string", value: "950" },
          { id: "avgCPC", sequence: 5, type: "string", value: "$5" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "week", sequence: 1, type: "string", value: "Jul 7, 2024 to Jul 15, 2024 (Week 25)" },
          { id: "clickType", sequence: 2, type: "string", value: "Phone Calls" },
          { id: "clicks", sequence: 3, type: "string", value: "18" },
          { id: "impressions", sequence: 4, type: "string", value: "45" },
          { id: "avgCPC", sequence: 5, type: "string", value: "$15" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "week", sequence: 1, type: "string", value: "Jul 20, 2024 to Jul 27, 2024 (Week 29)" },
          { id: "clickType", sequence: 2, type: "string", value: "Headline" },
          { id: "clicks", sequence: 3, type: "string", value: "12" },
          { id: "impressions", sequence: 4, type: "string", value: "950" },
          { id: "avgCPC", sequence: 5, type: "string", value: "$5" },
        ]
      ]
    case "gads-listGrid-citywidePerformance":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "city", sequence: 1, type: "string", value: "Mexico" },
          { id: "clicks", sequence: 2, type: "string", value: "12" },
          { id: "impressions", sequence: 3, type: "string", value: "950" },
          { id: "allConversions", sequence: 4, type: "string", value: "90" },
          { id: "convRate", sequence: 5, type: "string", value: "$5" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "city", sequence: 1, type: "string", value: "Tokeyo" },
          { id: "clicks", sequence: 2, type: "string", value: "18" },
          { id: "impressions", sequence: 3, type: "string", value: "455" },
          { id: "allConversions", sequence: 4, type: "string", value: "907" },
          { id: "convRate", sequence: 5, type: "string", value: "$15" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "city", sequence: 1, type: "string", value: "Berlin" },
          { id: "clicks", sequence: 2, type: "string", value: "12" },
          { id: "impressions", sequence: 3, type: "string", value: "950" },
          { id: "allConversions", sequence: 4, type: "string", value: "990" },
          { id: "convRate", sequence: 5, type: "string", value: "$5" },
        ]
      ]
    case "gsc-listGrid-topQueries":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "digital marketing budget" },
          { id: "clicks", sequence: 2, type: "string", value: "9" },
          { id: "impressions", sequence: 3, type: "string", value: "206" },
          { id: "avgPosition", sequence: 4, type: "string", value: "9.45" },
          { id: "ctr", sequence: 5, type: "string", value: "4.37%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "seo Turkey" },
          { id: "clicks", sequence: 2, type: "string", value: "5" },
          { id: "impressions", sequence: 3, type: "string", value: "1.41k" },
          { id: "avgPosition", sequence: 4, type: "string", value: "13.82" },
          { id: "ctr", sequence: 5, type: "string", value: "0.35%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "average cost of website design for small business" },
          { id: "clicks", sequence: 2, type: "string", value: "4" },
          { id: "impressions", sequence: 3, type: "string", value: "109" },
          { id: "avgPosition", sequence: 4, type: "string", value: "5.94" },
          { id: "ctr", sequence: 5, type: "string", value: "3.67%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "seo agency" },
          { id: "clicks", sequence: 2, type: "string", value: "4" },
          { id: "impressions", sequence: 3, type: "string", value: "1.31k" },
          { id: "avgPosition", sequence: 4, type: "string", value: "71.73" },
          { id: "ctr", sequence: 5, type: "string", value: "0.30%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "digital marketing budgeting" },
          { id: "clicks", sequence: 2, type: "string", value: "3" },
          { id: "impressions", sequence: 3, type: "string", value: "109" },
          { id: "avgPosition", sequence: 4, type: "string", value: "9.18" },
          { id: "ctr", sequence: 5, type: "string", value: "2.75%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "seo cost" },
          { id: "clicks", sequence: 2, type: "string", value: "3" },
          { id: "impressions", sequence: 3, type: "string", value: "188" },
          { id: "avgPosition", sequence: 4, type: "string", value: "17.49" },
          { id: "ctr", sequence: 5, type: "string", value: "1.60%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "website cost global" },
          { id: "clicks", sequence: 2, type: "string", value: "3" },
          { id: "impressions", sequence: 3, type: "string", value: "64" },
          { id: "avgPosition", sequence: 4, type: "string", value: "2.38" },
          { id: "ctr", sequence: 5, type: "string", value: "4.69%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "budget allocation in digital marketing" },
          { id: "clicks", sequence: 2, type: "string", value: "2" },
          { id: "impressions", sequence: 3, type: "string", value: "9" },
          { id: "avgPosition", sequence: 4, type: "string", value: "4.33" },
          { id: "ctr", sequence: 5, type: "string", value: "22.22%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "digital marketing agency Turkey" },
          { id: "clicks", sequence: 2, type: "string", value: "2" },
          { id: "impressions", sequence: 3, type: "string", value: "160" },
          { id: "avgPosition", sequence: 4, type: "string", value: "58.92" },
          { id: "ctr", sequence: 5, type: "string", value: "1.25%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "how much does a website cost in global" },
          { id: "clicks", sequence: 2, type: "string", value: "2" },
          { id: "impressions", sequence: 3, type: "string", value: "82" },
          { id: "avgPosition", sequence: 4, type: "string", value: "1.73" },
          { id: "ctr", sequence: 5, type: "string", value: "2.44%" }
        ]
      ]
    case "gsc-listGrid-topPages":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://www.tata.com/models/" },
          { id: "clicks", sequence: 2, type: "string", value: "180" },
          { id: "impressions", sequence: 3, type: "string", value: "6,651" },
          { id: "avgPosition", sequence: 4, type: "string", value: "32.00" },
          { id: "ctr", sequence: 5, type: "string", value: "8.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://www.tata.com/careers" },
          { id: "clicks", sequence: 2, type: "string", value: "170" },
          { id: "impressions", sequence: 3, type: "string", value: "6,542" },
          { id: "avgPosition", sequence: 4, type: "string", value: "23.00" },
          { id: "ctr", sequence: 5, type: "string", value: "6.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://www.tata.com/modelx/" },
          { id: "clicks", sequence: 2, type: "string", value: "160" },
          { id: "impressions", sequence: 3, type: "string", value: "6,250" },
          { id: "avgPosition", sequence: 4, type: "string", value: "34.00" },
          { id: "ctr", sequence: 5, type: "string", value: "5.00%" }
        ]
      ]
    case "gsc-listGrid-countries":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "aus" },
          { id: "clicks", sequence: 2, type: "string", value: "158" },
          { id: "impressions", sequence: 3, type: "string", value: "16.08k" },
          { id: "avgPosition", sequence: 4, type: "string", value: "24.23" },
          { id: "ctr", sequence: 5, type: "string", value: "0.98%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "ind" },
          { id: "clicks", sequence: 2, type: "string", value: "96" },
          { id: "impressions", sequence: 3, type: "string", value: "8.75k" },
          { id: "avgPosition", sequence: 4, type: "string", value: "33.69" },
          { id: "ctr", sequence: 5, type: "string", value: "1.10%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "pak" },
          { id: "clicks", sequence: 2, type: "string", value: "10" },
          { id: "impressions", sequence: 3, type: "string", value: "1.10k" },
          { id: "avgPosition", sequence: 4, type: "string", value: "43.96" },
          { id: "ctr", sequence: 5, type: "string", value: "0.91%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "bgd" },
          { id: "clicks", sequence: 2, type: "string", value: "7" },
          { id: "impressions", sequence: 3, type: "string", value: "1.01k" },
          { id: "avgPosition", sequence: 4, type: "string", value: "54.83" },
          { id: "ctr", sequence: 5, type: "string", value: "0.70%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "usa" },
          { id: "clicks", sequence: 2, type: "string", value: "7" },
          { id: "impressions", sequence: 3, type: "string", value: "35.62k" },
          { id: "avgPosition", sequence: 4, type: "string", value: "55.28" },
          { id: "ctr", sequence: 5, type: "string", value: "0.02%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "vnm" },
          { id: "clicks", sequence: 2, type: "string", value: "7" },
          { id: "impressions", sequence: 3, type: "string", value: "6.31k" },
          { id: "avgPosition", sequence: 4, type: "string", value: "49.45" },
          { id: "ctr", sequence: 5, type: "string", value: "0.11%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "idn" },
          { id: "clicks", sequence: 2, type: "string", value: "5" },
          { id: "impressions", sequence: 3, type: "string", value: "2k" },
          { id: "avgPosition", sequence: 4, type: "string", value: "41.09" },
          { id: "ctr", sequence: 5, type: "string", value: "0.25%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "gbr" },
          { id: "clicks", sequence: 2, type: "string", value: "4" },
          { id: "impressions", sequence: 3, type: "string", value: "5.01k" },
          { id: "avgPosition", sequence: 4, type: "string", value: "49.81" },
          { id: "ctr", sequence: 5, type: "string", value: "0.08%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "sgp" },
          { id: "clicks", sequence: 2, type: "string", value: "4" },
          { id: "impressions", sequence: 3, type: "string", value: "865" },
          { id: "avgPosition", sequence: 4, type: "string", value: "38.45" },
          { id: "ctr", sequence: 5, type: "string", value: "0.46%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "nga" },
          { id: "clicks", sequence: 2, type: "string", value: "3" },
          { id: "impressions", sequence: 3, type: "string", value: "138" },
          { id: "avgPosition", sequence: 4, type: "string", value: "33.92" },
          { id: "ctr", sequence: 5, type: "string", value: "2.17%" }
        ]
      ]
    case "gsc-listGrid-devices":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "device", sequence: 1, type: "string", value: "DESKTOP" },
          { id: "clicks", sequence: 2, type: "string", value: "231" },
          { id: "impressions", sequence: 3, type: "string", value: "102.21k" },
          { id: "avgPosition", sequence: 4, type: "string", value: "46.45" },
          { id: "ctr", sequence: 5, type: "string", value: "0.23%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "device", sequence: 1, type: "string", value: "MOBILE" },
          { id: "clicks", sequence: 2, type: "string", value: "109" },
          { id: "impressions", sequence: 3, type: "string", value: "38.52k" },
          { id: "avgPosition", sequence: 4, type: "string", value: "48.80" },
          { id: "ctr", sequence: 5, type: "string", value: "0.28%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "device", sequence: 1, type: "string", value: "TABLET" },
          { id: "clicks", sequence: 2, type: "string", value: "2" },
          { id: "impressions", sequence: 3, type: "string", value: "302" },
          { id: "avgPosition", sequence: 4, type: "string", value: "18.85" },
          { id: "ctr", sequence: 5, type: "string", value: "0.66%" }
        ]
      ]
    case "gsc-listGrid-sitemaps":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "path", sequence: 1, type: "url", value: "https://samplewebsite.com/electromagnetic resonance-bless-stratosphere-embark on-ultra-" },
          { id: "type", sequence: 2, type: "string", value: "YES" },
          { id: "pending", sequence: 3, type: "string", value: "NO" },
          { id: "sitemapsIndex", sequence: 4, type: "string", value: "YES" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "path", sequence: 1, type: "url", value: "https://samplewebsite.org/aglow with-refining-baptized in-adventurers-souls-messengers.html" },
          { id: "type", sequence: 2, type: "string", value: "NO" },
          { id: "pending", sequence: 3, type: "string", value: "YES" },
          { id: "sitemapsIndex", sequence: 4, type: "string", value: "YES" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "path", sequence: 1, type: "url", value: "https://suzak.biz/homeopathy-ghu-acupuncture-electrical impulses-wanderer-metamorphosis-guided-let us access.html" },
          { id: "type", sequence: 2, type: "string", value: "NO" },
          { id: "pending", sequence: 3, type: "string", value: "NO" },
          { id: "sitemapsIndex", sequence: 4, type: "string", value: "NO" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "path", sequence: 1, type: "url", value: "https://sonroy.net/ethy-qtum soup-myth-enveloped in-folding-aligned.html" },
          { id: "type", sequence: 2, type: "string", value: "NO" },
          { id: "pending", sequence: 3, type: "string", value: "YES" },
          { id: "sitemapsIndex", sequence: 4, type: "string", value: "YES" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "path", sequence: 1, type: "url", value: "https://dvide.bi/in this dimension-trna into-transrotateion-four-dimensional-structures-naturopathy" },
          { id: "type", sequence: 2, type: "string", value: "YES" },
          { id: "pending", sequence: 3, type: "string", value: "NO" },
          { id: "sitemapsIndex", sequence: 4, type: "string", value: "NO" }
        ]
      ]
    case "mads-listGrid-campaigns":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Ring" },
          { id: "impressions", sequence: 2, type: "string", value: "716" },
          { id: "clicks", sequence: 3, type: "string", value: "93" },
          { id: "averageCpc", sequence: 4, type: "string", value: "$4.19" },
          { id: "cost", sequence: 5, type: "string", value: "$45.00" },
          { id: "ctr", sequence: 6, type: "string", value: "4.99%" },
          { id: "conversions", sequence: 7, type: "string", value: "6" },
          { id: "conversionRate", sequence: 8, type: "string", value: "3.89%" },
          { id: "cpa", sequence: 9, type: "string", value: "$107.32" },
          { id: "returnOnAdSpend", sequence: 10, type: "string", value: "18.89%" },
          { id: "averageCpm", sequence: 11, type: "string", value: "$39.32" },
          { id: "revenue", sequence: 12, type: "string", value: "$245.62" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Wing" },
          { id: "impressions", sequence: 2, type: "string", value: "906" },
          { id: "clicks", sequence: 3, type: "string", value: "65" },
          { id: "averageCpc", sequence: 4, type: "string", value: "$1.73" },
          { id: "cost", sequence: 5, type: "string", value: "$52.00" },
          { id: "ctr", sequence: 6, type: "string", value: "7.05%" },
          { id: "conversions", sequence: 7, type: "string", value: "13" },
          { id: "conversionRate", sequence: 8, type: "string", value: "5.27%" },
          { id: "cpa", sequence: 9, type: "string", value: "$231.40" },
          { id: "returnOnAdSpend", sequence: 10, type: "string", value: "180.05%" },
          { id: "averageCpm", sequence: 11, type: "string", value: "$14.74" },
          { id: "revenue", sequence: 12, type: "string", value: "$537.12" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Auto Velvet" },
          { id: "impressions", sequence: 2, type: "string", value: "927" },
          { id: "clicks", sequence: 3, type: "string", value: "63" },
          { id: "averageCpc", sequence: 4, type: "string", value: "$3.28" },
          { id: "cost", sequence: 5, type: "string", value: "$41.00" },
          { id: "ctr", sequence: 6, type: "string", value: "7.65%" },
          { id: "conversions", sequence: 7, type: "string", value: "20" },
          { id: "conversionRate", sequence: 8, type: "string", value: "1.54%" },
          { id: "cpa", sequence: 9, type: "string", value: "$521.21" },
          { id: "returnOnAdSpend", sequence: 10, type: "string", value: "238.72%" },
          { id: "averageCpm", sequence: 11, type: "string", value: "$159.35" },
          { id: "revenue", sequence: 12, type: "string", value: "$1023.89" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Dental Clinic" },
          { id: "impressions", sequence: 2, type: "string", value: "830" },
          { id: "clicks", sequence: 3, type: "string", value: "60" },
          { id: "averageCpc", sequence: 4, type: "string", value: "$0.10" },
          { id: "cost", sequence: 5, type: "string", value: "$40.00" },
          { id: "ctr", sequence: 6, type: "string", value: "7.17%" },
          { id: "conversions", sequence: 7, type: "string", value: "24" },
          { id: "conversionRate", sequence: 8, type: "string", value: "3.17%" },
          { id: "cpa", sequence: 9, type: "string", value: "$419.67" },
          { id: "returnOnAdSpend", sequence: 10, type: "string", value: "1038.27%" },
          { id: "averageCpm", sequence: 11, type: "string", value: "$749.34" },
          { id: "revenue", sequence: 12, type: "string", value: "$843.61" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Green Leaves" },
          { id: "impressions", sequence: 2, type: "string", value: "806" },
          { id: "clicks", sequence: 3, type: "string", value: "510" },
          { id: "averageCpc", sequence: 4, type: "string", value: "$2.06" },
          { id: "cost", sequence: 5, type: "string", value: "$32.00" },
          { id: "ctr", sequence: 6, type: "string", value: "6.01%" },
          { id: "conversions", sequence: 7, type: "string", value: "12" },
          { id: "conversionRate", sequence: 8, type: "string", value: "1.62%" },
          { id: "cpa", sequence: 9, type: "string", value: "$210.80" },
          { id: "returnOnAdSpend", sequence: 10, type: "string", value: "803.51%" },
          { id: "averageCpm", sequence: 11, type: "string", value: "$235.96" },
          { id: "revenue", sequence: 12, type: "string", value: "$619.34" },
        ]
      ]
    case "mads-listGrid-adGroups":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "adGroup", sequence: 1, type: "string", value: "Tech Products" },
          { id: "campaign", sequence: 2, type: "string", value: "Gadget Galaxy" },
          { id: "clicks", sequence: 3, type: "string", value: "128" },
          { id: "impressions", sequence: 4, type: "string", value: "10500" },
          { id: "averageCpc", sequence: 5, type: "string", value: "$3.59" },
          { id: "cost", sequence: 6, type: "string", value: "$458.00" },
          { id: "ctr", sequence: 7, type: "string", value: "5.56%" },
          { id: "conversions", sequence: 8, type: "string", value: "16" },
          { id: "conversionRate", sequence: 9, type: "string", value: "4.08%" },
          { id: "cpa", sequence: 10, type: "string", value: "$28.63" },
          { id: "returnOnAdSpend", sequence: 11, type: "string", value: "512.15%" },
          { id: "averageCpm", sequence: 12, type: "string", value: "$43.62" },
          { id: "revenue", sequence: 13, type: "string", value: "$2357.14" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 1 },
          { id: "adGroup", sequence: 1, type: "string", value: "Home Appliances" },
          { id: "campaign", sequence: 2, type: "string", value: "Smart Living" },
          { id: "clicks", sequence: 3, type: "string", value: "102" },
          { id: "impressions", sequence: 4, type: "string", value: "9800" },
          { id: "averageCpc", sequence: 5, type: "string", value: "$2.89" },
          { id: "cost", sequence: 6, type: "string", value: "$294.00" },
          { id: "ctr", sequence: 7, type: "string", value: "5.20%" },
          { id: "conversions", sequence: 8, type: "string", value: "12" },
          { id: "conversionRate", sequence: 9, type: "string", value: "3.92%" },
          { id: "cpa", sequence: 10, type: "string", value: "$24.50" },
          { id: "returnOnAdSpend", sequence: 11, type: "string", value: "400.00%" },
          { id: "averageCpm", sequence: 12, type: "string", value: "$30.00" },
          { id: "revenue", sequence: 13, type: "string", value: "$1176.00" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 2 },
          { id: "adGroup", sequence: 1, type: "string", value: "Fitness Gear" },
          { id: "campaign", sequence: 2, type: "string", value: "Get Fit Today" },
          { id: "clicks", sequence: 3, type: "string", value: "75" },
          { id: "impressions", sequence: 4, type: "string", value: "9200" },
          { id: "averageCpc", sequence: 5, type: "string", value: "$5.50" },
          { id: "cost", sequence: 6, type: "string", value: "$412.50" },
          { id: "ctr", sequence: 7, type: "string", value: "4.15%" },
          { id: "conversions", sequence: 8, type: "string", value: "8" },
          { id: "conversionRate", sequence: 9, type: "string", value: "2.67%" },
          { id: "cpa", sequence: 10, type: "string", value: "$51.56" },
          { id: "returnOnAdSpend", sequence: 11, type: "string", value: "200.00%" },
          { id: "averageCpm", sequence: 12, type: "string", value: "$45.00" },
          { id: "revenue", sequence: 13, type: "string", value: "$825.00" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 3 },
          { id: "adGroup", sequence: 1, type: "string", value: "Pet Care" },
          { id: "campaign", sequence: 2, type: "string", value: "Healthy Pets" },
          { id: "clicks", sequence: 3, type: "string", value: "115" },
          { id: "impressions", sequence: 4, type: "string", value: "10500" },
          { id: "averageCpc", sequence: 5, type: "string", value: "$4.10" },
          { id: "cost", sequence: 6, type: "string", value: "$471.50" },
          { id: "ctr", sequence: 7, type: "string", value: "5.49%" },
          { id: "conversions", sequence: 8, type: "string", value: "14" },
          { id: "conversionRate", sequence: 9, type: "string", value: "4.08%" },
          { id: "cpa", sequence: 10, type: "string", value: "$33.64" },
          { id: "returnOnAdSpend", sequence: 11, type: "string", value: "430.00%" },
          { id: "averageCpm", sequence: 12, type: "string", value: "$44.90" },
          { id: "revenue", sequence: 13, type: "string", value: "$2029.60" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 4 },
          { id: "adGroup", sequence: 1, type: "string", value: "Outdoor Equipment" },
          { id: "campaign", sequence: 2, type: "string", value: "Adventure Awaits" },
          { id: "clicks", sequence: 3, type: "string", value: "90" },
          { id: "impressions", sequence: 4, type: "string", value: "8500" },
          { id: "averageCpc", sequence: 5, type: "string", value: "$6.99" },
          { id: "cost", sequence: 6, type: "string", value: "$629.10" },
          { id: "ctr", sequence: 7, type: "string", value: "6.47%" },
          { id: "conversions", sequence: 8, type: "string", value: "10" },
          { id: "conversionRate", sequence: 9, type: "string", value: "4.44%" },
          { id: "cpa", sequence: 10, type: "string", value: "$62.91" },
          { id: "returnOnAdSpend", sequence: 11, type: "string", value: "155.60%" },
          { id: "averageCpm", sequence: 12, type: "string", value: "$73.98" },
          { id: "revenue", sequence: 13, type: "string", value: "$983.90" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 5 },
          { id: "adGroup", sequence: 1, type: "string", value: "Luxury Watches" },
          { id: "campaign", sequence: 2, type: "string", value: "Timeless Style" },
          { id: "clicks", sequence: 3, type: "string", value: "80" },
          { id: "impressions", sequence: 4, type: "string", value: "7600" },
          { id: "averageCpc", sequence: 5, type: "string", value: "$7.50" },
          { id: "cost", sequence: 6, type: "string", value: "$600.00" },
          { id: "ctr", sequence: 7, type: "string", value: "5.26%" },
          { id: "conversions", sequence: 8, type: "string", value: "6" },
          { id: "conversionRate", sequence: 9, type: "string", value: "2.53%" },
          { id: "cpa", sequence: 10, type: "string", value: "$100.00" },
          { id: "returnOnAdSpend", sequence: 11, type: "string", value: "280.00%" },
          { id: "averageCpm", sequence: 12, type: "string", value: "$79.30" },
          { id: "revenue", sequence: 13, type: "string", value: "$1680.00" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 6 },
          { id: "adGroup", sequence: 1, type: "string", value: "Travel Packages" },
          { id: "campaign", sequence: 2, type: "string", value: "Dream Vacation" },
          { id: "clicks", sequence: 3, type: "string", value: "150" },
          { id: "impressions", sequence: 4, type: "string", value: "12000" },
          { id: "averageCpc", sequence: 5, type: "string", value: "$3.20" },
          { id: "cost", sequence: 6, type: "string", value: "$480.00" },
          { id: "ctr", sequence: 7, type: "string", value: "6.25%" },
          { id: "conversions", sequence: 8, type: "string", value: "20" },
          { id: "conversionRate", sequence: 9, type: "string", value: "4.67%" },
          { id: "cpa", sequence: 10, type: "string", value: "$24.00" },
          { id: "returnOnAdSpend", sequence: 11, type: "string", value: "416.67%" },
          { id: "averageCpm", sequence: 12, type: "string", value: "$40.00" },
          { id: "revenue", sequence: 13, type: "string", value: "$2000.00" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 7 },
          { id: "adGroup", sequence: 1, type: "string", value: "Tech Accessories" },
          { id: "campaign", sequence: 2, type: "string", value: "Future Tech" },
          { id: "clicks", sequence: 3, type: "string", value: "135" },
          { id: "impressions", sequence: 4, type: "string", value: "11200" },
          { id: "averageCpc", sequence: 5, type: "string", value: "$2.80" },
          { id: "cost", sequence: 6, type: "string", value: "$378.00" },
          { id: "ctr", sequence: 7, type: "string", value: "6.05%" },
          { id: "conversions", sequence: 8, type: "string", value: "18" },
          { id: "conversionRate", sequence: 9, type: "string", value: "4.67%" },
          { id: "cpa", sequence: 10, type: "string", value: "$21.00" },
          { id: "returnOnAdSpend", sequence: 11, type: "string", value: "600.00%" },
          { id: "averageCpm", sequence: 12, type: "string", value: "$33.75" },
          { id: "revenue", sequence: 13, type: "string", value: "$2500.00" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 8 },
          { id: "adGroup", sequence: 1, type: "string", value: "Home Decor" },
          { id: "campaign", sequence: 2, type: "string", value: "Comfort Living" },
          { id: "clicks", sequence: 3, type: "string", value: "110" },
          { id: "impressions", sequence: 4, type: "string", value: "10200" },
          { id: "averageCpc", sequence: 5, type: "string", value: "$4.00" },
          { id: "cost", sequence: 6, type: "string", value: "$440.00" },
          { id: "ctr", sequence: 7, type: "string", value: "5.39%" },
          { id: "conversions", sequence: 8, type: "string", value: "14" },
          { id: "conversionRate", sequence: 9, type: "string", value: "4.17%" },
          { id: "cpa", sequence: 10, type: "string", value: "$31.43" },
          { id: "returnOnAdSpend", sequence: 11, type: "string", value: "500.00%" },
          { id: "averageCpm", sequence: 12, type: "string", value: "$43.14" },
          { id: "revenue", sequence: 13, type: "string", value: "$2200.00" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 9 },
          { id: "adGroup", sequence: 1, type: "string", value: "Gaming Gear" },
          { id: "campaign", sequence: 2, type: "string", value: "Next-Gen Play" },
          { id: "clicks", sequence: 3, type: "string", value: "125" },
          { id: "impressions", sequence: 4, type: "string", value: "11000" },
          { id: "averageCpc", sequence: 5, type: "string", value: "$3.20" },
          { id: "cost", sequence: 6, type: "string", value: "$400.00" },
          { id: "ctr", sequence: 7, type: "string", value: "5.68%" },
          { id: "conversions", sequence: 8, type: "string", value: "20" },
          { id: "conversionRate", sequence: 9, type: "string", value: "4.00%" },
          { id: "cpa", sequence: 10, type: "string", value: "$20.00" },
          { id: "returnOnAdSpend", sequence: 11, type: "string", value: "500.00%" },
          { id: "averageCpm", sequence: 12, type: "string", value: "$36.36" },
          { id: "revenue", sequence: 13, type: "string", value: "$2000.00" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 10 },
          { id: "adGroup", sequence: 1, type: "string", value: "Fashion Accessories" },
          { id: "campaign", sequence: 2, type: "string", value: "Chic and Stylish" },
          { id: "clicks", sequence: 3, type: "string", value: "95" },
          { id: "impressions", sequence: 4, type: "string", value: "8500" },
          { id: "averageCpc", sequence: 5, type: "string", value: "$5.00" },
          { id: "cost", sequence: 6, type: "string", value: "$475.00" },
          { id: "ctr", sequence: 7, type: "string", value: "5.59%" },
          { id: "conversions", sequence: 8, type: "string", value: "10" },
          { id: "conversionRate", sequence: 9, type: "string", value: "3.33%" },
          { id: "cpa", sequence: 10, type: "string", value: "$47.50" },
          { id: "returnOnAdSpend", sequence: 11, type: "string", value: "400.00%" },
          { id: "averageCpm", sequence: 12, type: "string", value: "$55.88" },
          { id: "revenue", sequence: 13, type: "string", value: "$1900.00" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 11 },
          { id: "adGroup", sequence: 1, type: "string", value: "Photography" },
          { id: "campaign", sequence: 2, type: "string", value: "Capture Moments" },
          { id: "clicks", sequence: 3, type: "string", value: "110" },
          { id: "impressions", sequence: 4, type: "string", value: "9800" },
          { id: "averageCpc", sequence: 5, type: "string", value: "$6.20" },
          { id: "cost", sequence: 6, type: "string", value: "$682.00" },
          { id: "ctr", sequence: 7, type: "string", value: "6.12%" },
          { id: "conversions", sequence: 8, type: "string", value: "15" },
          { id: "conversionRate", sequence: 9, type: "string", value: "4.12%" },
          { id: "cpa", sequence: 10, type: "string", value: "$45.47" },
          { id: "returnOnAdSpend", sequence: 11, type: "string", value: "350.00%" },
          { id: "averageCpm", sequence: 12, type: "string", value: "$69.53" },
          { id: "revenue", sequence: 13, type: "string", value: "$1500.00" },
        ]
      ]
    case "mads-listGrid-keywords":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "Cosmic Router" },
          { id: "adGroup", sequence: 2, type: "string", value: "Technology" },
          { id: "campaign", sequence: 3, type: "string", value: "Tech Surge" },
          { id: "impressions", sequence: 4, type: "string", value: "12102" },
          { id: "clicks", sequence: 5, type: "string", value: "118" },
          { id: "averageCpc", sequence: 6, type: "string", value: "$3.56" },
          { id: "cost", sequence: 7, type: "string", value: "$420.00" },
          { id: "ctr", sequence: 8, type: "string", value: "5.27%" },
          { id: "conversions", sequence: 9, type: "string", value: "15" },
          { id: "conversionRate", sequence: 10, type: "string", value: "3.72%" },
          { id: "cpa", sequence: 11, type: "string", value: "$28.00" },
          { id: "returnOnAdSpend", sequence: 12, type: "string", value: "1575.00%" },
          { id: "averageCpm", sequence: 13, type: "string", value: "$34.68" },
          { id: "revenue", sequence: 14, type: "string", value: "$6600.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 1 },
          { id: "keyword", sequence: 1, type: "string", value: "Vortex Lens" },
          { id: "adGroup", sequence: 2, type: "string", value: "Photography" },
          { id: "campaign", sequence: 3, type: "string", value: "Photo Pro" },
          { id: "impressions", sequence: 4, type: "string", value: "9705" },
          { id: "clicks", sequence: 5, type: "string", value: "92" },
          { id: "averageCpc", sequence: 6, type: "string", value: "$2.95" },
          { id: "cost", sequence: 7, type: "string", value: "$271.00" },
          { id: "ctr", sequence: 8, type: "string", value: "5.15%" },
          { id: "conversions", sequence: 9, type: "string", value: "11" },
          { id: "conversionRate", sequence: 10, type: "string", value: "4.19%" },
          { id: "cpa", sequence: 11, type: "string", value: "$24.64" },
          { id: "returnOnAdSpend", sequence: 12, type: "string", value: "2205.01%" },
          { id: "averageCpm", sequence: 13, type: "string", value: "$27.89" },
          { id: "revenue", sequence: 14, type: "string", value: "$5985.11" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 2 },
          { id: "keyword", sequence: 1, type: "string", value: "Zenith Watch" },
          { id: "adGroup", sequence: 2, type: "string", value: "Luxury" },
          { id: "campaign", sequence: 3, type: "string", value: "Timeless Elegance" },
          { id: "impressions", sequence: 4, type: "string", value: "11892" },
          { id: "clicks", sequence: 5, type: "string", value: "134" },
          { id: "averageCpc", sequence: 6, type: "string", value: "$4.10" },
          { id: "cost", sequence: 7, type: "string", value: "$550.00" },
          { id: "ctr", sequence: 8, type: "string", value: "6.43%" },
          { id: "conversions", sequence: 9, type: "string", value: "18" },
          { id: "conversionRate", sequence: 10, type: "string", value: "4.72%" },
          { id: "cpa", sequence: 11, type: "string", value: "$30.56" },
          { id: "returnOnAdSpend", sequence: 12, type: "string", value: "1728.20%" },
          { id: "averageCpm", sequence: 13, type: "string", value: "$46.23" },
          { id: "revenue", sequence: 14, type: "string", value: "$9500.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 3 },
          { id: "keyword", sequence: 1, type: "string", value: "Lunar Space" },
          { id: "adGroup", sequence: 2, type: "string", value: "Tech Gadgets" },
          { id: "campaign", sequence: 3, type: "string", value: "Future Forward" },
          { id: "impressions", sequence: 4, type: "string", value: "13456" },
          { id: "clicks", sequence: 5, type: "string", value: "155" },
          { id: "averageCpc", sequence: 6, type: "string", value: "$3.45" },
          { id: "cost", sequence: 7, type: "string", value: "$534.75" },
          { id: "ctr", sequence: 8, type: "string", value: "5.32%" },
          { id: "conversions", sequence: 9, type: "string", value: "22" },
          { id: "conversionRate", sequence: 10, type: "string", value: "5.46%" },
          { id: "cpa", sequence: 11, type: "string", value: "$24.31" },
          { id: "returnOnAdSpend", sequence: 12, type: "string", value: "1450.00%" },
          { id: "averageCpm", sequence: 13, type: "string", value: "$39.75" },
          { id: "revenue", sequence: 14, type: "string", value: "$7750.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 4 },
          { id: "keyword", sequence: 1, type: "string", value: "Quantum Gear" },
          { id: "adGroup", sequence: 2, type: "string", value: "Tech Accessories" },
          { id: "campaign", sequence: 3, type: "string", value: "Tech Revolution" },
          { id: "impressions", sequence: 4, type: "string", value: "11234" },
          { id: "clicks", sequence: 5, type: "string", value: "104" },
          { id: "averageCpc", sequence: 6, type: "string", value: "$3.89" },
          { id: "cost", sequence: 7, type: "string", value: "$402.00" },
          { id: "ctr", sequence: 8, type: "string", value: "5.42%" },
          { id: "conversions", sequence: 9, type: "string", value: "16" },
          { id: "conversionRate", sequence: 10, type: "string", value: "4.82%" },
          { id: "cpa", sequence: 11, type: "string", value: "$25.12" },
          { id: "returnOnAdSpend", sequence: 12, type: "string", value: "1913.62%" },
          { id: "averageCpm", sequence: 13, type: "string", value: "$35.80" },
          { id: "revenue", sequence: 14, type: "string", value: "$7680.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 5 },
          { id: "keyword", sequence: 1, type: "string", value: "Nano Filter" },
          { id: "adGroup", sequence: 2, type: "string", value: "Health" },
          { id: "campaign", sequence: 3, type: "string", value: "Clean Air" },
          { id: "impressions", sequence: 4, type: "string", value: "9041" },
          { id: "clicks", sequence: 5, type: "string", value: "89" },
          { id: "averageCpc", sequence: 6, type: "string", value: "$2.79" },
          { id: "cost", sequence: 7, type: "string", value: "$248.00" },
          { id: "ctr", sequence: 8, type: "string", value: "5.42%" },
          { id: "conversions", sequence: 9, type: "string", value: "10" },
          { id: "conversionRate", sequence: 10, type: "string", value: "3.50%" },
          { id: "cpa", sequence: 11, type: "string", value: "$24.80" },
          { id: "returnOnAdSpend", sequence: 12, type: "string", value: "2202.48%" },
          { id: "averageCpm", sequence: 13, type: "string", value: "$27.48" },
          { id: "revenue", sequence: 14, type: "string", value: "$5500.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 6 },
          { id: "keyword", sequence: 1, type: "string", value: "Neutron Scanner" },
          { id: "adGroup", sequence: 2, type: "string", value: "Tech Solutions" },
          { id: "campaign", sequence: 3, type: "string", value: "Advanced Tech" },
          { id: "impressions", sequence: 4, type: "string", value: "12050" },
          { id: "clicks", sequence: 5, type: "string", value: "130" },
          { id: "averageCpc", sequence: 6, type: "string", value: "$4.02" },
          { id: "cost", sequence: 7, type: "string", value: "$523.00" },
          { id: "ctr", sequence: 8, type: "string", value: "6.68%" },
          { id: "conversions", sequence: 9, type: "string", value: "21" },
          { id: "conversionRate", sequence: 10, type: "string", value: "4.84%" },
          { id: "cpa", sequence: 11, type: "string", value: "$24.90" },
          { id: "returnOnAdSpend", sequence: 12, type: "string", value: "2051.10%" },
          { id: "averageCpm", sequence: 13, type: "string", value: "$43.42" },
          { id: "revenue", sequence: 14, type: "string", value: "$8820.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 7 },
          { id: "keyword", sequence: 1, type: "string", value: "Laser Focus" },
          { id: "adGroup", sequence: 2, type: "string", value: "Optics" },
          { id: "campaign", sequence: 3, type: "string", value: "Precision Optics" },
          { id: "impressions", sequence: 4, type: "string", value: "10998" },
          { id: "clicks", sequence: 5, type: "string", value: "123" },
          { id: "averageCpc", sequence: 6, type: "string", value: "$3.66" },
          { id: "cost", sequence: 7, type: "string", value: "$450.00" },
          { id: "ctr", sequence: 8, type: "string", value: "6.56%" },
          { id: "conversions", sequence: 9, type: "string", value: "14" },
          { id: "conversionRate", sequence: 10, type: "string", value: "5.50%" },
          { id: "cpa", sequence: 11, type: "string", value: "$32.14" },
          { id: "returnOnAdSpend", sequence: 12, type: "string", value: "1413.25%" },
          { id: "averageCpm", sequence: 13, type: "string", value: "$41.15" },
          { id: "revenue", sequence: 14, type: "string", value: "$6400.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 8 },
          { id: "keyword", sequence: 1, type: "string", value: "Plasma Shield" },
          { id: "adGroup", sequence: 2, type: "string", value: "Health Tech" },
          { id: "campaign", sequence: 3, type: "string", value: "Future Healthcare" },
          { id: "impressions", sequence: 4, type: "string", value: "11000" },
          { id: "clicks", sequence: 5, type: "string", value: "98" },
          { id: "averageCpc", sequence: 6, type: "string", value: "$4.05" },
          { id: "cost", sequence: 7, type: "string", value: "$396.00" },
          { id: "ctr", sequence: 8, type: "string", value: "5.53%" },
          { id: "conversions", sequence: 9, type: "string", value: "17" },
          { id: "conversionRate", sequence: 10, type: "string", value: "6.94%" },
          { id: "cpa", sequence: 11, type: "string", value: "$23.29" },
          { id: "returnOnAdSpend", sequence: 12, type: "string", value: "1719.21%" },
          { id: "averageCpm", sequence: 13, type: "string", value: "$36.00" },
          { id: "revenue", sequence: 14, type: "string", value: "$6100.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 9 },
          { id: "keyword", sequence: 1, type: "string", value: "Nano Drone" },
          { id: "adGroup", sequence: 2, type: "string", value: "Tech Gadgets" },
          { id: "campaign", sequence: 3, type: "string", value: "Future Flying" },
          { id: "impressions", sequence: 4, type: "string", value: "13256" },
          { id: "clicks", sequence: 5, type: "string", value: "144" },
          { id: "averageCpc", sequence: 6, type: "string", value: "$3.22" },
          { id: "cost", sequence: 7, type: "string", value: "$463.00" },
          { id: "ctr", sequence: 8, type: "string", value: "5.85%" },
          { id: "conversions", sequence: 9, type: "string", value: "19" },
          { id: "conversionRate", sequence: 10, type: "string", value: "5.97%" },
          { id: "cpa", sequence: 11, type: "string", value: "$24.37" },
          { id: "returnOnAdSpend", sequence: 12, type: "string", value: "1821.65%" },
          { id: "averageCpm", sequence: 13, type: "string", value: "$35.00" },
          { id: "revenue", sequence: 14, type: "string", value: "$7650.00" }
        ]
      ]
    case "mads-listGrid-conversions":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "goal", sequence: 1, type: "string", value: "Contact List" },
          { id: "campaign", sequence: 2, type: "string", value: "Dental Clinic" },
          { id: "conversions", sequence: 3, type: "string", value: "3" },
          { id: "revenue", sequence: 4, type: "string", value: "$20.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "goal", sequence: 1, type: "string", value: "Phone" },
          { id: "campaign", sequence: 2, type: "string", value: "Green Leaves" },
          { id: "conversions", sequence: 3, type: "string", value: "4" },
          { id: "revenue", sequence: 4, type: "string", value: "$17.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "goal", sequence: 1, type: "string", value: "Order Number" },
          { id: "campaign", sequence: 2, type: "string", value: "Dental Clinic" },
          { id: "conversions", sequence: 3, type: "string", value: "2" },
          { id: "revenue", sequence: 4, type: "string", value: "$14.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "goal", sequence: 1, type: "string", value: "Email" },
          { id: "campaign", sequence: 2, type: "string", value: "Wing" },
          { id: "conversions", sequence: 3, type: "string", value: "5" },
          { id: "revenue", sequence: 4, type: "string", value: "$15.00" }
        ]
      ]
    case "mads-listGrid-citywidePerformanceByClicks":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "city", sequence: 1, type: "string", value: "Mexico" },
          { id: "clicks", sequence: 2, type: "string", value: "12" },
          { id: "impressions", sequence: 3, type: "string", value: "950" },
          { id: "allConversions", sequence: 4, type: "string", value: "90" },
          { id: "convRate", sequence: 5, type: "string", value: "50%" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "city", sequence: 1, type: "string", value: "Tokeyo" },
          { id: "clicks", sequence: 2, type: "string", value: "18" },
          { id: "impressions", sequence: 3, type: "string", value: "455" },
          { id: "allConversions", sequence: 4, type: "string", value: "907" },
          { id: "convRate", sequence: 5, type: "string", value: "15%" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "city", sequence: 1, type: "string", value: "Berlin" },
          { id: "clicks", sequence: 2, type: "string", value: "12" },
          { id: "impressions", sequence: 3, type: "string", value: "950" },
          { id: "allConversions", sequence: 4, type: "string", value: "990" },
          { id: "convRate", sequence: 5, type: "string", value: "9%" },
        ]
      ]
    case "mads-listGrid-weeklyPerformanceByClickType":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "week", sequence: 1, type: "string", value: "Jul 1, 2024 to Jul 7, 2024 (Week 20)" },
          { id: "clickType", sequence: 2, type: "string", value: "Headline" },
          { id: "clicks", sequence: 3, type: "string", value: "12" },
          { id: "impressions", sequence: 4, type: "string", value: "950" },
          { id: "avgCPC", sequence: 5, type: "string", value: "$5" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "week", sequence: 1, type: "string", value: "Jul 7, 2024 to Jul 15, 2024 (Week 25)" },
          { id: "clickType", sequence: 2, type: "string", value: "Phone Calls" },
          { id: "clicks", sequence: 3, type: "string", value: "18" },
          { id: "impressions", sequence: 4, type: "string", value: "45" },
          { id: "avgCPC", sequence: 5, type: "string", value: "$15" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "week", sequence: 1, type: "string", value: "Jul 20, 2024 to Jul 27, 2024 (Week 29)" },
          { id: "clickType", sequence: 2, type: "string", value: "Headline" },
          { id: "clicks", sequence: 3, type: "string", value: "12" },
          { id: "impressions", sequence: 4, type: "string", value: "950" },
          { id: "avgCPC", sequence: 5, type: "string", value: "$5" },
        ]
      ]
    case "bwt-listGrid-topQueries":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "Darpan Honker" },
          { id: "clicks", sequence: 2, type: "string", value: "78" },
          { id: "impressions", sequence: 3, type: "string", value: "728" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "12.87" },
          { id: "avgPosition", sequence: 5, type: "string", value: "10.57" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "Paeoo Lacetti" },
          { id: "clicks", sequence: 2, type: "string", value: "79" },
          { id: "impressions", sequence: 3, type: "string", value: "7709" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "25.52" },
          { id: "avgPosition", sequence: 5, type: "string", value: "17.11" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "Cristol Rigand" },
          { id: "clicks", sequence: 2, type: "string", value: "79" },
          { id: "impressions", sequence: 3, type: "string", value: "8092" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "9.46" },
          { id: "avgPosition", sequence: 5, type: "string", value: "18.54" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "Dolpine A5" },
          { id: "clicks", sequence: 2, type: "string", value: "80" },
          { id: "impressions", sequence: 3, type: "string", value: "8829" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "21.60" },
          { id: "avgPosition", sequence: 5, type: "string", value: "27.91" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "Pacia Lodgy" },
          { id: "clicks", sequence: 2, type: "string", value: "81" },
          { id: "impressions", sequence: 3, type: "string", value: "823" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "6.55" },
          { id: "avgPosition", sequence: 5, type: "string", value: "14.10" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "Jaimler Double" },
          { id: "clicks", sequence: 2, type: "string", value: "87" },
          { id: "impressions", sequence: 3, type: "string", value: "815" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "15.96" },
          { id: "avgPosition", sequence: 5, type: "string", value: "10.75" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "Super Eight" },
          { id: "clicks", sequence: 2, type: "string", value: "89" },
          { id: "impressions", sequence: 3, type: "string", value: "910" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "20.15" },
          { id: "avgPosition", sequence: 5, type: "string", value: "15.63" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "Porsche Boxstar" },
          { id: "clicks", sequence: 2, type: "string", value: "89" },
          { id: "impressions", sequence: 3, type: "string", value: "990" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "19.51" },
          { id: "avgPosition", sequence: 5, type: "string", value: "24.65" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "Rafae 46" },
          { id: "clicks", sequence: 2, type: "string", value: "90" },
          { id: "impressions", sequence: 3, type: "string", value: "942" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "14.95" },
          { id: "avgPosition", sequence: 5, type: "string", value: "10.26" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "keyword", sequence: 1, type: "string", value: "Shanghuan" },
          { id: "clicks", sequence: 2, type: "string", value: "91" },
          { id: "impressions", sequence: 3, type: "string", value: "840" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "6.09" },
          { id: "avgPosition", sequence: 5, type: "string", value: "10.70" }
        ]

      ]
    case "bwt-listGrid-topPages":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://www.time.kikobeats.com" },
          { id: "clicks", sequence: 2, type: "string", value: "1" },
          { id: "impressions", sequence: 3, type: "string", value: "19" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "1.00" },
          { id: "avgPosition", sequence: 5, type: "string", value: "8.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://www.test-login.vercel.app" },
          { id: "clicks", sequence: 2, type: "string", value: "0" },
          { id: "impressions", sequence: 3, type: "string", value: "249" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "1.00" },
          { id: "avgPosition", sequence: 5, type: "string", value: "14.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://www.simpledata.com/how-much-does-average-seo-cost/" },
          { id: "clicks", sequence: 2, type: "string", value: "0" },
          { id: "impressions", sequence: 3, type: "string", value: "61" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "1.00" },
          { id: "avgPosition", sequence: 5, type: "string", value: "12.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://www.schma.com/schema-markup-for-seo-what-you-need-to-know/" },
          { id: "clicks", sequence: 2, type: "string", value: "0" },
          { id: "impressions", sequence: 3, type: "string", value: "10" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "1.00" },
          { id: "avgPosition", sequence: 5, type: "string", value: "4.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://www.test-alert.vercel.app/services/google-shopping-management/" },
          { id: "clicks", sequence: 2, type: "string", value: "0" },
          { id: "impressions", sequence: 3, type: "string", value: "7" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "1.00" },
          { id: "avgPosition", sequence: 5, type: "string", value: "3.50" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://www.copypastecharacter.com/" },
          { id: "clicks", sequence: 2, type: "string", value: "0" },
          { id: "impressions", sequence: 3, type: "string", value: "2" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "1.00" },
          { id: "avgPosition", sequence: 5, type: "string", value: "17.50" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://www.font-rendering.vercel.app" },
          { id: "clicks", sequence: 2, type: "string", value: "0" },
          { id: "impressions", sequence: 3, type: "string", value: "1" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "1.00" },
          { id: "avgPosition", sequence: 5, type: "string", value: "11.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://www.primer.style/design/foundations/typography" },
          { id: "clicks", sequence: 2, type: "string", value: "0" },
          { id: "impressions", sequence: 3, type: "string", value: "1" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "1.00" },
          { id: "avgPosition", sequence: 5, type: "string", value: "8.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://www.www.cssfontstack.com/" },
          { id: "clicks", sequence: 2, type: "string", value: "0" },
          { id: "impressions", sequence: 3, type: "string", value: "1" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "1.00" },
          { id: "avgPosition", sequence: 5, type: "string", value: "8.00" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://www.smiley.cool/emoji-list.php" },
          { id: "clicks", sequence: 2, type: "string", value: "0" },
          { id: "impressions", sequence: 3, type: "string", value: "1" },
          { id: "avgClickPosition", sequence: 4, type: "string", value: "1.00" },
          { id: "avgPosition", sequence: 5, type: "string", value: "2.00" }
        ]
      ]
    case "bwt-listGrid-inboundLinks":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://api.coindesk.com/v1/bpi/currentprice.json" },
          { id: "inboundLinks", sequence: 2, type: "string", value: "131" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://www.boredapi.com/api/activity" },
          { id: "inboundLinks", sequence: 2, type: "string", value: "92" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://api.agify.io?name=meelad" },
          { id: "inboundLinks", sequence: 2, type: "string", value: "15" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://api.genderize.io?name=luc" },
          { id: "inboundLinks", sequence: 2, type: "string", value: "3" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://api.nationalize.io?name=nathaniel" },
          { id: "inboundLinks", sequence: 2, type: "string", value: "1" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://www.example6.com/social-media-posts" },
          { id: "inboundLinks", sequence: 2, type: "string", value: "1" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://datausa.io/api/data?drilldowns" },
          { id: "inboundLinks", sequence: 2, type: "string", value: "1" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://dog.ceo/api/breeds/image/random" },
          { id: "inboundLinks", sequence: 2, type: "string", value: "1" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://ipinfo.io/161.185.160.93/geo" },
          { id: "inboundLinks", sequence: 2, type: "string", value: "1" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "https://api.zippopotam.us/us/33162" },
          { id: "inboundLinks", sequence: 2, type: "string", value: "1" }
        ]
      ]
    case "bwt-listGrid-crawlErrors":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "http://requestb.in/" },
          { id: "result", sequence: 2, type: "string", value: "9" },
          { id: "inboundErrorLinks", sequence: 3, type: "string", value: "12,631" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "http://respondto.it/" },
          { id: "result", sequence: 2, type: "string", value: "8" },
          { id: "inboundErrorLinks", sequence: 3, type: "string", value: "18,456" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "http://httpbin.org/" },
          { id: "result", sequence: 2, type: "string", value: "5" },
          { id: "inboundErrorLinks", sequence: 3, type: "string", value: "12,812" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "http://test-alert.vercel.app" },
          { id: "result", sequence: 2, type: "string", value: "8" },
          { id: "inboundErrorLinks", sequence: 3, type: "string", value: "12,512" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "http://test-timeout.vercel.app" },
          { id: "result", sequence: 2, type: "string", value: "1" },
          { id: "inboundErrorLinks", sequence: 3, type: "string", value: "10,012" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "http://vercel.com/new/templates" },
          { id: "result", sequence: 2, type: "string", value: "2" },
          { id: "inboundErrorLinks", sequence: 3, type: "string", value: "11,12" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "page", sequence: 1, type: "url", value: "http://visual-counter.vercel.app" },
          { id: "result", sequence: 2, type: "string", value: "5" },
          { id: "inboundErrorLinks", sequence: 3, type: "string", value: "11,120" }
        ]
      ]
    case "fads-listGrid-campaigns":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Food Trolley Cover - May 2024" },
          { id: "objective", sequence: 2, type: "number", value: "TRAFFIC" },
          { id: "results", sequence: 3, type: "number", value: "475 (Link Clicks)" },
          { id: "clicks", sequence: 4, type: "string", value: "3073" },
          { id: "impressions", sequence: 5, type: "string", value: "49524" },
          { id: "reach", sequence: 6, type: "string", value: "43524" },
          { id: "amountSpent", sequence: 7, type: "string", value: "$189.79" },
          { id: "cpc", sequence: 8, type: "string", value: "$0.51" },
          { id: "ctr", sequence: 9, type: "string", value: "0.86%" },
          { id: "linkClick", sequence: 10, type: "string", value: "1255" },
          { id: "frequency", sequence: 11, type: "string", value: "2.32" },
          { id: "cpm", sequence: 12, type: "string", value: "$10" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Miracle FP Campaign - May 2024" },
          { id: "objective", sequence: 2, type: "number", value: "TRAFFIC" },
          { id: "results", sequence: 3, type: "number", value: "421 (Link Clicks)" },
          { id: "clicks", sequence: 4, type: "string", value: "303" },
          { id: "impressions", sequence: 5, type: "string", value: "4524" },
          { id: "reach", sequence: 6, type: "string", value: "4524" },
          { id: "amountSpent", sequence: 7, type: "string", value: "$181.79" },
          { id: "cpc", sequence: 8, type: "string", value: "$0.51" },
          { id: "ctr", sequence: 9, type: "string", value: "0.86%" },
          { id: "linkClick", sequence: 10, type: "string", value: "155" },
          { id: "frequency", sequence: 11, type: "string", value: "1.32" },
          { id: "cpm", sequence: 12, type: "string", value: "$100" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Fryrite 40 Campaign - April 2024" },
          { id: "objective", sequence: 2, type: "number", value: "TRAFFIC" },
          { id: "results", sequence: 3, type: "number", value: "175 (Link Clicks)" },
          { id: "clicks", sequence: 4, type: "string", value: "3073" },
          { id: "impressions", sequence: 5, type: "string", value: "4954" },
          { id: "reach", sequence: 6, type: "string", value: "43524" },
          { id: "amountSpent", sequence: 7, type: "string", value: "$989.79" },
          { id: "cpc", sequence: 8, type: "string", value: "$0.51" },
          { id: "ctr", sequence: 9, type: "string", value: "0.56%" },
          { id: "linkClick", sequence: 10, type: "string", value: "155" },
          { id: "frequency", sequence: 11, type: "string", value: "4.32" },
          { id: "cpm", sequence: 12, type: "string", value: "$40" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Air Filter - March" },
          { id: "objective", sequence: 2, type: "number", value: "TRAFFIC" },
          { id: "results", sequence: 3, type: "number", value: "445 (Link Clicks)" },
          { id: "clicks", sequence: 4, type: "string", value: "3573" },
          { id: "impressions", sequence: 5, type: "string", value: "19524" },
          { id: "reach", sequence: 6, type: "string", value: "43524" },
          { id: "amountSpent", sequence: 7, type: "string", value: "$89.79" },
          { id: "cpc", sequence: 8, type: "string", value: "$0.51" },
          { id: "ctr", sequence: 9, type: "string", value: "0.86%" },
          { id: "linkClick", sequence: 10, type: "string", value: "5255" },
          { id: "frequency", sequence: 11, type: "string", value: "3.32" },
          { id: "cpm", sequence: 12, type: "string", value: "$105" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Air Filter - March" },
          { id: "objective", sequence: 2, type: "number", value: "TRAFFIC" },
          { id: "results", sequence: 3, type: "number", value: "405 (Link Clicks)" },
          { id: "clicks", sequence: 4, type: "string", value: "3073" },
          { id: "impressions", sequence: 5, type: "string", value: "9524" },
          { id: "reach", sequence: 6, type: "string", value: "4354" },
          { id: "amountSpent", sequence: 7, type: "string", value: "$49.79" },
          { id: "cpc", sequence: 8, type: "string", value: "$0.51" },
          { id: "ctr", sequence: 9, type: "string", value: "0.66%" },
          { id: "linkClick", sequence: 10, type: "string", value: "255" },
          { id: "frequency", sequence: 11, type: "string", value: "3.32" },
          { id: "cpm", sequence: 12, type: "string", value: "$105" },
        ],
      ]
    case "fads-listGrid-adSets":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Food Trolley Cover - May 2024" },
          { id: "adset", sequence: 2, type: "string", value: "Cover - May 2024" },
          { id: "results", sequence: 3, type: "number", value: "475 (Link Clicks)" },
          { id: "clicks", sequence: 4, type: "string", value: "3053" },
          { id: "impressions", sequence: 5, type: "string", value: "9524" },
          { id: "reach", sequence: 6, type: "string", value: "4354" },
          { id: "amountSpent", sequence: 7, type: "string", value: "$89.79" },
          { id: "cpc", sequence: 8, type: "string", value: "$0.55" },
          { id: "ctr", sequence: 9, type: "string", value: "0.76%" },
          { id: "linkClick", sequence: 10, type: "string", value: "255" },
          { id: "frequency", sequence: 11, type: "string", value: "5.12" },
          { id: "cpm", sequence: 12, type: "string", value: "$9" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Miracle FP Campaign - May 2024" },
          { id: "adset", sequence: 2, type: "string", value: "Miracle FP Hospitality | Website" },
          { id: "results", sequence: 3, type: "number", value: "475 (Link Clicks)" },
          { id: "clicks", sequence: 4, type: "string", value: "303" },
          { id: "impressions", sequence: 5, type: "string", value: "1524" },
          { id: "reach", sequence: 6, type: "string", value: "434" },
          { id: "amountSpent", sequence: 7, type: "string", value: "$89.79" },
          { id: "cpc", sequence: 8, type: "string", value: "$0.85" },
          { id: "ctr", sequence: 9, type: "string", value: "0.46%" },
          { id: "linkClick", sequence: 10, type: "string", value: "655" },
          { id: "frequency", sequence: 11, type: "string", value: "6.12" },
          { id: "cpm", sequence: 12, type: "string", value: "$19" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Fryrite 40 Campaign - April 2024" },
          { id: "adset", sequence: 2, type: "string", value: "Miracle FP Hospitality | Website" },
          { id: "results", sequence: 3, type: "number", value: "475 (Link Clicks)" },
          { id: "clicks", sequence: 4, type: "string", value: "303" },
          { id: "impressions", sequence: 5, type: "string", value: "1524" },
          { id: "reach", sequence: 6, type: "string", value: "434" },
          { id: "amountSpent", sequence: 7, type: "string", value: "$89.79" },
          { id: "cpc", sequence: 8, type: "string", value: "$0.85" },
          { id: "ctr", sequence: 9, type: "string", value: "0.46%" },
          { id: "linkClick", sequence: 10, type: "string", value: "655" },
          { id: "frequency", sequence: 11, type: "string", value: "6.12" },
          { id: "cpm", sequence: 12, type: "string", value: "$19" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Air Filter - March" },
          { id: "adset", sequence: 2, type: "string", value: "Air Filter - Call" },
          { id: "results", sequence: 3, type: "number", value: "575 (Link Clicks)" },
          { id: "clicks", sequence: 4, type: "string", value: "353" },
          { id: "impressions", sequence: 5, type: "string", value: "1724" },
          { id: "reach", sequence: 6, type: "string", value: "430" },
          { id: "amountSpent", sequence: 7, type: "string", value: "$80.79" },
          { id: "cpc", sequence: 8, type: "string", value: "$0.95" },
          { id: "ctr", sequence: 9, type: "string", value: "0.56%" },
          { id: "linkClick", sequence: 10, type: "string", value: "6055" },
          { id: "frequency", sequence: 11, type: "string", value: "3.12" },
          { id: "cpm", sequence: 12, type: "string", value: "$20" },
        ]
      ]
    case "fads-listGrid-ads":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Food Trolley Cover - May 2024" },
          { id: "adImage", sequence: 2, type: "string", value: "No image" },
          { id: "ad", sequence: 3, type: "string", value: "Website" },
          { id: "adset", sequence: 4, type: "string", value: "Cover - May 2024" },
          { id: "results", sequence: 5, type: "number", value: "405 (Link Clicks)" },
          { id: "clicks", sequence: 6, type: "string", value: "303" },
          { id: "impressions", sequence: 7, type: "string", value: "524" },
          { id: "reach", sequence: 8, type: "string", value: "435" },
          { id: "amountSpent", sequence: 9, type: "string", value: "$80.79" },
          { id: "cpc", sequence: 10, type: "string", value: "$0.50" },
          { id: "ctr", sequence: 11, type: "string", value: "0.76%" },
          { id: "linkClick", sequence: 12, type: "string", value: "205" },
          { id: "frequency", sequence: 13, type: "string", value: "5.52" },
          { id: "cpm", sequence: 14, type: "string", value: "$10" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Food Trolley Cover - April 2024" },
          { id: "adImage", sequence: 2, type: "string", value: "No image" },
          { id: "ad", sequence: 3, type: "string", value: "Call Button" },
          { id: "adset", sequence: 4, type: "string", value: "Hospitality Ad Set" },
          { id: "results", sequence: 5, type: "number", value: "405 (Link Clicks)" },
          { id: "clicks", sequence: 6, type: "string", value: "3653" },
          { id: "impressions", sequence: 7, type: "string", value: "5024" },
          { id: "reach", sequence: 8, type: "string", value: "4305" },
          { id: "amountSpent", sequence: 9, type: "string", value: "$50.79" },
          { id: "cpc", sequence: 10, type: "string", value: "$0.50" },
          { id: "ctr", sequence: 11, type: "string", value: "0.71%" },
          { id: "linkClick", sequence: 12, type: "string", value: "255" },
          { id: "frequency", sequence: 13, type: "string", value: "5.82" },
          { id: "cpm", sequence: 14, type: "string", value: "$105" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Miracle FP Campaign - May 2024" },
          { id: "adImage", sequence: 2, type: "string", value: "No image" },
          { id: "ad", sequence: 3, type: "string", value: "Call Button" },
          { id: "adset", sequence: 4, type: "string", value: "Mircale FP Hospitality Website" },
          { id: "results", sequence: 5, type: "number", value: "450 (Link Clicks)" },
          { id: "clicks", sequence: 6, type: "string", value: "353" },
          { id: "impressions", sequence: 7, type: "string", value: "5124" },
          { id: "reach", sequence: 8, type: "string", value: "4305" },
          { id: "amountSpent", sequence: 9, type: "string", value: "$40.79" },
          { id: "cpc", sequence: 10, type: "string", value: "$0.50" },
          { id: "ctr", sequence: 11, type: "string", value: "0.78%" },
          { id: "linkClick", sequence: 12, type: "string", value: "265" },
          { id: "frequency", sequence: 13, type: "string", value: "5.92" },
          { id: "cpm", sequence: 14, type: "string", value: "$106" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "campaign", sequence: 1, type: "string", value: "Fryrite 40 Campaign - April 2024" },
          { id: "adImage", sequence: 2, type: "string", value: "No image" },
          { id: "ad", sequence: 3, type: "string", value: "Call Button" },
          { id: "adset", sequence: 4, type: "string", value: "Hospitality Ad Set" },
          { id: "results", sequence: 5, type: "number", value: "400 (Link Clicks)" },
          { id: "clicks", sequence: 6, type: "string", value: "313" },
          { id: "impressions", sequence: 7, type: "string", value: "524" },
          { id: "reach", sequence: 8, type: "string", value: "4395" },
          { id: "amountSpent", sequence: 9, type: "string", value: "$45.79" },
          { id: "cpc", sequence: 10, type: "string", value: "$0.56" },
          { id: "ctr", sequence: 11, type: "string", value: "0.71%" },
          { id: "linkClick", sequence: 12, type: "string", value: "2605" },
          { id: "frequency", sequence: 13, type: "string", value: "5.32" },
          { id: "cpm", sequence: 14, type: "string", value: "$100" },
        ],
      ]
    case "fads-listGrid-demographicsCountry":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "country", sequence: 1, type: "string", value: "AU" },
          { id: "clicks", sequence: 2, type: "string", value: "5293" },
          { id: "impressions", sequence: 3, type: "string", value: "406923" },
          { id: "cpc", sequence: 4, type: "string", value: "$0.30" },
          { id: "ctr", sequence: 5, type: "string", value: "1.30%" }
        ]
      ]
    case "fads-listGrid-demographicsRegion":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "region", sequence: 1, type: "string", value: "Japan Capital Territory" },
          { id: "clicks", sequence: 2, type: "string", value: "68" },
          { id: "impressions", sequence: 3, type: "string", value: "4722" },
          { id: "cpc", sequence: 4, type: "string", value: "$0.26" },
          { id: "ctr", sequence: 5, type: "string", value: "1.44%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "region", sequence: 1, type: "string", value: "Jervis Bay Territory" },
          { id: "clicks", sequence: 2, type: "string", value: "0" },
          { id: "impressions", sequence: 3, type: "string", value: "14" },
          { id: "cpc", sequence: 4, type: "string", value: "$0.00" },
          { id: "ctr", sequence: 5, type: "string", value: "0.00%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "region", sequence: 1, type: "string", value: "New South Wales" },
          { id: "clicks", sequence: 2, type: "string", value: "1408" },
          { id: "impressions", sequence: 3, type: "string", value: "107964" },
          { id: "cpc", sequence: 4, type: "string", value: "$0.31" },
          { id: "ctr", sequence: 5, type: "string", value: "1.30%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "region", sequence: 1, type: "string", value: "Northern Territory" },
          { id: "clicks", sequence: 2, type: "string", value: "66" },
          { id: "impressions", sequence: 3, type: "string", value: "6419" },
          { id: "cpc", sequence: 4, type: "string", value: "$0.36" },
          { id: "ctr", sequence: 5, type: "string", value: "1.03%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "region", sequence: 1, type: "string", value: "Queensland" },
          { id: "clicks", sequence: 2, type: "string", value: "1126" },
          { id: "impressions", sequence: 3, type: "string", value: "88013" },
          { id: "cpc", sequence: 4, type: "string", value: "$0.30" },
          { id: "ctr", sequence: 5, type: "string", value: "1.28%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "region", sequence: 1, type: "string", value: "South Korea" },
          { id: "clicks", sequence: 2, type: "string", value: "492" },
          { id: "impressions", sequence: 3, type: "string", value: "39661" },
          { id: "cpc", sequence: 4, type: "string", value: "$0.30" },
          { id: "ctr", sequence: 5, type: "string", value: "1.24%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "region", sequence: 1, type: "string", value: "Tasmania" },
          { id: "clicks", sequence: 2, type: "string", value: "196" },
          { id: "impressions", sequence: 3, type: "string", value: "17176" },
          { id: "cpc", sequence: 4, type: "string", value: "$0.33" },
          { id: "ctr", sequence: 5, type: "string", value: "1.14%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "region", sequence: 1, type: "string", value: "Iceland" },
          { id: "clicks", sequence: 2, type: "string", value: "1" },
          { id: "impressions", sequence: 3, type: "string", value: "426" },
          { id: "cpc", sequence: 4, type: "string", value: "$1.38" },
          { id: "ctr", sequence: 5, type: "string", value: "0.23%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "region", sequence: 1, type: "string", value: "Victoria" },
          { id: "clicks", sequence: 2, type: "string", value: "1321" },
          { id: "impressions", sequence: 3, type: "string", value: "92043" },
          { id: "cpc", sequence: 4, type: "string", value: "$0.27" },
          { id: "ctr", sequence: 5, type: "string", value: "1.44%" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "region", sequence: 1, type: "string", value: "West Indies" },
          { id: "clicks", sequence: 2, type: "string", value: "615" },
          { id: "impressions", sequence: 3, type: "string", value: "50486" },
          { id: "cpc", sequence: 4, type: "string", value: "$0.30" },
          { id: "ctr", sequence: 5, type: "string", value: "1.22%" }
        ]
      ]
    case "forg-listGrid-likesTopCountries":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "Australia" },
          { id: "count", sequence: 2, type: "string", value: "783" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "India" },
          { id: "count", sequence: 2, type: "string", value: "134" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "Brazil" },
          { id: "count", sequence: 2, type: "string", value: "91" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "United States" },
          { id: "count", sequence: 2, type: "string", value: "49" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "Pakistan" },
          { id: "count", sequence: 2, type: "string", value: "48" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "Egypt" },
          { id: "count", sequence: 2, type: "string", value: "37" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "Viet Nam" },
          { id: "count", sequence: 2, type: "string", value: "30" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "Turkey" },
          { id: "count", sequence: 2, type: "string", value: "24" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "Bangladesh" },
          { id: "count", sequence: 2, type: "string", value: "22" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "Mexico" },
          { id: "count", sequence: 2, type: "string", value: "22" }
        ]

      ]
    case "forg-listGrid-likesTopCities":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Melbourne, VIC, Australia" },
          { id: "count", sequence: 2, type: "string", value: "554" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Sydney, NSW, Australia" },
          { id: "count", sequence: 2, type: "string", value: "40" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Vadodara, Gujarat, India" },
          { id: "count", sequence: 2, type: "string", value: "40" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Ahmedabad, Gujarat, India" },
          { id: "count", sequence: 2, type: "string", value: "19" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Adelaide, SA, Australia" },
          { id: "count", sequence: 2, type: "string", value: "18" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Cairo, Cairo Governorate, Egypt" },
          { id: "count", sequence: 2, type: "string", value: "16" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Perth, WA, Australia" },
          { id: "count", sequence: 2, type: "string", value: "15" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "São Paulo, SP, Brazil" },
          { id: "count", sequence: 2, type: "string", value: "15" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Faisalabad, Punjab, Pakistan" },
          { id: "count", sequence: 2, type: "string", value: "13" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Brisbane, QLD, Australia" },
          { id: "count", sequence: 2, type: "string", value: "13" }
        ]

      ]
    case "forg-listGrid-likesTopLanguages":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "en_US" },
          { id: "count", sequence: 2, type: "string", value: "820" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "en_GB" },
          { id: "count", sequence: 2, type: "string", value: "378" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "pt_BR" },
          { id: "count", sequence: 2, type: "string", value: "97" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "es_LA" },
          { id: "count", sequence: 2, type: "string", value: "63" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "ar_AR" },
          { id: "count", sequence: 2, type: "string", value: "54" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "fr_FR" },
          { id: "count", sequence: 2, type: "string", value: "41" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "vi_VN" },
          { id: "count", sequence: 2, type: "string", value: "32" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "tr_TR" },
          { id: "count", sequence: 2, type: "string", value: "23" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "es_ES" },
          { id: "count", sequence: 2, type: "string", value: "22" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "it_IT" },
          { id: "count", sequence: 2, type: "string", value: "18" }
        ]

      ]
    case "forg-listGrid-reachTopCountries":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "Australia" },
          { id: "count", sequence: 2, type: "string", value: "312076" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "United Kingdom" },
          { id: "count", sequence: 2, type: "string", value: "72" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "Philippines" },
          { id: "count", sequence: 2, type: "string", value: "10" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "India" },
          { id: "count", sequence: 2, type: "string", value: "9" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "Netherlands" },
          { id: "count", sequence: 2, type: "string", value: "6" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "New Zealand" },
          { id: "count", sequence: 2, type: "string", value: "6" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "China" },
          { id: "count", sequence: 2, type: "string", value: "5" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "Malaysia" },
          { id: "count", sequence: 2, type: "string", value: "3" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "Pakistan" },
          { id: "count", sequence: 2, type: "string", value: "3" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCountries", sequence: 1, type: "string", value: "Ireland" },
          { id: "count", sequence: 2, type: "string", value: "2" }
        ]

      ]
    case "forg-listGrid-reachTopCities":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Melbourne, VIC, Australia" },
          { id: "count", sequence: 2, type: "string", value: "41427" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Sydney, NSW, Australia" },
          { id: "count", sequence: 2, type: "string", value: "35464" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Perth, WA, Australia" },
          { id: "count", sequence: 2, type: "string", value: "22599" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Adelaide, SA, Australia" },
          { id: "count", sequence: 2, type: "string", value: "18662" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Brisbane, QLD, Australia" },
          { id: "count", sequence: 2, type: "string", value: "11509" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Gold Coast, QLD, Australia" },
          { id: "count", sequence: 2, type: "string", value: "5530" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Canberra, ACT, Australia" },
          { id: "count", sequence: 2, type: "string", value: "3569" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Sunshine Coast, QLD, Australia" },
          { id: "count", sequence: 2, type: "string", value: "3409" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Townsville, QLD, Australia" },
          { id: "count", sequence: 2, type: "string", value: "3076" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topCities", sequence: 1, type: "string", value: "Wollongong, NSW, Australia" },
          { id: "count", sequence: 2, type: "string", value: "2999" }
        ]
      ]
    case "forg-listGrid-reachTopLanguages":
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "en_US" },
          { id: "count", sequence: 2, type: "string", value: "188367" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "en_US" },
          { id: "count", sequence: 2, type: "string", value: "105574" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "vi_VN" },
          { id: "count", sequence: 2, type: "string", value: "2834" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "zh_CN" },
          { id: "count", sequence: 2, type: "string", value: "1939" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "th_TH" },
          { id: "count", sequence: 2, type: "string", value: "1880" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "ar_AR" },
          { id: "count", sequence: 2, type: "string", value: "1588" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "ko_KR" },
          { id: "count", sequence: 2, type: "string", value: "1526" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "tr_TR" },
          { id: "count", sequence: 2, type: "string", value: "1101" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "zh_TW" },
          { id: "count", sequence: 2, type: "string", value: "928" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 0 },
          { id: "topLanguages", sequence: 1, type: "string", value: "it_IT" },
          { id: "count", sequence: 2, type: "string", value: "879" }
        ]
      ]
    default:
      return [
        [
          { id: "sequence", sequence: 0, type: "number", value: 1 },
          { id: "name", sequence: 1, type: "string", value: "Standard Dummy" },
          { id: "comment", sequence: 2, type: "string", value: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." },
          { id: "starRating", sequence: 3, type: "starRating", value: "FIVE" },
          { id: "createTime", sequence: 4, type: "string", value: "05-12-2023 00:26:57" },
          { id: "updateTime", sequence: 5, type: "string", value: "05-12-2023 00:26:57" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 2 },
          { id: "name", sequence: 1, type: "string", value: "Industry" },
          { id: "comment", sequence: 2, type: "string", value: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem." },
          { id: "starRating", sequence: 3, type: "starRating", value: "FIVE" },
          { id: "createTime", sequence: 4, type: "string", value: "22-11-2023 01:29:53" },
          { id: "updateTime", sequence: 5, type: "string", value: "22-11-2023 01:29:53" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 3 },
          { id: "name", sequence: 1, type: "string", value: "Alexander" },
          { id: "comment", sequence: 2, type: "string", value: "English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy." },
          { id: "starRating", sequence: 3, type: "starRating", value: "FIVE" },
          { id: "createTime", sequence: 4, type: "string", value: "31-10-2023 00:32:41" },
          { id: "updateTime", sequence: 5, type: "string", value: "31-10-2023 00:32:41" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 4 },
          { id: "name", sequence: 1, type: "string", value: "Printer" },
          { id: "comment", sequence: 2, type: "string", value: "Various versions have evolved over the years, sometimes by accident, sometimes on purpose injected humour and the like." },
          { id: "starRating", sequence: 3, type: "starRating", value: "FIVE" },
          { id: "createTime", sequence: 4, type: "string", value: "30-10-2023 06:42:55" },
          { id: "updateTime", sequence: 5, type: "string", value: "30-10-2023 06:42:55" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 5 },
          { id: "name", sequence: 1, type: "string", value: "Paolo Shang" },
          { id: "comment", sequence: 2, type: "string", value: "Various versions have evolved over the years, sometimes by accident, sometimes on purpose injected humour and the like." },
          { id: "starRating", sequence: 3, type: "starRating", value: "FIVE" },
          { id: "createTime", sequence: 4, type: "string", value: "28-09-2023 03:17:19" },
          { id: "updateTime", sequence: 5, type: "string", value: "28-09-2023 03:17:19" },
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 6 },
          { id: "name", sequence: 1, type: "string", value: "Rongjun Oten" },
          { id: "comment", sequence: 2, type: "string", value: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." },
          { id: "starRating", sequence: 3, type: "starRating", value: "FIVE" },
          { id: "createTime", sequence: 4, type: "string", value: "18-09-2023 00:52:38" },
          { id: "updateTime", sequence: 5, type: "string", value: "18-09-2023 00:52:38" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 7 },
          { id: "name", sequence: 1, type: "string", value: "Galley Vernon" },
          { id: "comment", sequence: 2, type: "string", value: "It is over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage." },
          { id: "starRating", sequence: 3, type: "starRating", value: "FIVE" },
          { id: "createTime", sequence: 4, type: "string", value: "10-10-2022 02:00:12" },
          { id: "updateTime", sequence: 5, type: "string", value: "13-09-2023 23:52:53" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 8 },
          { id: "name", sequence: 1, type: "string", value: "Safro Maf" },
          { id: "comment", sequence: 2, type: "string", value: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." },
          { id: "starRating", sequence: 3, type: "starRating", value: "FIVE" },
          { id: "createTime", sequence: 4, type: "string", value: "04-09-2023 02:07:40" },
          { id: "updateTime", sequence: 5, type: "string", value: "04-09-2023 02:07:40" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 9 },
          { id: "name", sequence: 1, type: "string", value: "Venom Karpe" },
          { id: "comment", sequence: 2, type: "string", value: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." },
          { id: "starRating", sequence: 3, type: "starRating", value: "FIVE" },
          { id: "createTime", sequence: 4, type: "string", value: "06-06-2023 00:37:32" },
          { id: "updateTime", sequence: 5, type: "string", value: "28-08-2023 04:46:06" }
        ],
        [
          { id: "sequence", sequence: 0, type: "number", value: 10 },
          { id: "name", sequence: 1, type: "string", value: "Neon Gark" },
          { id: "comment", sequence: 2, type: "string", value: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable." },
          { id: "starRating", sequence: 3, type: "starRating", value: "FIVE" },
          { id: "createTime", sequence: 4, type: "string", value: "16-08-2023 22:16:01" },
          { id: "updateTime", sequence: 5, type: "string", value: "16-08-2023 22:16:01" }
        ],
      ]
  }
}