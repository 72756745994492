enum GeneralPermissions {
  GlobalSearch = "General.GlobalSearch",
  // SystemSettings = "General.SystemSettings",
  QuickActions = "General.QuickActions",
}

enum CompanyPermissions {
  GetCompany = "Company.Get",
  ListCompany = "Company.List",
  CreateCompany = "Company.Create",
  UpdateCompany = "Company.Update",
  PatchCompany = "Company.Patch",
  CompanyEmail = "Company.Email.All",
  CompanyAddress = "Company.Address.All",
  CompanyPhone = "Company.Phone.All",
  CompanyNote = "Company.Note.All",
  CompanyLookup = "Company.Lookup.Enabled",
  CompanyTimeline = "Company.Timeline",
}

enum ContactPermissions {
  GetContact = "Contact.Get",
  ListContact = "Contact.List",
  CreateContact = "Contact.Create",
  UpdateContact = "Contact.Update",
  PatchContact = "Contact.Patch",
  ContactEmail = "Contact.Email.All",
  ContactAddress = "Contact.Address.All",
  ContactPhone = "Contact.Phone.All",
  ContactNote = "Contact.Note.All",
  ContactLookup = "Contact.Lookup.Enabled",
}

enum AccountPermissions {
  GetAccount = "Account.Get",
  ListAccount = "Account.List",
  CreateAccount = "Account.Create",
  UpdateAccount = "Account.Update",
  PatchAccount = "Account.Patch",
  PatchAccountStatus = "Account.Status.Patch",
  DeleteAccount = "Account.Delete",
  AccountTimeline = "Account.Timeline",
  AllAccountConnector = "Account.Connector.All",
  AllAccountIntegration = "Account.Integration.All",
  AllAccountNote = "Account.Note.All",
  AllAccountServices = "Account.Services.All",
  AllAccountSetting = "Account.Setting.All",
  AccountLookup = "Account.Lookup.Enabled",
  AccountLinkCompany = "Account.Link.Company.All",
  AccountLinkAccountManager = "Account.Link.AccountManager.All",
  AccountLinkSalesLead = "Account.Link.SalesLead.All",
  AccountLinkServices = "Account.Link.Services.All",
  AccountListName = "Account.List.Name.All",
  AccountListCompany = "Account.List.Company.All",
  AccountListClientContact = "Account.List.ClientContact.All",
  AccountListAccountManger = "Account.List.AccountManager.All",
  AccountListAccountNumber = "Account.List.AccountNumber.All",
  AccountListLastActivity = "Account.List.LastActivity.All",
  AccountListStatus = "Account.List.Status.All",
  AccountListConnector = "Account.List.Connector.All",
  AccountOverviewCompany = "Account.Overview.Company.All",
  AccountOverviewAccountNumber = "Account.Overview.AccountNumber.All",
  AccountOverviewPrimaryContact = "Account.Overview.PrimaryContact.All",
  AccountOverviewSalesLead = "Account.Overview.SalesLead.All",
  AccountOverviewSecondaryContact = "Account.Overview.SacondaryContact.All",
  AccountOverviewAccountManagerEnable = "Account.Overview.AccountManager.Enabled",
  AccountOverviewAccountManagerList = "Account.Overview.AccountManager.List",
  AccountOverviewAccountManagerCreate = "Account.Overview.AccountManager.Create",
  AccountOverviewAccountManagerDelete = "Account.Overview.AccountManager.Delete",
  AccountOverviewTimelineAccount = "Account.Overview.Timeline.Account.All",
  AccountOverviewTimelineCompany = "Account.Overview.Timeline.Company.All",
  AccountOverviewTimelineContact = "Account.Overview.Timeline.Contact.All",
  AccountOverviewAttachment = "Account.Overview.Attachment.All",
  AccountProjectList = "Account.Project.List",
  AccountProjectUpdate = "Account.Project.Patch",
  AccountProjectDelete = "Account.Project.Delete",
  AccountProjectCreate = "Account.Project.Create",
  AccountProjectImport = "Account.Project.Import",
}

enum AccountServicesPermissions {
  GetAccountService = "Account.Get",
  ListAccountService = "Account.Services.List",
  CreateAccountService = "Account.Services.Create",
  UpdateAccountService = "Account.Services.Update",
  DeleteAccountService = "Account.Services.Delete",
  PatchAccountService = "Account.Services.Patch",
  AccountServiceLookup = "Account.Service.Lookup.Enabled",
  AllAccountServices = "Account.Services.All",
}

enum SettingPermissions {
  AccountSetting = "Setting.AccountSetting",
  PaymentSetting = "Setting.Payment",
  SubscriptionSetting = "Setting.Subscription",
  AddonsSetting = "Setting.Addons",
  InvoiceSetting = "Setting.Invoice",
  SystemSettings = "Setting.Enabled",
  SettingSmtpSettingEnabled = 'Setting.SmtpSetting.Enabled'
}

enum RolePermissions {
  ListRoles = "Roles.List",
  GetRole = "Roles.Get",
  CreateRole = "Roles.Create",
  UpdateRole = "Roles.Update",
  PatchRole = "Roles.Patch",
  RolesLookup = "Roles.Lookup.Enabled",
}

enum UserPermissions {
  ListUsers = "Users.List",
  GetUser = "Users.Get",
  CreateUser = "Users.Create",
  UpdateUser = "Users.Update",
  PatchUser = "Users.Patch",
  UsersLookup = "Users.Lookup.Enabled",
}

enum TeamPermissions {
  ListTeams = "Teams.List",
  GetTeam = "Teams.Get",
  CreateTeam = "Teams.Create",
  UpdateTeam = "Teams.Update",
  PatchTeam = "Teams.Patch",
  TeamsLookup = "Teams.Lookup.Enabled",
}

enum ServicesPermissions {
  ListServices = "Services.List",
  GetService = "Services.Get",
  CreateService = "Services.Create",
  UpdateService = "Services.Update",
  PatchService = "Services.Patch",
}

enum ConnectorPermissions {
  AllConnector = 'Connector.All',
  GetConnector = 'Connector.Get',
  CreateConnector = 'Connector.Create',
  DeleteConnector = 'Connector.Delete',
  AllXeroConnector = 'Connector.Xero.All',
  AllMyobConnector = 'Connector.Myob.All',
  AllQuickBooksConnector = 'Connector.QuickBooks.All',
  ConnectorSetting = 'Connector.Setting.Enabled',
}

enum ProjectPermissions {
  ListProject = "Project.List",
  GetProject = "Project.Get",
  CreateProject = "Project.Create",
  UpdateProject = "Project.Update",
  PatchProject = "Project.Patch",
  DeleteProject = "Project.Delete",
  ProjectLookup = "Project.Lookup.Enabled",
  ListProjectSection = "Project.Section.List",
  GetProjectSection = "Project.Section.Get",
  CreateProjectSection = "Project.Section.Create",
  UpdateProjectSection = "Project.Section.Update",
  PatchProjectSection = "Project.Section.Patch",
  ProjectTimeline = "Project.Timeline",
}

enum ProjectTemplatePermissions {
  ListProjectTemplate = "ProjectTemplate.List",
  GetProjectTemplate = "ProjectTemplate.Get",
  CreateProjectTemplate = "ProjectTemplate.Create",
  UpdateProjectTemplate = "ProjectTemplate.Update",
  PatchProjectTemplate = "ProjectTemplate.Patch",
  ListProjectTemplateSection = "ProjectTemplate.Section.List",
  GetProjectTemplateSection = "ProjectTemplate.Section.Get",
  CreateProjectTemplateSection = "ProjectTemplate.Section.Create",
  UpdateProjectTemplateSection = "ProjectTemplate.Section.Update",
  PatchProjectTemplateSection = "ProjectTemplate.Section.Patch",
}

enum TaskPermissions {
  ListTask = "Task.List",
  GetTask = "Task.Get",
  CreateTask = "Task.Create",
  UpdateTask = "Task.Update",
  PatchTask = "Task.Patch",
  DeleteTask = "Task.Delete",
  ListTaskAttachment = "Task.Attachment.List",
  CreateTaskAttachment = "Task.Attachment.Create",
  DeleteTaskAttachment = "Task.Attachment.Delete",
  ListTaskComment = "Task.Comment.List",
  GetTaskComment = "Task.Comment.Get",
  CreateTaskComment = "Task.Comment.Create",
  PatchTaskComment = "Task.Comment.Patch",
  DeleteTaskComment = "Task.Comment.Delete",
  CreateTaskAssignee = "Task.Assignee.Create",
  DeleteTaskAssignee = "Task.Assignee.Delete",
  CreateTaskTeam = "Task.Team.Create",
  DeleteTaskTeam = "Task.Team.Delete",
  CreateTaskProject = "Task.Project.Create",
  DeleteTaskProject = "Task.Project.Delete",
  CreateTaskWatcher = "Task.Watcher.Create",
  DeleteTaskWatcher = "Task.Watcher.Delete",
  TaskTimeline = "Task.Timeline",
}

enum MyTaskPermissions {
  MyTask = "MyTask.Enabled",
  ListMyTaskLifecycle = "MyTask.Lifecycle.Enabled",
}

enum GlobalSearchPermissions {
  GlobalSearch = "GlobalSearch.Enabled",
}

enum KeywordTrackingPermissions {
  KeywordTracking = "KeywordTracking.Enabled",
  ListKeywordTrackingLocation = "KeywordTracking.Location.List",
  CreateKeywordTrackingLocation = "KeywordTracking.Location.Create",
  LookupKeywordTrackingLocation = "KeywordTracking.Location.Lookup.List",
  GetKeywordTrackingConnector = "KeywordTracking.Connector.Get",
  CreateKeywordTrackingConnector = "KeywordTracking.Connector.Create",
  PatchKeywordTrackingConnector = "KeywordTracking.Connector.Patch",
  DeleteKeywordTrackingConnector = "KeywordTracking.Connector.Delete",
  CreateKeywordTrackingRealTimeSerp = "KeywordTracking.RealTime.Create",
  ListKeywordTrackingKeyword = "KeywordTracking.Keyword.List",
  CreateKeywordTrackingKeyword = "KeywordTracking.Keyword.Create",
  DeleteKeywordTrackingKeyword = "KeywordTracking.Keyword.Delete",
  GetKeywordTrackingKeywordStatistics = "KeywordTracking.KeywordStatistics.Get",
  GetKeywordTrackingCompetitor = "KeywordTracking.Competitor.Get",
  CreateKeywordTrackingCompetitor = "KeywordTracking.Competitor.Create",
  DeleteKeywordTrackingCompetitor = "KeywordTracking.Competitor.Delete",
  GetKeywordTrackingCompetitorAudit = "KeywordTracking.Competitor.Audit.Get",
  GetKeywordTrackingAuditDate = "KeywordTracking.AuditDates.Get",
  PatchRankTrackerKeywordTracking = "KeywordTracking.RankTracker.Patch",
}

enum ReportsPermissions {
  Reports = 'Reports.Enabled',
  ListReports = 'Reports.List',
  GetReports = 'Reports.Get',
  CreateReports = 'Reports.Create',
  PatchReports = 'Reports.Patch',
  DeleteReports = 'Reports.Delete',
  CloneReports = 'Reports.Clone.Create',
  BrandLogoCreateReports = 'Reports.BrandLogo.Create',
  BrandSettingReports = 'Reports.Brand.Setting.Enabled',
  ReportsScheduleEnabled = 'Reports.Schedule.Enabled',
}

enum ReportsTemplatesPermissions {
  ReportsTemplates = 'Reports.Template.Enabled',
  ListReportsTemplates = 'Reports.Template.List',
  GetReportsTemplates = 'Reports.Template.Get',
  CreateReportsTemplates = 'Reports.Template.Create',
  PatchReportsTemplates = 'Reports.Template.Patch',
  DeleteReportsTemplates = 'Reports.Template.Delete',
  CloneReportsTemplates = 'Reports.Template.Clone.Create',
  BrandlogoReportsTemplates = 'Reports.Template.BrandLogo.Create',
  LookupReportsTemplates = 'Reports.Template.Lookup.Enabled'
}

// enum CampaignsPermissions {
//   ManualSerp = 'Campaigns.ManualSerp',
//   ReportExport = 'Campaigns.ReportExport',
// }

// enum ProjectPermissions {
//   ViewByProjectGroup = 'Project.ViewByProjectGroup',
//   BulkActions = 'Project.BulkActions',
// }

// enum TeamWorkspacePermissions {
//   DesignerTeam = 'TeamWorkspace.DesignerTeam',
//   SupportTeam = 'TeamWorkspace.SupportTeam',
//   SeoTeam = 'TeamWorkspace.SeoTeam',
//   ContentTeam = 'TeamWorkspace.ContentTeam',
//   BloggingTeam = 'TeamWorkspace.BloggingTeam',
//   HostingTeam = 'TeamWorkspace.HostingTeam',
//   AccountsTeam = 'TeamWorkspace.AccountsTeam',
//   DevTeam = 'TeamWorkspace.DevTeam',
// }

export const ClaimPermissions = {
  ...GeneralPermissions,
  ...AccountPermissions,
  ...AccountServicesPermissions,
  // ...CampaignsPermissions,
  // ...ProjectPermissions,
  // ...TeamWorkspacePermissions,
  ...CompanyPermissions,
  ...ContactPermissions,
  ...SettingPermissions,
  ...RolePermissions,
  ...UserPermissions,
  ...TeamPermissions,
  ...ServicesPermissions,
  ...ConnectorPermissions,
  ...ProjectPermissions,
  ...ProjectTemplatePermissions,
  ...TaskPermissions,
  ...MyTaskPermissions,
  ...GlobalSearchPermissions,
  ...KeywordTrackingPermissions,
  ...ReportsPermissions,
  ...ReportsTemplatesPermissions,
};

export type ClaimPermissions =
  | GeneralPermissions
  | CompanyPermissions
  | ContactPermissions
  | AccountPermissions
  | AccountServicesPermissions
  | SettingPermissions
  | RolePermissions
  | UserPermissions
  | TeamPermissions
  | ServicesPermissions
  | ConnectorPermissions
  | ProjectPermissions
  | ProjectTemplatePermissions
  | TaskPermissions
  | MyTaskPermissions
  | GlobalSearchPermissions
  | KeywordTrackingPermissions
  | ReportsPermissions
  | ReportsTemplatesPermissions;
// | CampaignsPermissions
// | ProjectPermissions
// | TeamWorkspacePermissions;
