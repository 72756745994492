import { IReportBranding, ISmtpSetting, PatchReportRequest, PostSmtpSettingRequest, PostSmtpSettingTestEmailRequest, PutSmtpSettingRequest } from "../../models";
import { api } from "../api";


export const getSmtpSetting = async (
    subscriptionId: string,
): Promise<any> => {
    const response = await api.get<ISmtpSetting>(
        `/${subscriptionId}/smtp-setting`
    );
    return response.data;
};

export const postSmtpSetting = async (
    subscriptionId: string,
    request: PostSmtpSettingRequest
): Promise<ISmtpSetting> => {
    const response = await api.post<ISmtpSetting>(
        `/${subscriptionId}/smtp-setting/create-smtp-setting`,
        request
    );
    return response.data;
};

export const putSmtpSetting = async (
    subscriptionId: string,
    id: string,
    request: PutSmtpSettingRequest
): Promise<void> => {
    await api.put(`/${subscriptionId}/smtp-setting/${id}`, request);
};


export const deleteSmtpSetting = async (
    subscriptionId: string,
    id: string
): Promise<void> => {
    await api.delete(`/${subscriptionId}/smtp-setting/${id}`);
};

export const postSmtpSettingTestEmail = async (
    subscriptionId: string,
    request: PostSmtpSettingTestEmailRequest
): Promise<any> => {
    const response = await api.post<any>(
        `/${subscriptionId}/smtp-setting/send-test-mail`,
        request
    );
    return response.data;
};