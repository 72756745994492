export enum ErrorCodes {
  DUPLICATE_COMPANY = "DUPLICATE_COMPANY",
  DUPLICATE_USER = "DUPLICATE_USER",
  DUPLICATE_SUBSCRIPTION_USER = "DUPLICATE_SUBSCRIPTION_USER",
  DUPLICATE_SUBSCRIPTION = "DUPLICATE_SUBSCRIPTION",
  DUPLICATE_COMPANY_EMAIL = "DUPLICATE_COMPANY_EMAIL",
  DUPLICATE_COMPANY_PHONE = "DUPLICATE_COMPANY_PHONE",
  DUPLICATE_CONTACT = "DUPLICATE_CONTACT",
  DUPLICATE_CONTACT_EMAIL = "DUPLICATE_CONTACT_EMAIL",
  DUPLICATE_CONTACT_PHONE = "DUPLICATE_CONTACT_PHONE",
  INVALID_PASSWORD = "INVALID_PASSWORD",
  INVALID_OOB_CODE = "INVALID_OOB_CODE",
  DUPLICATE_ROLE = "DUPLICATE_ROLE",
  DUPLICATE_TEAM = "DUPLICATE_TEAM",
  DUPLICATE_SERVICE = "DUPLICATE_SERVICE",
  DUPLICATE_ACCOUNT = "DUPLICATE_ACCOUNT",
  DUPLICATE_ACCOUNT_SERVICE = "DUPLICATE_ACCOUNT_SERVICE",
  DUPLICATE_PROJECT = "DUPLICATE_PROJECT",
  DUPLICATE_GMB_LOCATION = "DUPLICATE_GMB_LOCATION",
  GMB_ACCOUNT_ALREADY_IN_USE = "GMB_ACCOUNT_ALREADY_IN_USE",
  DUPLICATE_ASSIGNEE = "DUPLICATE_ASSIGNEE",
  DUPLICATE_WATCHER = "DUPLICATE_WATCHER",
  DUPLICATE_TASK_STATUS = "DUPLICATE_TASK_STATUS",
  DUPLICATE_SERP = "DUPLICATE_SERP",
  DUPLICATE_COMPETITOR = "DUPLICATE_COMPETITOR",
  DUPLICATE_SERP_ACCOUNT = "DUPLICATE_SERP_ACCOUNT",
  DATA_SAVED_WITH_LIMIT_REACHED = "DATA_SAVED_WITH_LIMIT_REACHED",
  ALREADY_IN_PROGRESS = "ALREADY_IN_PROGRESS",
  DUPLICATE_REPORT = "DUPLICATE_REPORT",
  TASK_LIFECYCLE_ALREADY_IN_USE = "TASK_LIFECYCLE_ALREADY_IN_USE",
  DUPLICATE_LOCATION = "DUPLICATE_LOCATION",
  INVALID_EMAIL = "INVALID_EMAIL",
  BLACKLISTED_DOMAIN = "BLACKLISTED_DOMAIN",
  DISPOSABLE_DOMAIN = "DISPOSABLE_DOMAIN",
  DOMAIN_DOES_NOT_EXISTS = "DOMAIN_DOES_NOT_EXISTS",
  MAILBOX_NOT_FOUND = "MAILBOX_NOT_FOUND",
  DUPLICATE_RANK_TRACKER = "DUPLICATE_RANK_TRACKER",
  TAX_ID_INVALID = "TAX_ID_INVALID",
  SERP_FREQUENCY_NOT_ALLOW = "SERP_FREQUENCY_NOT_ALLOW",
  DUPLICATE_ACCOUNT_SCHEDULE = "DUPLICATE_ACCOUNT_SCHEDULE",
  REPORT_ACCOUNT_SCHEDULE_EMAIL_ADD_LIMIT = "REPORT_ACCOUNT_SCHEDULE_EMAIL_ADD_LIMIT",
  REPORT_LINK_TAG_IS_MISSING = "REPORT_LINK_TAG_IS_MISSING",
  REPORT_ACCOUNT_SCHEDULE_IS_RUNNING = "REPORT_ACCOUNT_SCHEDULE_IS_RUNNING",
  ACCOUNT_LIMIT = "ACCOUNT_LIMIT",
  DUPLICATE_SMTTP_SETTING = "DUPLICATE_SMTTP_SETTING",
}
