import { GetScheduleReportAuditRequest, IPaginated, IScheduleReport, IScheduleReportAudit, PatchScheduleReportRequest, PostScheduleReportRequest, PutScheduleReportRequest } from "../../models";
import { api } from "../api";

export const getScheduleReport = async (
  subscriptionId: string,
  reportAccountId: string
): Promise<IScheduleReport> => {
  const response = await api.get<IScheduleReport>(`/${subscriptionId}/report-account-schedule/${reportAccountId}`);
  return response.data;
};

export const postScheduleReport = async (
  subscriptionId: string,
  request: PostScheduleReportRequest
): Promise<IScheduleReport> => {
  const response = await api.post<IScheduleReport>(
    `/${subscriptionId}/report-account-schedule`,
    request
  );
  return response.data;
};

export const patchScheduleReport = async (
  subscriptionId: string,
  id: string,
  request: PatchScheduleReportRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/report-account-schedule/${id}`,
    request
  );
};

export const putScheduleReport = async (
  subscriptionId: string,
  id: string,
  request: PutScheduleReportRequest
): Promise<void> => {
  await api.put(
    `/${subscriptionId}/report-account-schedule/${id}`,
    request
  );
};

export const getScheduleReportAudit = async (
  subscriptionId: string,
  reportAccountId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetScheduleReportAuditRequest
): Promise<IPaginated<IScheduleReportAudit>> => {
  const response = await api.get<IPaginated<IScheduleReportAudit>>(
    `/${subscriptionId}/report-account-schedule-audit/${reportAccountId}`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};
