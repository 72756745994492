import { Message } from 'react-hook-form';
import * as Yup from 'yup';

export function noLeadingOrTrailingWhitespace(filedName: string, message?: Message) {
    return Yup.string().test(
        'no-leading-trailing-whitespace',
        message ? message : `${filedName} cannot have leading or trailing spaces`,
        value => value === value!.trim()
    );
}

export function isQuillTextValid(value: string) {
    // Check if the content is not just a blank <p> tag, whitespace, or multiple blank lines
    const cleanedText = value
        .replace(/<p><br><\/p>/g, '') // Remove empty <p> tags with <br>
        .replace(/<p>(\s|<br>)*<\/p>/g, '')
        .replace(/<p><\/p>/g, '') // Remove empty <p> tags
        .replace(/&nbsp;/g, '') // Remove non-breaking spaces
        .trim();
    // Remove any white spaces or line breaks from the value
    const trimmedValue = value.replace(/\s/g, '');
    // Check if the value is a blank <p> tag or just white spaces
    return trimmedValue !== '<p></p>' && trimmedValue !== '' && cleanedText.length > 0;
}