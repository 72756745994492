import {
  GetUserAccountAccessRequest,
  IPaginated,
  IUserAccountAccess,
  IUserProfileAccountAccess,
  PatchUserAccountAccessRequest,
  PatchUserProfileAccountAccessRequest,
  PostUserAccountAccessRequest,
} from "../../models";
import { api } from "../api";

export const getUserProfileAccountAccess = async (
  subscriptionId: string,
  id: string
): Promise<IUserProfileAccountAccess> => {
  const response = await api.get<IUserProfileAccountAccess>(`/${subscriptionId}/user-profile-account-access/${id}`);
  return response.data;
};

export const patchUserProfileAccountAccess = async (
  subscriptionId: string,
  id: string,
  request: PatchUserProfileAccountAccessRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/user-profile-account-access/${id}`, request);
};

export const getUserAccounts = async (
  subscriptionId: string,
  userId: string,
  currentPage: number,
  itemsPerPage: number,
  request?: GetUserAccountAccessRequest
): Promise<IPaginated<IUserAccountAccess>> => {
  const response = await api.get<IPaginated<IUserAccountAccess>>(
    `/${subscriptionId}/user-account-access/${userId}`,
    {
      params: {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        ...request,
      },
    }
  );
  return response.data;
};

export const postUserAccountAccess = async (
  subscriptionId: string,
  userId: string,
  request: PostUserAccountAccessRequest
): Promise<IUserAccountAccess> => {
  const response = await api.post<IUserAccountAccess>(
    `/${subscriptionId}/user-account-access/${userId}`,
    request
  );
  return response.data;
};

export const patchUserAccountAccess = async (
  subscriptionId: string,
  id: string,
  request: PatchUserAccountAccessRequest
): Promise<void> => {
  await api.patch(`/${subscriptionId}/user-account-access/${id}`, request);
};

export const deleteUserAccountAccess = async (
  subscriptionId: string,
  teamMemberId: string
): Promise<void> => {
  await api.delete(`/${subscriptionId}/user-account-access/${teamMemberId}`);
};
