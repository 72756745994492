// form

import { Controller, useFormContext } from 'react-hook-form';
import { Editor, EditorProps } from '@daxrm/components';
import { FormControl, FormHelperText } from '@mui/material';

// import { EditorV2, EditorV2Props } from '@daxrm/components';



// @mui


// ----------------------------------------------------------------------

interface Props extends Omit<EditorProps, 'value' | 'onChange'> {
  name: string;
  placeholder: string;
  isShowImage?: boolean;
}

export default function RHFEditor({ name, isShowImage = false, helperText, id, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          <Editor
            isShowImage
            id={id || name}
            value={field.value}
            onChange={field.onChange}
            error={!!error}
            helperText={!!error?.message ?
              <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                {error?.message}
              </FormHelperText>

              : !!helperText ? <FormHelperText sx={{ px: 2, textTransform: 'capitalize' }}>
                {helperText}
              </FormHelperText> : null
            }
            {...other}
          />
        </FormControl>
      )}
    />
  );
}
