import "quill-mention";
import "quill-mention/dist/quill.mention.css";

import { Box, BoxProps } from '@mui/material';
import { FullEditorToolbar, redoChange, undoChange } from './EditorToolbar';
import { ReactNode, useEffect, useRef, useState } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';

import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.grey[500]}`,
  '& .ql-container.ql-snow': {
    borderColor: 'transparent',
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily,
  },
  '& .ql-editor': {
    minHeight: 100,
    maxHeight: 640,
    '&.ql-blank::before': {
      fontStyle: 'normal',
      color: theme.palette.text.disabled,
    },
    '& pre.ql-syntax': {
      ...theme.typography.body2,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[900],
    },
  },
}));

// ----------------------------------------------------------------------

export interface EditorProps extends ReactQuillProps {
  id?: string;
  error?: boolean;
  helperText?: ReactNode;
  sx?: BoxProps;
  isShowImage?: boolean;
  onFileUpload?: (file: File) => Promise<string>;
  onMentionSearch?: (searchTerm: string) => Promise<{ id: string; value: string }[]>;
}

export function Editor({
  id = 'minimal-quill',
  error,
  value,
  onChange,
  helperText,
  sx,
  onFileUpload,
  onMentionSearch,
  isShowImage = false,
  ...other
}: EditorProps) {
  const [formats, setFormats] = useState<string[] | undefined>();
  const quillRef: React.RefObject<ReactQuill> = useRef(null);

  const [modules, setModules] = useState<{
    [key: string]: any;
  } | undefined>();

  useEffect(() => {
    setModules({
      toolbar: {
        container: `#${id}`,
        handlers: {
          undo: undoChange,
          redo: redoChange,
        },
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
      syntax: true,
      clipboard: {
        matchVisual: false,
      },
      imageUploader: onFileUpload ? {
        upload: onFileUpload
      } : undefined,
      mention: onMentionSearch ? {
        minChars: 3,
        dataAttributes: ['email'],
        source: async function (searchTerm: any, renderList: any) {
          const matchedPeople = await onMentionSearch(searchTerm);
          renderList(matchedPeople);
        }
      } : undefined
    })

    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      // Use Quill's API to set the cursor position
      quill.setSelection({ index: 0, length: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const formatList = [
      'align',
      'background',
      'blockquote',
      'bold',
      'bullet',
      'code',
      'code-block',
      'color',
      'direction',
      'font',
      'formula',
      'header',
      'indent',
      'italic',
      'link',
      'list',
      'script',
      'size',
      'strike',
      'table',
      'underline',
    ];

    if (!!onFileUpload) {
      formatList.push(
        'image',
        'imageBlot',
      )
    }

    if (isShowImage) {
      formatList.push(
        'image',
        'imageBlot',
      )
    }
    if (!!onMentionSearch) {
      formatList.push(
        'mention'
      )
    }
    setFormats([...formatList]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <RootStyle
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
        }}
      >
        <FullEditorToolbar id={id} hasFileUpload={!!onFileUpload} />
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder="Write something awesome..."
          {...other}
        />
      </RootStyle>

      {helperText && helperText}
    </>
  );
}
