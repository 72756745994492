import { IReportBranding, PatchReportRequest } from "../../models";
import { api } from "../api";


export const getReportBranding = async (
    subscriptionId: string,
): Promise<any> => {
    const response = await api.get<any>(
        `/${subscriptionId}/report-branding`
    );
    return response.data;
};

export const patchReportBranding = async (
    subscriptionId: string,
    id: string,
    request: PatchReportRequest
): Promise<void> => {
    await api.patch(`/${subscriptionId}/report-branding/${id}`, request);
};

export const getReportScheduleEmailTemplate = async (
    subscriptionId: string,
): Promise<any> => {
    const response = await api.get<any>(
        `/${subscriptionId}/report-schedule-email-template`
    );
    return response.data;
};