import {
  GenericLookupType,
  ILookup,
  ICountryLookup,
  IStateLookup,
  IConnector,
  ILifecycleLookup,
  GenericLifecycleReferenceCode,
  ITaxTypeLookup,
  IPriorityTypeLookup,
  IDeviceTypeLookup,
  INoteTypeLookup,
  IGoogleAdsLocationLookup,
  ITimezoneLookup,
  ICurrencyTypeLookup,
  IMailTypeLookup,
} from "../models";

import { api } from "./api";

const { REACT_APP_LOOKUP_API_URL } = process.env;

export const getLookups = async (
  lookupType: GenericLookupType
): Promise<ILookup[]> => {
  const response = await api.get<ILookup[]>(`/lookup`, {
    baseURL: `${REACT_APP_LOOKUP_API_URL}`,
    params: {
      lookupType: lookupType,
    },
  });
  return response.data;
};

export const getLifecycleLookups = async (
  referenceCode: GenericLifecycleReferenceCode
): Promise<ILifecycleLookup[]> => {
  const response = await api.get<ILifecycleLookup[]>(`/lookup/lifecycle`, {
    baseURL: `${REACT_APP_LOOKUP_API_URL}`,
    params: {
      referenceCode: referenceCode,
    },
  });
  return response.data;
};

export const getCountryLookups = async (): Promise<ICountryLookup[]> => {
  const response = await api.get<ICountryLookup[]>(`/lookup/country`, {
    baseURL: `${REACT_APP_LOOKUP_API_URL}`,
  });
  return response.data;
};

export const getStateLookups = async (
  countryCode: string
): Promise<IStateLookup[]> => {
  const response = await api.get<IStateLookup[]>(`/state`, {
    baseURL: `${REACT_APP_LOOKUP_API_URL}`,
    params: {
      countryCode: countryCode,
    },
  });
  return response.data;
};

export const getConnectorLookups = async (): Promise<IConnector[]> => {
  const response = await api.get<IConnector[]>(`/lookup/connector`, {
    baseURL: `${REACT_APP_LOOKUP_API_URL}`,
  });
  return response.data;
};

export const getTaxTypeLookups = async (): Promise<ITaxTypeLookup[]> => {
  const response = await api.get<ITaxTypeLookup[]>(`/lookup/tax-type`, {
    baseURL: `${REACT_APP_LOOKUP_API_URL}`,
  });
  return response.data;
};

export const getPriorityTypeLookups = async (): Promise<
  IPriorityTypeLookup[]
> => {
  const response = await api.get<IPriorityTypeLookup[]>(
    `/lookup/priority-type`,
    {
      baseURL: `${REACT_APP_LOOKUP_API_URL}`,
    }
  );
  return response.data;
};

export const getDeviceTypeLookups = async (): Promise<IDeviceTypeLookup[]> => {
  const response = await api.get<IDeviceTypeLookup[]>(`/lookup/device-type`, {
    baseURL: `${REACT_APP_LOOKUP_API_URL}`,
  });
  return response.data;
};

export const getNoteTypeLookups = async (): Promise<INoteTypeLookup[]> => {
  const response = await api.get<INoteTypeLookup[]>(`/lookup/note-type`, {
    baseURL: `${REACT_APP_LOOKUP_API_URL}`,
  });
  return response.data;
};

export const getGoogleAdsLocationLookups = async (): Promise<
  IGoogleAdsLocationLookup[]
> => {
  const response = await api.get<IGoogleAdsLocationLookup[]>(
    `/lookup/google-ads-location`,
    {
      baseURL: `${REACT_APP_LOOKUP_API_URL}`,
    }
  );
  return response.data;
};

export const getTimezoneLookups = async (): Promise<ITimezoneLookup[]> => {
  const response = await api.get<ITimezoneLookup[]>(`/lookup/timezone`, {
    baseURL: `${REACT_APP_LOOKUP_API_URL}`,
  });
  return response.data;
};

export const getCurrencyTypeLookups = async (): Promise<
  ICurrencyTypeLookup[]
> => {
  const response = await api.get<ICurrencyTypeLookup[]>(
    `/lookup/currency-type`,
    {
      baseURL: `${REACT_APP_LOOKUP_API_URL}`,
    }
  );
  return response.data;
};

export const getMailTypeLookups = async (): Promise<
  IMailTypeLookup[]
> => {
  const response = await api.get<IMailTypeLookup[]>(
    `/lookup/mail-type`,
    {
      baseURL: `${REACT_APP_LOOKUP_API_URL}`,
    }
  );
  return response.data;
};
